/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type DeleteBroadcastMessageInput = {
  id: string,
  _version?: number | null,
};

export type ModelBroadcastMessageConditionInput = {
  modelType?: ModelBroadcastMessageStringInput | null,
  message?: ModelStringInput | null,
  title?: ModelStringInput | null,
  originType?: ModelBroadcastMessageOriginInput | null,
  originId?: ModelIDInput | null,
  sentBy?: ModelStringInput | null,
  url?: ModelStringInput | null,
  extra?: ModelStringInput | null,
  targetedGroup?: ModelUserGroupsInput | null,
  targetedRole?: ModelTeamMemberRoleInput | null,
  targetedEventId?: ModelIDInput | null,
  targetedUsers?: ModelStringInput | null,
  users?: ModelStringInput | null,
  broadcastAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBroadcastMessageConditionInput | null > | null,
  or?: Array< ModelBroadcastMessageConditionInput | null > | null,
  not?: ModelBroadcastMessageConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBroadcastMessageStringInput = {
  eq?: BroadcastMessageString | null,
  ne?: BroadcastMessageString | null,
};

export enum BroadcastMessageString {
  broadcast = "broadcast",
}


export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBroadcastMessageOriginInput = {
  eq?: BroadcastMessageOrigin | null,
  ne?: BroadcastMessageOrigin | null,
};

export enum BroadcastMessageOrigin {
  manual = "manual",
  certificate = "certificate",
}


export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelUserGroupsInput = {
  eq?: UserGroups | null,
  ne?: UserGroups | null,
};

export enum UserGroups {
  Admin = "Admin",
  Pilot = "Pilot",
  Ground = "Ground",
  TeamLead = "TeamLead",
}


export type ModelTeamMemberRoleInput = {
  eq?: TeamMemberRole | null,
  ne?: TeamMemberRole | null,
};

export enum TeamMemberRole {
  captain = "captain",
  ops1 = "ops1",
  ops2 = "ops2",
  rffs1 = "rffs1",
  rffs2 = "rffs2",
  photos1 = "photos1",
  photos2 = "photos2",
}


export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type BroadcastMessage = {
  __typename: "BroadcastMessage",
  id: string,
  modelType?: BroadcastMessageString | null,
  message: string,
  title?: string | null,
  originType?: BroadcastMessageOrigin | null,
  originId?: string | null,
  sentBy?: string | null,
  url?: string | null,
  extra?: string | null,
  targetedGroup?: UserGroups | null,
  targetedRole?: TeamMemberRole | null,
  targetedEventId?: string | null,
  targetedEvent?: Event | null,
  targetedUsers?: Array< string | null > | null,
  users?: Array< string | null > | null,
  broadcastAt?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Event = {
  __typename: "Event",
  modelType?: TaskTypeString | null,
  id: string,
  type: EventType,
  oid: string,
  venue?: string | null,
  venueId?: number | null,
  regionId?: number | null,
  date: string,
  endDate?: string | null,
  pax?: number | null,
  product?: string | null,
  ref?: string | null,
  description?: string | null,
  notes?: string | null,
  overview?: PleasureOverview | null,
  currentFlight?: Flight | null,
  currentRefuelRequest?: RefuelRequest | null,
  teamMembers?: ModelTeamMemberConnection | null,
  flights?: ModelFlightConnection | null,
  legs?: ModelFlightLegConnection | null,
  users: Array< string >,
  pilotName?: string | null,
  pilotConfirmed?: boolean | null,
  aircraftId?: number | null,
  aircraftType?: string | null,
  aircraftName?: string | null,
  aircraftSeats?: number | null,
  aircraft?: string | null,
  duration?: number | null,
  client?: string | null,
  status?: EventStatus | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  eventOverviewId?: string | null,
  eventCurrentFlightId?: string | null,
  eventCurrentRefuelRequestId?: string | null,
};

export enum TaskTypeString {
  task = "task",
}


export enum EventType {
  trial_lesson = "trial_lesson",
  lesson = "lesson",
  test = "test",
  pilot_test = "pilot_test",
  training = "training",
  bespoke = "bespoke",
  charter = "charter",
  pleasure = "pleasure",
  transit = "transit",
  positioning = "positioning",
  sfh = "sfh",
  other = "other",
  ground_exam = "ground_exam",
  ground_school = "ground_school",
}


export type PleasureOverview = {
  __typename: "PleasureOverview",
  id: string,
  eventId: string,
  team?: ModelTeamMemberConnection | null,
  manifestItems?: ModelManifestItemConnection | null,
  paxBooked?: number | null,
  aircraftReg?: string | null,
  aircraftSeats?: number | null,
  fireName?: string | null,
  fireReg?: string | null,
  bowserName?: string | null,
  truckName?: string | null,
  truckReg?: string | null,
  counts?: string | null,
  teamLeaderId?: string | null,
  teamLeader?: TeamMember | null,
  disabledRoles?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTeamMemberConnection = {
  __typename: "ModelTeamMemberConnection",
  items:  Array<TeamMember | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TeamMember = {
  __typename: "TeamMember",
  id: string,
  overviewId?: string | null,
  eventId?: string | null,
  name?: string | null,
  forename?: string | null,
  surname?: string | null,
  teamResourceId?: number | null,
  teamRole?: string | null,
  phone?: string | null,
  confirmed?: boolean | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelManifestItemConnection = {
  __typename: "ModelManifestItemConnection",
  items:  Array<ManifestItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ManifestItem = {
  __typename: "ManifestItem",
  id: string,
  overviewId: string,
  eventId?: string | null,
  time?: string | null,
  duration?: number | null,
  designator?: PfFlightDesignator | null,
  pax?: number | null,
  weight?: number | null,
  voucherIds?: Array< string | null > | null,
  frontSeatTaken?: boolean | null,
  passengers?:  Array<ManifestPassenger | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum PfFlightDesignator {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
  L = "L",
  S = "S",
  T = "T",
  V = "V",
  W = "W",
  X = "X",
}


export type ManifestPassenger = {
  __typename: "ManifestPassenger",
  title?: string | null,
  forename?: string | null,
  surname?: string | null,
  phone?: string | null,
  email?: string | null,
  postcode?: string | null,
  weight?: number | null,
  enquiryId?: string | null,
  voucherId?: string | null,
  frontSeat?: boolean | null,
};

export type Flight = {
  __typename: "Flight",
  id: string,
  tupleId?: string | null,
  eventId: string,
  flightOrderId?: number | null,
  designator: PfFlightDesignator,
  sequence: number,
  flightNumber?: string | null,
  theme?: string | null,
  aircraftName?: string | null,
  isFlightFull?: boolean | null,
  scheduledDeparture?: string | null,
  actualDeparture?: string | null,
  estimatedArrival?: string | null,
  scheduledCheckIn?: string | null,
  status?: FlightStatus | null,
  duration?: number | null,
  passengers?: ModelFlightPassengerConnection | null,
  users: Array< string >,
  updatedAt: string,
  lastUpdatedBy?: UpdateSource | null,
  createdAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum FlightStatus {
  arriving = "arriving",
  checkedin = "checkedin",
  briefing = "briefing",
  briefed = "briefed",
  boarding = "boarding",
  dispatched = "dispatched",
  complete = "complete",
}


export type ModelFlightPassengerConnection = {
  __typename: "ModelFlightPassengerConnection",
  items:  Array<FlightPassenger | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type FlightPassenger = {
  __typename: "FlightPassenger",
  id: string,
  eventId: string,
  tupleId?: string | null,
  event?: Event | null,
  enquiryId?: number | null,
  voucherId?: string | null,
  designator?: PfFlightDesignator | null,
  sequence?: number | null,
  flightNumber?: string | null,
  flightId?: string | null,
  number: number,
  passengerNumber?: number | null,
  forename: string,
  surname: string,
  weight: number,
  seat: PassengerSeat,
  phone?: string | null,
  email?: string | null,
  postcode?: string | null,
  bubbly?: boolean | null,
  creamTea?: boolean | null,
  lunch?: boolean | null,
  magnum?: boolean | null,
  chocolate?: boolean | null,
  photo?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum PassengerSeat {
  front = "front",
  rear = "rear",
}


export enum UpdateSource {
  adventure001 = "adventure001",
  overflight = "overflight",
}


export type RefuelRequest = {
  __typename: "RefuelRequest",
  id: string,
  eventId: string,
  requestedBy?: string | null,
  requestFrom?: TeamMemberRole | null,
  delay?: number | null,
  users: Array< string >,
  flightOrderId?: number | null,
  adventureId?: number | null,
  aircraftId?: number | null,
  aircraftName?: string | null,
  litres?: number | null,
  fuelSource?: FuelSource | null,
  fuelType?: FuelType | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type FuelSource = {
  __typename: "FuelSource",
  provider?: FuellingSource | null,
  name?: string | null,
};

export enum FuellingSource {
  bowser = "bowser",
  venue = "venue",
}


export enum FuelType {
  jeta1 = "jeta1",
  avgas100 = "avgas100",
  avgas100ll = "avgas100ll",
}


export type ModelFlightConnection = {
  __typename: "ModelFlightConnection",
  items:  Array<Flight | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFlightLegConnection = {
  __typename: "ModelFlightLegConnection",
  items:  Array<FlightLeg | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type FlightLeg = {
  __typename: "FlightLeg",
  id: string,
  eventId: string,
  event?: Event | null,
  order: number,
  venueId?: number | null,
  venueName?: string | null,
  venuePostcode?: string | null,
  empty?: boolean | null,
  departing?: string | null,
  fromVenueId?: number | null,
  fromVenueName?: string | null,
  fromVenuePostcode?: string | null,
  fromCoords?: Coords | null,
  arriving?: string | null,
  toVenueId?: number | null,
  toVenueName?: string | null,
  toVenuePostcode?: string | null,
  toCoords?: Coords | null,
  distance?: number | null,
  ete?: number | null,
  lat?: number | null,
  lng?: number | null,
  w3w?: string | null,
  status?: EventStatus | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Coords = {
  __typename: "Coords",
  lat?: number | null,
  lng?: number | null,
};

export enum EventStatus {
  pending = "pending",
  started = "started",
  lifted = "lifted",
  enroute = "enroute",
  techstop = "techstop",
  landed = "landed",
  complete = "complete",
  cancelled = "cancelled",
  postponed = "postponed",
  emergency = "emergency",
}


export type CreateEventInput = {
  modelType?: TaskTypeString | null,
  id?: string | null,
  type: EventType,
  oid: string,
  venue?: string | null,
  venueId?: number | null,
  regionId?: number | null,
  date: string,
  endDate?: string | null,
  pax?: number | null,
  product?: string | null,
  ref?: string | null,
  description?: string | null,
  notes?: string | null,
  users: Array< string >,
  pilotName?: string | null,
  pilotConfirmed?: boolean | null,
  aircraftId?: number | null,
  aircraftType?: string | null,
  aircraftName?: string | null,
  aircraftSeats?: number | null,
  aircraft?: string | null,
  duration?: number | null,
  client?: string | null,
  status?: EventStatus | null,
  _version?: number | null,
  eventOverviewId?: string | null,
  eventCurrentFlightId?: string | null,
  eventCurrentRefuelRequestId?: string | null,
};

export type ModelEventConditionInput = {
  modelType?: ModelTaskTypeStringInput | null,
  type?: ModelEventTypeInput | null,
  oid?: ModelStringInput | null,
  venue?: ModelStringInput | null,
  venueId?: ModelIntInput | null,
  regionId?: ModelIntInput | null,
  date?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  pax?: ModelIntInput | null,
  product?: ModelStringInput | null,
  ref?: ModelStringInput | null,
  description?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  users?: ModelStringInput | null,
  pilotName?: ModelStringInput | null,
  pilotConfirmed?: ModelBooleanInput | null,
  aircraftId?: ModelIntInput | null,
  aircraftType?: ModelStringInput | null,
  aircraftName?: ModelStringInput | null,
  aircraftSeats?: ModelIntInput | null,
  aircraft?: ModelStringInput | null,
  duration?: ModelFloatInput | null,
  client?: ModelStringInput | null,
  status?: ModelEventStatusInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  eventOverviewId?: ModelIDInput | null,
  eventCurrentFlightId?: ModelIDInput | null,
  eventCurrentRefuelRequestId?: ModelIDInput | null,
};

export type ModelTaskTypeStringInput = {
  eq?: TaskTypeString | null,
  ne?: TaskTypeString | null,
};

export type ModelEventTypeInput = {
  eq?: EventType | null,
  ne?: EventType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelEventStatusInput = {
  eq?: EventStatus | null,
  ne?: EventStatus | null,
};

export type UpdateEventInput = {
  modelType?: TaskTypeString | null,
  id: string,
  type?: EventType | null,
  oid?: string | null,
  venue?: string | null,
  venueId?: number | null,
  regionId?: number | null,
  date?: string | null,
  endDate?: string | null,
  pax?: number | null,
  product?: string | null,
  ref?: string | null,
  description?: string | null,
  notes?: string | null,
  users?: Array< string > | null,
  pilotName?: string | null,
  pilotConfirmed?: boolean | null,
  aircraftId?: number | null,
  aircraftType?: string | null,
  aircraftName?: string | null,
  aircraftSeats?: number | null,
  aircraft?: string | null,
  duration?: number | null,
  client?: string | null,
  status?: EventStatus | null,
  _version?: number | null,
  eventOverviewId?: string | null,
  eventCurrentFlightId?: string | null,
  eventCurrentRefuelRequestId?: string | null,
};

export type DeleteEventInput = {
  id: string,
  _version?: number | null,
};

export type CreatePleasureOverviewInput = {
  id?: string | null,
  eventId: string,
  paxBooked?: number | null,
  aircraftReg?: string | null,
  aircraftSeats?: number | null,
  fireName?: string | null,
  fireReg?: string | null,
  bowserName?: string | null,
  truckName?: string | null,
  truckReg?: string | null,
  counts?: string | null,
  teamLeaderId?: string | null,
  disabledRoles?: string | null,
  _version?: number | null,
};

export type ModelPleasureOverviewConditionInput = {
  eventId?: ModelIDInput | null,
  paxBooked?: ModelIntInput | null,
  aircraftReg?: ModelStringInput | null,
  aircraftSeats?: ModelIntInput | null,
  fireName?: ModelStringInput | null,
  fireReg?: ModelStringInput | null,
  bowserName?: ModelStringInput | null,
  truckName?: ModelStringInput | null,
  truckReg?: ModelStringInput | null,
  counts?: ModelStringInput | null,
  teamLeaderId?: ModelIDInput | null,
  disabledRoles?: ModelStringInput | null,
  and?: Array< ModelPleasureOverviewConditionInput | null > | null,
  or?: Array< ModelPleasureOverviewConditionInput | null > | null,
  not?: ModelPleasureOverviewConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdatePleasureOverviewInput = {
  id: string,
  eventId?: string | null,
  paxBooked?: number | null,
  aircraftReg?: string | null,
  aircraftSeats?: number | null,
  fireName?: string | null,
  fireReg?: string | null,
  bowserName?: string | null,
  truckName?: string | null,
  truckReg?: string | null,
  counts?: string | null,
  teamLeaderId?: string | null,
  disabledRoles?: string | null,
  _version?: number | null,
};

export type DeletePleasureOverviewInput = {
  id: string,
  _version?: number | null,
};

export type CreateTeamMemberInput = {
  id?: string | null,
  overviewId?: string | null,
  eventId?: string | null,
  name?: string | null,
  forename?: string | null,
  surname?: string | null,
  teamResourceId?: number | null,
  teamRole?: string | null,
  phone?: string | null,
  confirmed?: boolean | null,
  owner?: string | null,
  _version?: number | null,
};

export type ModelTeamMemberConditionInput = {
  overviewId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  forename?: ModelStringInput | null,
  surname?: ModelStringInput | null,
  teamResourceId?: ModelIntInput | null,
  teamRole?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  confirmed?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTeamMemberConditionInput | null > | null,
  or?: Array< ModelTeamMemberConditionInput | null > | null,
  not?: ModelTeamMemberConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateTeamMemberInput = {
  id: string,
  overviewId?: string | null,
  eventId?: string | null,
  name?: string | null,
  forename?: string | null,
  surname?: string | null,
  teamResourceId?: number | null,
  teamRole?: string | null,
  phone?: string | null,
  confirmed?: boolean | null,
  owner?: string | null,
  _version?: number | null,
};

export type DeleteTeamMemberInput = {
  id: string,
  _version?: number | null,
};

export type CreateManifestItemInput = {
  id?: string | null,
  overviewId: string,
  eventId?: string | null,
  time?: string | null,
  duration?: number | null,
  designator?: PfFlightDesignator | null,
  pax?: number | null,
  weight?: number | null,
  voucherIds?: Array< string | null > | null,
  frontSeatTaken?: boolean | null,
  passengers?: Array< ManifestPassengerInput | null > | null,
  _version?: number | null,
};

export type ManifestPassengerInput = {
  title?: string | null,
  forename?: string | null,
  surname?: string | null,
  phone?: string | null,
  email?: string | null,
  postcode?: string | null,
  weight?: number | null,
  enquiryId?: string | null,
  voucherId?: string | null,
  frontSeat?: boolean | null,
};

export type ModelManifestItemConditionInput = {
  overviewId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  duration?: ModelFloatInput | null,
  designator?: ModelPfFlightDesignatorInput | null,
  pax?: ModelIntInput | null,
  weight?: ModelIntInput | null,
  voucherIds?: ModelStringInput | null,
  frontSeatTaken?: ModelBooleanInput | null,
  and?: Array< ModelManifestItemConditionInput | null > | null,
  or?: Array< ModelManifestItemConditionInput | null > | null,
  not?: ModelManifestItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPfFlightDesignatorInput = {
  eq?: PfFlightDesignator | null,
  ne?: PfFlightDesignator | null,
};

export type UpdateManifestItemInput = {
  id: string,
  overviewId?: string | null,
  eventId?: string | null,
  time?: string | null,
  duration?: number | null,
  designator?: PfFlightDesignator | null,
  pax?: number | null,
  weight?: number | null,
  voucherIds?: Array< string | null > | null,
  frontSeatTaken?: boolean | null,
  passengers?: Array< ManifestPassengerInput | null > | null,
  _version?: number | null,
};

export type DeleteManifestItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateFlightInput = {
  id?: string | null,
  tupleId?: string | null,
  eventId: string,
  flightOrderId?: number | null,
  designator: PfFlightDesignator,
  sequence: number,
  flightNumber?: string | null,
  theme?: string | null,
  aircraftName?: string | null,
  isFlightFull?: boolean | null,
  scheduledDeparture?: string | null,
  actualDeparture?: string | null,
  estimatedArrival?: string | null,
  scheduledCheckIn?: string | null,
  status?: FlightStatus | null,
  duration?: number | null,
  users: Array< string >,
  updatedAt?: string | null,
  lastUpdatedBy?: UpdateSource | null,
  _version?: number | null,
};

export type ModelFlightConditionInput = {
  tupleId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  flightOrderId?: ModelIntInput | null,
  designator?: ModelPfFlightDesignatorInput | null,
  sequence?: ModelIntInput | null,
  flightNumber?: ModelStringInput | null,
  theme?: ModelStringInput | null,
  aircraftName?: ModelStringInput | null,
  isFlightFull?: ModelBooleanInput | null,
  scheduledDeparture?: ModelStringInput | null,
  actualDeparture?: ModelStringInput | null,
  estimatedArrival?: ModelStringInput | null,
  scheduledCheckIn?: ModelStringInput | null,
  status?: ModelFlightStatusInput | null,
  duration?: ModelFloatInput | null,
  users?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  lastUpdatedBy?: ModelUpdateSourceInput | null,
  and?: Array< ModelFlightConditionInput | null > | null,
  or?: Array< ModelFlightConditionInput | null > | null,
  not?: ModelFlightConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFlightStatusInput = {
  eq?: FlightStatus | null,
  ne?: FlightStatus | null,
};

export type ModelUpdateSourceInput = {
  eq?: UpdateSource | null,
  ne?: UpdateSource | null,
};

export type UpdateFlightInput = {
  id: string,
  tupleId?: string | null,
  eventId?: string | null,
  flightOrderId?: number | null,
  designator?: PfFlightDesignator | null,
  sequence?: number | null,
  flightNumber?: string | null,
  theme?: string | null,
  aircraftName?: string | null,
  isFlightFull?: boolean | null,
  scheduledDeparture?: string | null,
  actualDeparture?: string | null,
  estimatedArrival?: string | null,
  scheduledCheckIn?: string | null,
  status?: FlightStatus | null,
  duration?: number | null,
  users?: Array< string > | null,
  updatedAt?: string | null,
  lastUpdatedBy?: UpdateSource | null,
  _version?: number | null,
};

export type DeleteFlightInput = {
  id: string,
  _version?: number | null,
};

export type CreateRefuelRequestInput = {
  id?: string | null,
  eventId: string,
  requestedBy?: string | null,
  requestFrom?: TeamMemberRole | null,
  delay?: number | null,
  users: Array< string >,
  flightOrderId?: number | null,
  adventureId?: number | null,
  aircraftId?: number | null,
  aircraftName?: string | null,
  litres?: number | null,
  fuelSource?: FuelSourceInput | null,
  fuelType?: FuelType | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type FuelSourceInput = {
  provider?: FuellingSource | null,
  name?: string | null,
};

export type ModelRefuelRequestConditionInput = {
  eventId?: ModelIDInput | null,
  requestedBy?: ModelStringInput | null,
  requestFrom?: ModelTeamMemberRoleInput | null,
  delay?: ModelIntInput | null,
  users?: ModelStringInput | null,
  flightOrderId?: ModelIntInput | null,
  adventureId?: ModelIntInput | null,
  aircraftId?: ModelIntInput | null,
  aircraftName?: ModelStringInput | null,
  litres?: ModelIntInput | null,
  fuelType?: ModelFuelTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelRefuelRequestConditionInput | null > | null,
  or?: Array< ModelRefuelRequestConditionInput | null > | null,
  not?: ModelRefuelRequestConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFuelTypeInput = {
  eq?: FuelType | null,
  ne?: FuelType | null,
};

export type UpdateRefuelRequestInput = {
  id: string,
  eventId?: string | null,
  requestedBy?: string | null,
  requestFrom?: TeamMemberRole | null,
  delay?: number | null,
  users?: Array< string > | null,
  flightOrderId?: number | null,
  adventureId?: number | null,
  aircraftId?: number | null,
  aircraftName?: string | null,
  litres?: number | null,
  fuelSource?: FuelSourceInput | null,
  fuelType?: FuelType | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteRefuelRequestInput = {
  id: string,
  _version?: number | null,
};

export type CreateRefuelInput = {
  id?: string | null,
  eventId: string,
  refuelRequestId?: string | null,
  aircraftId?: number | null,
  aircraftName?: string | null,
  litres?: number | null,
  fuelSource?: FuelSourceInput | null,
  fuelType?: FuelType | null,
  users: Array< string >,
  createdAt?: string | null,
  refueledAt: string,
  refueledBy?: string | null,
  _version?: number | null,
};

export type ModelRefuelConditionInput = {
  eventId?: ModelIDInput | null,
  refuelRequestId?: ModelIDInput | null,
  aircraftId?: ModelIntInput | null,
  aircraftName?: ModelStringInput | null,
  litres?: ModelIntInput | null,
  fuelType?: ModelFuelTypeInput | null,
  users?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  refueledAt?: ModelStringInput | null,
  refueledBy?: ModelStringInput | null,
  and?: Array< ModelRefuelConditionInput | null > | null,
  or?: Array< ModelRefuelConditionInput | null > | null,
  not?: ModelRefuelConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Refuel = {
  __typename: "Refuel",
  id: string,
  eventId: string,
  refuelRequestId?: string | null,
  refuelRequest?: RefuelRequest | null,
  aircraftId?: number | null,
  aircraftName?: string | null,
  litres?: number | null,
  fuelSource?: FuelSource | null,
  fuelType?: FuelType | null,
  users: Array< string >,
  createdAt: string,
  refueledAt: string,
  refueledBy?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateRefuelInput = {
  id: string,
  eventId?: string | null,
  refuelRequestId?: string | null,
  aircraftId?: number | null,
  aircraftName?: string | null,
  litres?: number | null,
  fuelSource?: FuelSourceInput | null,
  fuelType?: FuelType | null,
  users?: Array< string > | null,
  createdAt?: string | null,
  refueledAt?: string | null,
  refueledBy?: string | null,
  _version?: number | null,
};

export type DeleteRefuelInput = {
  id: string,
  _version?: number | null,
};

export type CreateFlightPassengerInput = {
  id?: string | null,
  eventId: string,
  tupleId?: string | null,
  enquiryId?: number | null,
  voucherId?: string | null,
  designator?: PfFlightDesignator | null,
  sequence?: number | null,
  flightNumber?: string | null,
  flightId?: string | null,
  number: number,
  passengerNumber?: number | null,
  forename: string,
  surname: string,
  weight: number,
  seat: PassengerSeat,
  phone?: string | null,
  email?: string | null,
  postcode?: string | null,
  bubbly?: boolean | null,
  creamTea?: boolean | null,
  lunch?: boolean | null,
  magnum?: boolean | null,
  chocolate?: boolean | null,
  photo?: boolean | null,
  _version?: number | null,
};

export type ModelFlightPassengerConditionInput = {
  eventId?: ModelIDInput | null,
  tupleId?: ModelIDInput | null,
  enquiryId?: ModelIntInput | null,
  voucherId?: ModelStringInput | null,
  designator?: ModelPfFlightDesignatorInput | null,
  sequence?: ModelIntInput | null,
  flightNumber?: ModelStringInput | null,
  flightId?: ModelIDInput | null,
  number?: ModelIntInput | null,
  passengerNumber?: ModelIntInput | null,
  forename?: ModelStringInput | null,
  surname?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  seat?: ModelPassengerSeatInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  bubbly?: ModelBooleanInput | null,
  creamTea?: ModelBooleanInput | null,
  lunch?: ModelBooleanInput | null,
  magnum?: ModelBooleanInput | null,
  chocolate?: ModelBooleanInput | null,
  photo?: ModelBooleanInput | null,
  and?: Array< ModelFlightPassengerConditionInput | null > | null,
  or?: Array< ModelFlightPassengerConditionInput | null > | null,
  not?: ModelFlightPassengerConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPassengerSeatInput = {
  eq?: PassengerSeat | null,
  ne?: PassengerSeat | null,
};

export type UpdateFlightPassengerInput = {
  id: string,
  eventId?: string | null,
  tupleId?: string | null,
  enquiryId?: number | null,
  voucherId?: string | null,
  designator?: PfFlightDesignator | null,
  sequence?: number | null,
  flightNumber?: string | null,
  flightId?: string | null,
  number?: number | null,
  passengerNumber?: number | null,
  forename?: string | null,
  surname?: string | null,
  weight?: number | null,
  seat?: PassengerSeat | null,
  phone?: string | null,
  email?: string | null,
  postcode?: string | null,
  bubbly?: boolean | null,
  creamTea?: boolean | null,
  lunch?: boolean | null,
  magnum?: boolean | null,
  chocolate?: boolean | null,
  photo?: boolean | null,
  _version?: number | null,
};

export type DeleteFlightPassengerInput = {
  id: string,
  _version?: number | null,
};

export type CreateFollowingLogInput = {
  modelType: FollowingLogString,
  id?: string | null,
  user: string,
  pilotName?: string | null,
  type: EventType,
  notes?: string | null,
  eventId?: string | null,
  originId: string,
  originType: FollowingLogOrigin,
  aircraft?: string | null,
  aircraftType?: string | null,
  pob?: number | null,
  action?: FollowingAction | null,
  origin?: string | null,
  destination?: string | null,
  d_lat?: string | null,
  d_lng?: string | null,
  accuracy?: number | null,
  altitude?: number | null,
  altitudeAccuracy?: number | null,
  lat?: string | null,
  lng?: string | null,
  eta?: number | null,
  ete?: number | null,
  gs?: number | null,
  hdg?: number | null,
  battery?: number | null,
  createdAt?: string | null,
  _version?: number | null,
};

export enum FollowingLogString {
  following = "following",
}


export enum FollowingLogOrigin {
  FlightLeg = "FlightLeg",
  Flight = "Flight",
  Event = "Event",
}


export enum FollowingAction {
  pending = "pending",
  lifted = "lifted",
  enroute = "enroute",
  techstop = "techstop",
  landed = "landed",
  complete = "complete",
  cancelled = "cancelled",
  emergency = "emergency",
}


export type ModelFollowingLogConditionInput = {
  modelType?: ModelFollowingLogStringInput | null,
  user?: ModelStringInput | null,
  pilotName?: ModelStringInput | null,
  type?: ModelEventTypeInput | null,
  notes?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  originId?: ModelIDInput | null,
  originType?: ModelFollowingLogOriginInput | null,
  aircraft?: ModelStringInput | null,
  aircraftType?: ModelStringInput | null,
  pob?: ModelIntInput | null,
  action?: ModelFollowingActionInput | null,
  origin?: ModelStringInput | null,
  destination?: ModelStringInput | null,
  d_lat?: ModelStringInput | null,
  d_lng?: ModelStringInput | null,
  accuracy?: ModelFloatInput | null,
  altitude?: ModelFloatInput | null,
  altitudeAccuracy?: ModelFloatInput | null,
  lat?: ModelStringInput | null,
  lng?: ModelStringInput | null,
  eta?: ModelFloatInput | null,
  ete?: ModelFloatInput | null,
  gs?: ModelFloatInput | null,
  hdg?: ModelFloatInput | null,
  battery?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFollowingLogConditionInput | null > | null,
  or?: Array< ModelFollowingLogConditionInput | null > | null,
  not?: ModelFollowingLogConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFollowingLogStringInput = {
  eq?: FollowingLogString | null,
  ne?: FollowingLogString | null,
};

export type ModelFollowingLogOriginInput = {
  eq?: FollowingLogOrigin | null,
  ne?: FollowingLogOrigin | null,
};

export type ModelFollowingActionInput = {
  eq?: FollowingAction | null,
  ne?: FollowingAction | null,
};

export type FollowingLog = {
  __typename: "FollowingLog",
  modelType: FollowingLogString,
  id: string,
  user: string,
  pilotName?: string | null,
  type: EventType,
  notes?: string | null,
  eventId?: string | null,
  originId: string,
  originType: FollowingLogOrigin,
  aircraft?: string | null,
  aircraftType?: string | null,
  pob?: number | null,
  action?: FollowingAction | null,
  origin?: string | null,
  destination?: string | null,
  d_lat?: string | null,
  d_lng?: string | null,
  accuracy?: number | null,
  altitude?: number | null,
  altitudeAccuracy?: number | null,
  lat?: string | null,
  lng?: string | null,
  eta?: number | null,
  ete?: number | null,
  gs?: number | null,
  hdg?: number | null,
  battery?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFollowingLogInput = {
  modelType?: FollowingLogString | null,
  id: string,
  user?: string | null,
  pilotName?: string | null,
  type?: EventType | null,
  notes?: string | null,
  eventId?: string | null,
  originId?: string | null,
  originType?: FollowingLogOrigin | null,
  aircraft?: string | null,
  aircraftType?: string | null,
  pob?: number | null,
  action?: FollowingAction | null,
  origin?: string | null,
  destination?: string | null,
  d_lat?: string | null,
  d_lng?: string | null,
  accuracy?: number | null,
  altitude?: number | null,
  altitudeAccuracy?: number | null,
  lat?: string | null,
  lng?: string | null,
  eta?: number | null,
  ete?: number | null,
  gs?: number | null,
  hdg?: number | null,
  battery?: number | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteFollowingLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateAzureFollowingLogInput = {
  modelType: FollowingLogString,
  id?: string | null,
  user: string,
  pilotName?: string | null,
  type: EventType,
  notes?: string | null,
  eventId?: string | null,
  originId: string,
  originType: FollowingLogOrigin,
  aircraft?: string | null,
  aircraftType?: string | null,
  pob?: number | null,
  action?: FollowingAction | null,
  origin?: string | null,
  destination?: string | null,
  d_lat?: string | null,
  d_lng?: string | null,
  accuracy?: number | null,
  altitude?: number | null,
  altitudeAccuracy?: number | null,
  lat?: string | null,
  lng?: string | null,
  eta?: number | null,
  ete?: number | null,
  gs?: number | null,
  hdg?: number | null,
  battery?: number | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelAzureFollowingLogConditionInput = {
  modelType?: ModelFollowingLogStringInput | null,
  user?: ModelStringInput | null,
  pilotName?: ModelStringInput | null,
  type?: ModelEventTypeInput | null,
  notes?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  originId?: ModelIDInput | null,
  originType?: ModelFollowingLogOriginInput | null,
  aircraft?: ModelStringInput | null,
  aircraftType?: ModelStringInput | null,
  pob?: ModelIntInput | null,
  action?: ModelFollowingActionInput | null,
  origin?: ModelStringInput | null,
  destination?: ModelStringInput | null,
  d_lat?: ModelStringInput | null,
  d_lng?: ModelStringInput | null,
  accuracy?: ModelFloatInput | null,
  altitude?: ModelFloatInput | null,
  altitudeAccuracy?: ModelFloatInput | null,
  lat?: ModelStringInput | null,
  lng?: ModelStringInput | null,
  eta?: ModelFloatInput | null,
  ete?: ModelFloatInput | null,
  gs?: ModelFloatInput | null,
  hdg?: ModelFloatInput | null,
  battery?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAzureFollowingLogConditionInput | null > | null,
  or?: Array< ModelAzureFollowingLogConditionInput | null > | null,
  not?: ModelAzureFollowingLogConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type AzureFollowingLog = {
  __typename: "AzureFollowingLog",
  modelType: FollowingLogString,
  id: string,
  user: string,
  pilotName?: string | null,
  type: EventType,
  notes?: string | null,
  eventId?: string | null,
  originId: string,
  originType: FollowingLogOrigin,
  aircraft?: string | null,
  aircraftType?: string | null,
  pob?: number | null,
  action?: FollowingAction | null,
  origin?: string | null,
  destination?: string | null,
  d_lat?: string | null,
  d_lng?: string | null,
  accuracy?: number | null,
  altitude?: number | null,
  altitudeAccuracy?: number | null,
  lat?: string | null,
  lng?: string | null,
  eta?: number | null,
  ete?: number | null,
  gs?: number | null,
  hdg?: number | null,
  battery?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAzureFollowingLogInput = {
  modelType?: FollowingLogString | null,
  id: string,
  user?: string | null,
  pilotName?: string | null,
  type?: EventType | null,
  notes?: string | null,
  eventId?: string | null,
  originId?: string | null,
  originType?: FollowingLogOrigin | null,
  aircraft?: string | null,
  aircraftType?: string | null,
  pob?: number | null,
  action?: FollowingAction | null,
  origin?: string | null,
  destination?: string | null,
  d_lat?: string | null,
  d_lng?: string | null,
  accuracy?: number | null,
  altitude?: number | null,
  altitudeAccuracy?: number | null,
  lat?: string | null,
  lng?: string | null,
  eta?: number | null,
  ete?: number | null,
  gs?: number | null,
  hdg?: number | null,
  battery?: number | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteAzureFollowingLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateFlightLegInput = {
  id?: string | null,
  eventId: string,
  order: number,
  venueId?: number | null,
  venueName?: string | null,
  venuePostcode?: string | null,
  empty?: boolean | null,
  departing?: string | null,
  fromVenueId?: number | null,
  fromVenueName?: string | null,
  fromVenuePostcode?: string | null,
  fromCoords?: CoordsInput | null,
  arriving?: string | null,
  toVenueId?: number | null,
  toVenueName?: string | null,
  toVenuePostcode?: string | null,
  toCoords?: CoordsInput | null,
  distance?: number | null,
  ete?: number | null,
  lat?: number | null,
  lng?: number | null,
  w3w?: string | null,
  status?: EventStatus | null,
  _version?: number | null,
};

export type CoordsInput = {
  lat?: number | null,
  lng?: number | null,
};

export type ModelFlightLegConditionInput = {
  eventId?: ModelIDInput | null,
  order?: ModelIntInput | null,
  venueId?: ModelIntInput | null,
  venueName?: ModelStringInput | null,
  venuePostcode?: ModelStringInput | null,
  empty?: ModelBooleanInput | null,
  departing?: ModelStringInput | null,
  fromVenueId?: ModelIntInput | null,
  fromVenueName?: ModelStringInput | null,
  fromVenuePostcode?: ModelStringInput | null,
  arriving?: ModelStringInput | null,
  toVenueId?: ModelIntInput | null,
  toVenueName?: ModelStringInput | null,
  toVenuePostcode?: ModelStringInput | null,
  distance?: ModelFloatInput | null,
  ete?: ModelFloatInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  w3w?: ModelStringInput | null,
  status?: ModelEventStatusInput | null,
  and?: Array< ModelFlightLegConditionInput | null > | null,
  or?: Array< ModelFlightLegConditionInput | null > | null,
  not?: ModelFlightLegConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateFlightLegInput = {
  id: string,
  eventId?: string | null,
  order?: number | null,
  venueId?: number | null,
  venueName?: string | null,
  venuePostcode?: string | null,
  empty?: boolean | null,
  departing?: string | null,
  fromVenueId?: number | null,
  fromVenueName?: string | null,
  fromVenuePostcode?: string | null,
  fromCoords?: CoordsInput | null,
  arriving?: string | null,
  toVenueId?: number | null,
  toVenueName?: string | null,
  toVenuePostcode?: string | null,
  toCoords?: CoordsInput | null,
  distance?: number | null,
  ete?: number | null,
  lat?: number | null,
  lng?: number | null,
  w3w?: string | null,
  status?: EventStatus | null,
  _version?: number | null,
};

export type DeleteFlightLegInput = {
  id: string,
  _version?: number | null,
};

export type CreatePushSubscriptionInput = {
  user: string,
  channel: PushChannel,
  deviceToken: string,
  deviceType?: string | null,
  expoPushToken: string,
  _version?: number | null,
};

export enum PushChannel {
  BROADCAST = "BROADCAST",
  FOLLOWING_LIFTED = "FOLLOWING_LIFTED",
  FOLLOWING_ENROUTE = "FOLLOWING_ENROUTE",
  FOLLOWING_LANDED = "FOLLOWING_LANDED",
  FOLLOWING_EMERGENCY = "FOLLOWING_EMERGENCY",
  FOLLOWING_OTHER = "FOLLOWING_OTHER",
}


export type ModelPushSubscriptionConditionInput = {
  deviceType?: ModelStringInput | null,
  and?: Array< ModelPushSubscriptionConditionInput | null > | null,
  or?: Array< ModelPushSubscriptionConditionInput | null > | null,
  not?: ModelPushSubscriptionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type PushSubscription = {
  __typename: "PushSubscription",
  user: string,
  channel: PushChannel,
  deviceToken: string,
  deviceType?: string | null,
  expoPushToken: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePushSubscriptionInput = {
  user: string,
  channel: PushChannel,
  deviceToken: string,
  deviceType?: string | null,
  expoPushToken: string,
  _version?: number | null,
};

export type DeletePushSubscriptionInput = {
  user: string,
  channel: PushChannel,
  deviceToken: string,
  expoPushToken: string,
  _version?: number | null,
};

export type CreateUserSettingInput = {
  teamLeaderRegionId?: number | null,
  teamLeaderRegionIds?: Array< number | null > | null,
  teamResourceId?: number | null,
  owner: string,
  profileImage?: string | null,
  callsign?: string | null,
  calCode?: string | null,
  appVersion?: string | null,
  weightDisplay?: WeightDisplay | null,
  accessibilityIcons?: boolean | null,
  _version?: number | null,
};

export enum WeightDisplay {
  kgs = "kgs",
  lbs = "lbs",
}


export type ModelUserSettingConditionInput = {
  teamLeaderRegionId?: ModelIntInput | null,
  teamLeaderRegionIds?: ModelIntInput | null,
  teamResourceId?: ModelIntInput | null,
  profileImage?: ModelStringInput | null,
  callsign?: ModelStringInput | null,
  calCode?: ModelStringInput | null,
  appVersion?: ModelStringInput | null,
  weightDisplay?: ModelWeightDisplayInput | null,
  accessibilityIcons?: ModelBooleanInput | null,
  and?: Array< ModelUserSettingConditionInput | null > | null,
  or?: Array< ModelUserSettingConditionInput | null > | null,
  not?: ModelUserSettingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelWeightDisplayInput = {
  eq?: WeightDisplay | null,
  ne?: WeightDisplay | null,
};

export type UserSetting = {
  __typename: "UserSetting",
  teamLeaderRegionId?: number | null,
  teamLeaderRegionIds?: Array< number | null > | null,
  teamResourceId?: number | null,
  owner: string,
  profileImage?: string | null,
  callsign?: string | null,
  calCode?: string | null,
  appVersion?: string | null,
  weightDisplay?: WeightDisplay | null,
  accessibilityIcons?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserSettingInput = {
  teamLeaderRegionId?: number | null,
  teamLeaderRegionIds?: Array< number | null > | null,
  teamResourceId?: number | null,
  owner: string,
  profileImage?: string | null,
  callsign?: string | null,
  calCode?: string | null,
  appVersion?: string | null,
  weightDisplay?: WeightDisplay | null,
  accessibilityIcons?: boolean | null,
  _version?: number | null,
};

export type DeleteUserSettingInput = {
  owner: string,
  _version?: number | null,
};

export type CreateCertificateInput = {
  owner: string,
  model: CertificateModel,
  type: CertificateType,
  number?: string | null,
  aircraft?: string | null,
  pdf?: string | null,
  expiresAt?: string | null,
  _version?: number | null,
};

export enum CertificateModel {
  user = "user",
  pilot = "pilot",
}


export enum CertificateType {
  firstaid = "firstaid",
  fire = "fire",
  insurance = "insurance",
  licence = "licence",
  other = "other",
  photography = "photography",
  handling = "handling",
  promotions = "promotions",
  sms = "sms",
  refuelling = "refuelling",
  safety_briefings = "safety_briefings",
  ops1 = "ops1",
  ops2 = "ops2",
  opc = "opc",
  lpc = "lpc",
  line = "line",
  crm = "crm",
  crs = "crs",
  dgoods = "dgoods",
  medical = "medical",
}


export type ModelCertificateConditionInput = {
  number?: ModelStringInput | null,
  aircraft?: ModelStringInput | null,
  pdf?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  and?: Array< ModelCertificateConditionInput | null > | null,
  or?: Array< ModelCertificateConditionInput | null > | null,
  not?: ModelCertificateConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Certificate = {
  __typename: "Certificate",
  owner: string,
  model: CertificateModel,
  type: CertificateType,
  number?: string | null,
  aircraft?: string | null,
  pdf?: string | null,
  expiresAt?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCertificateInput = {
  owner: string,
  model: CertificateModel,
  type: CertificateType,
  number?: string | null,
  aircraft?: string | null,
  pdf?: string | null,
  expiresAt?: string | null,
  _version?: number | null,
};

export type DeleteCertificateInput = {
  owner: string,
  model: CertificateModel,
  type: CertificateType,
  _version?: number | null,
};

export type CreateUserCertificateInput = {
  key: string,
  owner: string,
  model: CertificateModel,
  type: CertificateType,
  number?: string | null,
  aircraft?: string | null,
  pdf?: string | null,
  expiresAt?: string | null,
  _version?: number | null,
};

export type ModelUserCertificateConditionInput = {
  owner?: ModelStringInput | null,
  model?: ModelCertificateModelInput | null,
  type?: ModelCertificateTypeInput | null,
  number?: ModelStringInput | null,
  aircraft?: ModelStringInput | null,
  pdf?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  and?: Array< ModelUserCertificateConditionInput | null > | null,
  or?: Array< ModelUserCertificateConditionInput | null > | null,
  not?: ModelUserCertificateConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCertificateModelInput = {
  eq?: CertificateModel | null,
  ne?: CertificateModel | null,
};

export type ModelCertificateTypeInput = {
  eq?: CertificateType | null,
  ne?: CertificateType | null,
};

export type UserCertificate = {
  __typename: "UserCertificate",
  key: string,
  owner: string,
  model: CertificateModel,
  type: CertificateType,
  number?: string | null,
  aircraft?: string | null,
  pdf?: string | null,
  expiresAt?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserCertificateInput = {
  key: string,
  owner?: string | null,
  model?: CertificateModel | null,
  type?: CertificateType | null,
  number?: string | null,
  aircraft?: string | null,
  pdf?: string | null,
  expiresAt?: string | null,
  _version?: number | null,
};

export type DeleteUserCertificateInput = {
  key: string,
  _version?: number | null,
};

export type CreateBroadcastMessageInput = {
  id?: string | null,
  modelType?: BroadcastMessageString | null,
  message: string,
  title?: string | null,
  originType?: BroadcastMessageOrigin | null,
  originId?: string | null,
  sentBy?: string | null,
  url?: string | null,
  extra?: string | null,
  targetedGroup?: UserGroups | null,
  targetedRole?: TeamMemberRole | null,
  targetedEventId?: string | null,
  targetedUsers?: Array< string | null > | null,
  users?: Array< string | null > | null,
  broadcastAt?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type UpdateBroadcastMessageInput = {
  id: string,
  modelType?: BroadcastMessageString | null,
  message?: string | null,
  title?: string | null,
  originType?: BroadcastMessageOrigin | null,
  originId?: string | null,
  sentBy?: string | null,
  url?: string | null,
  extra?: string | null,
  targetedGroup?: UserGroups | null,
  targetedRole?: TeamMemberRole | null,
  targetedEventId?: string | null,
  targetedUsers?: Array< string | null > | null,
  users?: Array< string | null > | null,
  broadcastAt?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type CreateBroadcastReceiptInput = {
  id?: string | null,
  broadcastMessageId?: string | null,
  owner: string,
  pushToken: string,
  deviceToken: string,
  sentAt?: string | null,
  status?: BroadcastReceiptStatus | null,
  message?: string | null,
  details?: string | null,
  deliveredAt?: string | null,
  errors?: string | null,
  _version?: number | null,
};

export enum BroadcastReceiptStatus {
  error = "error",
  ok = "ok",
}


export type ModelBroadcastReceiptConditionInput = {
  broadcastMessageId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  pushToken?: ModelStringInput | null,
  deviceToken?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  status?: ModelBroadcastReceiptStatusInput | null,
  message?: ModelStringInput | null,
  details?: ModelStringInput | null,
  deliveredAt?: ModelStringInput | null,
  errors?: ModelStringInput | null,
  and?: Array< ModelBroadcastReceiptConditionInput | null > | null,
  or?: Array< ModelBroadcastReceiptConditionInput | null > | null,
  not?: ModelBroadcastReceiptConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBroadcastReceiptStatusInput = {
  eq?: BroadcastReceiptStatus | null,
  ne?: BroadcastReceiptStatus | null,
};

export type BroadcastReceipt = {
  __typename: "BroadcastReceipt",
  id: string,
  broadcastMessageId?: string | null,
  broadcastMessage?: BroadcastMessage | null,
  owner: string,
  pushToken: string,
  deviceToken: string,
  sentAt?: string | null,
  status?: BroadcastReceiptStatus | null,
  message?: string | null,
  details?: string | null,
  deliveredAt?: string | null,
  errors?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateBroadcastReceiptInput = {
  id: string,
  broadcastMessageId?: string | null,
  owner?: string | null,
  pushToken?: string | null,
  deviceToken?: string | null,
  sentAt?: string | null,
  status?: BroadcastReceiptStatus | null,
  message?: string | null,
  details?: string | null,
  deliveredAt?: string | null,
  errors?: string | null,
  _version?: number | null,
};

export type DeleteBroadcastReceiptInput = {
  id: string,
  _version?: number | null,
};

export type CreateExperimentInput = {
  owner: string,
  experiment: Experiments,
  enabled: boolean,
  extra?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export enum Experiments {
  following_geofence = "following_geofence",
  adventure_api = "adventure_api",
}


export type ModelExperimentConditionInput = {
  enabled?: ModelBooleanInput | null,
  extra?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelExperimentConditionInput | null > | null,
  or?: Array< ModelExperimentConditionInput | null > | null,
  not?: ModelExperimentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Experiment = {
  __typename: "Experiment",
  owner: string,
  experiment: Experiments,
  enabled: boolean,
  extra?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateExperimentInput = {
  owner: string,
  experiment: Experiments,
  enabled?: boolean | null,
  extra?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteExperimentInput = {
  owner: string,
  experiment: Experiments,
  _version?: number | null,
};

export type CreatePhotoAssetInput = {
  id?: string | null,
  file?: S3ObjectInput | null,
  files?: Array< PhotoObjectInput | null > | null,
  enquiryId?: string | null,
  passengers?: string | null,
  email?: string | null,
  flightId: string,
  eventId: string,
  salesAtEvent?: PhotoSalesInput | null,
  salesAfterEvent?: PhotoSalesInput | null,
  soldBy: string,
  emailSent?: boolean | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
};

export type PhotoObjectInput = {
  file?: S3ObjectInput | null,
  soldAtEvent?: PhotoSalesInput | null,
  soldAfterEvent?: PhotoSalesInput | null,
};

export type PhotoSalesInput = {
  physical?: PhotoSaleInput | null,
  digital?: PhotoSaleInput | null,
};

export type PhotoSaleInput = {
  copies?: number | null,
  delivered?: boolean | null,
  cost?: PhotoSaleCostInput | null,
};

export type PhotoSaleCostInput = {
  unitCost?: number | null,
  discount?: number | null,
  total?: number | null,
};

export type ModelPhotoAssetConditionInput = {
  enquiryId?: ModelStringInput | null,
  passengers?: ModelStringInput | null,
  email?: ModelStringInput | null,
  flightId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  soldBy?: ModelStringInput | null,
  emailSent?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPhotoAssetConditionInput | null > | null,
  or?: Array< ModelPhotoAssetConditionInput | null > | null,
  not?: ModelPhotoAssetConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type PhotoAsset = {
  __typename: "PhotoAsset",
  id: string,
  file?: S3Object | null,
  files?:  Array<PhotoObject | null > | null,
  enquiryId?: string | null,
  passengers?: string | null,
  email?: string | null,
  flightId: string,
  flight?: Flight | null,
  eventId: string,
  event?: Event | null,
  salesAtEvent?: PhotoSales | null,
  salesAfterEvent?: PhotoSales | null,
  soldBy: string,
  emailSent?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
};

export type PhotoObject = {
  __typename: "PhotoObject",
  file?: S3Object | null,
  soldAtEvent?: PhotoSales | null,
  soldAfterEvent?: PhotoSales | null,
};

export type PhotoSales = {
  __typename: "PhotoSales",
  physical?: PhotoSale | null,
  digital?: PhotoSale | null,
};

export type PhotoSale = {
  __typename: "PhotoSale",
  copies?: number | null,
  delivered?: boolean | null,
  cost?: PhotoSaleCost | null,
};

export type PhotoSaleCost = {
  __typename: "PhotoSaleCost",
  unitCost?: number | null,
  discount?: number | null,
  total?: number | null,
};

export type UpdatePhotoAssetInput = {
  id: string,
  file?: S3ObjectInput | null,
  files?: Array< PhotoObjectInput | null > | null,
  enquiryId?: string | null,
  passengers?: string | null,
  email?: string | null,
  flightId?: string | null,
  eventId?: string | null,
  salesAtEvent?: PhotoSalesInput | null,
  salesAfterEvent?: PhotoSalesInput | null,
  soldBy?: string | null,
  emailSent?: boolean | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeletePhotoAssetInput = {
  id: string,
  _version?: number | null,
};

export type CreateSafetyCommInput = {
  id?: string | null,
  title: string,
  modelType: SafetyCommString,
  url?: string | null,
  file?: S3ObjectInput | null,
  publishedBy?: string | null,
  publishedAt: string,
  createdAt?: string | null,
  _version?: number | null,
};

export enum SafetyCommString {
  safetycomm = "safetycomm",
}


export type ModelSafetyCommConditionInput = {
  title?: ModelStringInput | null,
  modelType?: ModelSafetyCommStringInput | null,
  url?: ModelStringInput | null,
  publishedBy?: ModelStringInput | null,
  publishedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSafetyCommConditionInput | null > | null,
  or?: Array< ModelSafetyCommConditionInput | null > | null,
  not?: ModelSafetyCommConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSafetyCommStringInput = {
  eq?: SafetyCommString | null,
  ne?: SafetyCommString | null,
};

export type SafetyComm = {
  __typename: "SafetyComm",
  id: string,
  title: string,
  modelType: SafetyCommString,
  url?: string | null,
  file?: S3Object | null,
  publishedBy?: string | null,
  publishedAt: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSafetyCommInput = {
  id: string,
  title?: string | null,
  modelType?: SafetyCommString | null,
  url?: string | null,
  file?: S3ObjectInput | null,
  publishedBy?: string | null,
  publishedAt?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteSafetyCommInput = {
  id: string,
  _version?: number | null,
};

export type ModelEventFilterInput = {
  modelType?: ModelTaskTypeStringInput | null,
  id?: ModelIDInput | null,
  type?: ModelEventTypeInput | null,
  oid?: ModelStringInput | null,
  venue?: ModelStringInput | null,
  venueId?: ModelIntInput | null,
  regionId?: ModelIntInput | null,
  date?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  pax?: ModelIntInput | null,
  product?: ModelStringInput | null,
  ref?: ModelStringInput | null,
  description?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  users?: ModelStringInput | null,
  pilotName?: ModelStringInput | null,
  pilotConfirmed?: ModelBooleanInput | null,
  aircraftId?: ModelIntInput | null,
  aircraftType?: ModelStringInput | null,
  aircraftName?: ModelStringInput | null,
  aircraftSeats?: ModelIntInput | null,
  aircraft?: ModelStringInput | null,
  duration?: ModelFloatInput | null,
  client?: ModelStringInput | null,
  status?: ModelEventStatusInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  eventOverviewId?: ModelIDInput | null,
  eventCurrentFlightId?: ModelIDInput | null,
  eventCurrentRefuelRequestId?: ModelIDInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type SearchableEventFilterInput = {
  id?: SearchableIDFilterInput | null,
  oid?: SearchableStringFilterInput | null,
  venue?: SearchableStringFilterInput | null,
  venueId?: SearchableIntFilterInput | null,
  regionId?: SearchableIntFilterInput | null,
  date?: SearchableStringFilterInput | null,
  endDate?: SearchableStringFilterInput | null,
  pax?: SearchableIntFilterInput | null,
  product?: SearchableStringFilterInput | null,
  ref?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  notes?: SearchableStringFilterInput | null,
  users?: SearchableStringFilterInput | null,
  pilotName?: SearchableStringFilterInput | null,
  pilotConfirmed?: SearchableBooleanFilterInput | null,
  aircraftId?: SearchableIntFilterInput | null,
  aircraftType?: SearchableStringFilterInput | null,
  aircraftName?: SearchableStringFilterInput | null,
  aircraftSeats?: SearchableIntFilterInput | null,
  aircraft?: SearchableStringFilterInput | null,
  duration?: SearchableFloatFilterInput | null,
  client?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  eventOverviewId?: SearchableIDFilterInput | null,
  eventCurrentFlightId?: SearchableIDFilterInput | null,
  eventCurrentRefuelRequestId?: SearchableIDFilterInput | null,
  modelType?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  and?: Array< SearchableEventFilterInput | null > | null,
  or?: Array< SearchableEventFilterInput | null > | null,
  not?: SearchableEventFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableEventSortInput = {
  field?: SearchableEventSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEventSortableFields {
  id = "id",
  oid = "oid",
  venue = "venue",
  venueId = "venueId",
  regionId = "regionId",
  date = "date",
  endDate = "endDate",
  pax = "pax",
  product = "product",
  ref = "ref",
  description = "description",
  notes = "notes",
  users = "users",
  pilotName = "pilotName",
  pilotConfirmed = "pilotConfirmed",
  aircraftId = "aircraftId",
  aircraftType = "aircraftType",
  aircraftName = "aircraftName",
  aircraftSeats = "aircraftSeats",
  aircraft = "aircraft",
  duration = "duration",
  client = "client",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
  eventOverviewId = "eventOverviewId",
  eventCurrentFlightId = "eventCurrentFlightId",
  eventCurrentRefuelRequestId = "eventCurrentRefuelRequestId",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableEventAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableEventAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableEventAggregateField {
  modelType = "modelType",
  id = "id",
  type = "type",
  oid = "oid",
  venue = "venue",
  venueId = "venueId",
  regionId = "regionId",
  date = "date",
  endDate = "endDate",
  pax = "pax",
  product = "product",
  ref = "ref",
  description = "description",
  notes = "notes",
  users = "users",
  pilotName = "pilotName",
  pilotConfirmed = "pilotConfirmed",
  aircraftId = "aircraftId",
  aircraftType = "aircraftType",
  aircraftName = "aircraftName",
  aircraftSeats = "aircraftSeats",
  aircraft = "aircraft",
  duration = "duration",
  client = "client",
  status = "status",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
  eventOverviewId = "eventOverviewId",
  eventCurrentFlightId = "eventCurrentFlightId",
  eventCurrentRefuelRequestId = "eventCurrentRefuelRequestId",
}


export type SearchableEventConnection = {
  __typename: "SearchableEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelPleasureOverviewFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  paxBooked?: ModelIntInput | null,
  aircraftReg?: ModelStringInput | null,
  aircraftSeats?: ModelIntInput | null,
  fireName?: ModelStringInput | null,
  fireReg?: ModelStringInput | null,
  bowserName?: ModelStringInput | null,
  truckName?: ModelStringInput | null,
  truckReg?: ModelStringInput | null,
  counts?: ModelStringInput | null,
  teamLeaderId?: ModelIDInput | null,
  disabledRoles?: ModelStringInput | null,
  and?: Array< ModelPleasureOverviewFilterInput | null > | null,
  or?: Array< ModelPleasureOverviewFilterInput | null > | null,
  not?: ModelPleasureOverviewFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPleasureOverviewConnection = {
  __typename: "ModelPleasureOverviewConnection",
  items:  Array<PleasureOverview | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTeamMemberFilterInput = {
  id?: ModelIDInput | null,
  overviewId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  forename?: ModelStringInput | null,
  surname?: ModelStringInput | null,
  teamResourceId?: ModelIntInput | null,
  teamRole?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  confirmed?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTeamMemberFilterInput | null > | null,
  or?: Array< ModelTeamMemberFilterInput | null > | null,
  not?: ModelTeamMemberFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelManifestItemFilterInput = {
  id?: ModelIDInput | null,
  overviewId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  time?: ModelStringInput | null,
  duration?: ModelFloatInput | null,
  designator?: ModelPfFlightDesignatorInput | null,
  pax?: ModelIntInput | null,
  weight?: ModelIntInput | null,
  voucherIds?: ModelStringInput | null,
  frontSeatTaken?: ModelBooleanInput | null,
  and?: Array< ModelManifestItemFilterInput | null > | null,
  or?: Array< ModelManifestItemFilterInput | null > | null,
  not?: ModelManifestItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type SearchableManifestItemFilterInput = {
  id?: SearchableIDFilterInput | null,
  overviewId?: SearchableIDFilterInput | null,
  eventId?: SearchableIDFilterInput | null,
  time?: SearchableStringFilterInput | null,
  duration?: SearchableFloatFilterInput | null,
  pax?: SearchableIntFilterInput | null,
  weight?: SearchableIntFilterInput | null,
  voucherIds?: SearchableStringFilterInput | null,
  frontSeatTaken?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  designator?: SearchableStringFilterInput | null,
  and?: Array< SearchableManifestItemFilterInput | null > | null,
  or?: Array< SearchableManifestItemFilterInput | null > | null,
  not?: SearchableManifestItemFilterInput | null,
};

export type SearchableManifestItemSortInput = {
  field?: SearchableManifestItemSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableManifestItemSortableFields {
  id = "id",
  overviewId = "overviewId",
  eventId = "eventId",
  time = "time",
  duration = "duration",
  pax = "pax",
  weight = "weight",
  voucherIds = "voucherIds",
  frontSeatTaken = "frontSeatTaken",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableManifestItemAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableManifestItemAggregateField,
};

export enum SearchableManifestItemAggregateField {
  id = "id",
  overviewId = "overviewId",
  eventId = "eventId",
  time = "time",
  duration = "duration",
  designator = "designator",
  pax = "pax",
  weight = "weight",
  voucherIds = "voucherIds",
  frontSeatTaken = "frontSeatTaken",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableManifestItemConnection = {
  __typename: "SearchableManifestItemConnection",
  items:  Array<ManifestItem | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelFlightFilterInput = {
  id?: ModelIDInput | null,
  tupleId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  flightOrderId?: ModelIntInput | null,
  designator?: ModelPfFlightDesignatorInput | null,
  sequence?: ModelIntInput | null,
  flightNumber?: ModelStringInput | null,
  theme?: ModelStringInput | null,
  aircraftName?: ModelStringInput | null,
  isFlightFull?: ModelBooleanInput | null,
  scheduledDeparture?: ModelStringInput | null,
  actualDeparture?: ModelStringInput | null,
  estimatedArrival?: ModelStringInput | null,
  scheduledCheckIn?: ModelStringInput | null,
  status?: ModelFlightStatusInput | null,
  duration?: ModelFloatInput | null,
  users?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  lastUpdatedBy?: ModelUpdateSourceInput | null,
  and?: Array< ModelFlightFilterInput | null > | null,
  or?: Array< ModelFlightFilterInput | null > | null,
  not?: ModelFlightFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type SearchableFlightFilterInput = {
  id?: SearchableIDFilterInput | null,
  tupleId?: SearchableIDFilterInput | null,
  eventId?: SearchableIDFilterInput | null,
  flightOrderId?: SearchableIntFilterInput | null,
  sequence?: SearchableIntFilterInput | null,
  flightNumber?: SearchableStringFilterInput | null,
  theme?: SearchableStringFilterInput | null,
  aircraftName?: SearchableStringFilterInput | null,
  isFlightFull?: SearchableBooleanFilterInput | null,
  scheduledDeparture?: SearchableStringFilterInput | null,
  actualDeparture?: SearchableStringFilterInput | null,
  estimatedArrival?: SearchableStringFilterInput | null,
  scheduledCheckIn?: SearchableStringFilterInput | null,
  duration?: SearchableFloatFilterInput | null,
  users?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  designator?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  lastUpdatedBy?: SearchableStringFilterInput | null,
  and?: Array< SearchableFlightFilterInput | null > | null,
  or?: Array< SearchableFlightFilterInput | null > | null,
  not?: SearchableFlightFilterInput | null,
};

export type SearchableFlightSortInput = {
  field?: SearchableFlightSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFlightSortableFields {
  id = "id",
  tupleId = "tupleId",
  eventId = "eventId",
  flightOrderId = "flightOrderId",
  sequence = "sequence",
  flightNumber = "flightNumber",
  theme = "theme",
  aircraftName = "aircraftName",
  isFlightFull = "isFlightFull",
  scheduledDeparture = "scheduledDeparture",
  actualDeparture = "actualDeparture",
  estimatedArrival = "estimatedArrival",
  scheduledCheckIn = "scheduledCheckIn",
  duration = "duration",
  users = "users",
  updatedAt = "updatedAt",
  createdAt = "createdAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableFlightAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableFlightAggregateField,
};

export enum SearchableFlightAggregateField {
  id = "id",
  tupleId = "tupleId",
  eventId = "eventId",
  flightOrderId = "flightOrderId",
  designator = "designator",
  sequence = "sequence",
  flightNumber = "flightNumber",
  theme = "theme",
  aircraftName = "aircraftName",
  isFlightFull = "isFlightFull",
  scheduledDeparture = "scheduledDeparture",
  actualDeparture = "actualDeparture",
  estimatedArrival = "estimatedArrival",
  scheduledCheckIn = "scheduledCheckIn",
  status = "status",
  duration = "duration",
  users = "users",
  updatedAt = "updatedAt",
  lastUpdatedBy = "lastUpdatedBy",
  createdAt = "createdAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableFlightConnection = {
  __typename: "SearchableFlightConnection",
  items:  Array<Flight | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelRefuelRequestFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  requestedBy?: ModelStringInput | null,
  requestFrom?: ModelTeamMemberRoleInput | null,
  delay?: ModelIntInput | null,
  users?: ModelStringInput | null,
  flightOrderId?: ModelIntInput | null,
  adventureId?: ModelIntInput | null,
  aircraftId?: ModelIntInput | null,
  aircraftName?: ModelStringInput | null,
  litres?: ModelIntInput | null,
  fuelType?: ModelFuelTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelRefuelRequestFilterInput | null > | null,
  or?: Array< ModelRefuelRequestFilterInput | null > | null,
  not?: ModelRefuelRequestFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRefuelRequestConnection = {
  __typename: "ModelRefuelRequestConnection",
  items:  Array<RefuelRequest | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRefuelFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  refuelRequestId?: ModelIDInput | null,
  aircraftId?: ModelIntInput | null,
  aircraftName?: ModelStringInput | null,
  litres?: ModelIntInput | null,
  fuelType?: ModelFuelTypeInput | null,
  users?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  refueledAt?: ModelStringInput | null,
  refueledBy?: ModelStringInput | null,
  and?: Array< ModelRefuelFilterInput | null > | null,
  or?: Array< ModelRefuelFilterInput | null > | null,
  not?: ModelRefuelFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRefuelConnection = {
  __typename: "ModelRefuelConnection",
  items:  Array<Refuel | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFlightPassengerFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  tupleId?: ModelIDInput | null,
  enquiryId?: ModelIntInput | null,
  voucherId?: ModelStringInput | null,
  designator?: ModelPfFlightDesignatorInput | null,
  sequence?: ModelIntInput | null,
  flightNumber?: ModelStringInput | null,
  flightId?: ModelIDInput | null,
  number?: ModelIntInput | null,
  passengerNumber?: ModelIntInput | null,
  forename?: ModelStringInput | null,
  surname?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  seat?: ModelPassengerSeatInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  bubbly?: ModelBooleanInput | null,
  creamTea?: ModelBooleanInput | null,
  lunch?: ModelBooleanInput | null,
  magnum?: ModelBooleanInput | null,
  chocolate?: ModelBooleanInput | null,
  photo?: ModelBooleanInput | null,
  and?: Array< ModelFlightPassengerFilterInput | null > | null,
  or?: Array< ModelFlightPassengerFilterInput | null > | null,
  not?: ModelFlightPassengerFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFlightPassengerByEventCompositeKeyConditionInput = {
  eq?: ModelFlightPassengerByEventCompositeKeyInput | null,
  le?: ModelFlightPassengerByEventCompositeKeyInput | null,
  lt?: ModelFlightPassengerByEventCompositeKeyInput | null,
  ge?: ModelFlightPassengerByEventCompositeKeyInput | null,
  gt?: ModelFlightPassengerByEventCompositeKeyInput | null,
  between?: Array< ModelFlightPassengerByEventCompositeKeyInput | null > | null,
  beginsWith?: ModelFlightPassengerByEventCompositeKeyInput | null,
};

export type ModelFlightPassengerByEventCompositeKeyInput = {
  flightId?: string | null,
  number?: number | null,
};

export type ModelFlightPassengerByFlightCompositeKeyConditionInput = {
  eq?: ModelFlightPassengerByFlightCompositeKeyInput | null,
  le?: ModelFlightPassengerByFlightCompositeKeyInput | null,
  lt?: ModelFlightPassengerByFlightCompositeKeyInput | null,
  ge?: ModelFlightPassengerByFlightCompositeKeyInput | null,
  gt?: ModelFlightPassengerByFlightCompositeKeyInput | null,
  between?: Array< ModelFlightPassengerByFlightCompositeKeyInput | null > | null,
  beginsWith?: ModelFlightPassengerByFlightCompositeKeyInput | null,
};

export type ModelFlightPassengerByFlightCompositeKeyInput = {
  eventId?: string | null,
  number?: number | null,
};

export type SearchableFlightPassengerFilterInput = {
  id?: SearchableIDFilterInput | null,
  eventId?: SearchableIDFilterInput | null,
  tupleId?: SearchableIDFilterInput | null,
  enquiryId?: SearchableIntFilterInput | null,
  voucherId?: SearchableStringFilterInput | null,
  sequence?: SearchableIntFilterInput | null,
  flightNumber?: SearchableStringFilterInput | null,
  flightId?: SearchableIDFilterInput | null,
  number?: SearchableIntFilterInput | null,
  passengerNumber?: SearchableIntFilterInput | null,
  forename?: SearchableStringFilterInput | null,
  surname?: SearchableStringFilterInput | null,
  weight?: SearchableFloatFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  postcode?: SearchableStringFilterInput | null,
  bubbly?: SearchableBooleanFilterInput | null,
  creamTea?: SearchableBooleanFilterInput | null,
  lunch?: SearchableBooleanFilterInput | null,
  magnum?: SearchableBooleanFilterInput | null,
  chocolate?: SearchableBooleanFilterInput | null,
  photo?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  designator?: SearchableStringFilterInput | null,
  seat?: SearchableStringFilterInput | null,
  and?: Array< SearchableFlightPassengerFilterInput | null > | null,
  or?: Array< SearchableFlightPassengerFilterInput | null > | null,
  not?: SearchableFlightPassengerFilterInput | null,
};

export type SearchableFlightPassengerSortInput = {
  field?: SearchableFlightPassengerSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFlightPassengerSortableFields {
  id = "id",
  eventId = "eventId",
  tupleId = "tupleId",
  enquiryId = "enquiryId",
  voucherId = "voucherId",
  sequence = "sequence",
  flightNumber = "flightNumber",
  flightId = "flightId",
  number = "number",
  passengerNumber = "passengerNumber",
  forename = "forename",
  surname = "surname",
  weight = "weight",
  phone = "phone",
  email = "email",
  postcode = "postcode",
  bubbly = "bubbly",
  creamTea = "creamTea",
  lunch = "lunch",
  magnum = "magnum",
  chocolate = "chocolate",
  photo = "photo",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableFlightPassengerAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableFlightPassengerAggregateField,
};

export enum SearchableFlightPassengerAggregateField {
  id = "id",
  eventId = "eventId",
  tupleId = "tupleId",
  enquiryId = "enquiryId",
  voucherId = "voucherId",
  designator = "designator",
  sequence = "sequence",
  flightNumber = "flightNumber",
  flightId = "flightId",
  number = "number",
  passengerNumber = "passengerNumber",
  forename = "forename",
  surname = "surname",
  weight = "weight",
  seat = "seat",
  phone = "phone",
  email = "email",
  postcode = "postcode",
  bubbly = "bubbly",
  creamTea = "creamTea",
  lunch = "lunch",
  magnum = "magnum",
  chocolate = "chocolate",
  photo = "photo",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableFlightPassengerConnection = {
  __typename: "SearchableFlightPassengerConnection",
  items:  Array<FlightPassenger | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelFollowingLogFilterInput = {
  modelType?: ModelFollowingLogStringInput | null,
  id?: ModelIDInput | null,
  user?: ModelStringInput | null,
  pilotName?: ModelStringInput | null,
  type?: ModelEventTypeInput | null,
  notes?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  originId?: ModelIDInput | null,
  originType?: ModelFollowingLogOriginInput | null,
  aircraft?: ModelStringInput | null,
  aircraftType?: ModelStringInput | null,
  pob?: ModelIntInput | null,
  action?: ModelFollowingActionInput | null,
  origin?: ModelStringInput | null,
  destination?: ModelStringInput | null,
  d_lat?: ModelStringInput | null,
  d_lng?: ModelStringInput | null,
  accuracy?: ModelFloatInput | null,
  altitude?: ModelFloatInput | null,
  altitudeAccuracy?: ModelFloatInput | null,
  lat?: ModelStringInput | null,
  lng?: ModelStringInput | null,
  eta?: ModelFloatInput | null,
  ete?: ModelFloatInput | null,
  gs?: ModelFloatInput | null,
  hdg?: ModelFloatInput | null,
  battery?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFollowingLogFilterInput | null > | null,
  or?: Array< ModelFollowingLogFilterInput | null > | null,
  not?: ModelFollowingLogFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFollowingLogConnection = {
  __typename: "ModelFollowingLogConnection",
  items:  Array<FollowingLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableFollowingLogFilterInput = {
  id?: SearchableIDFilterInput | null,
  user?: SearchableStringFilterInput | null,
  pilotName?: SearchableStringFilterInput | null,
  notes?: SearchableStringFilterInput | null,
  eventId?: SearchableIDFilterInput | null,
  originId?: SearchableIDFilterInput | null,
  aircraft?: SearchableStringFilterInput | null,
  aircraftType?: SearchableStringFilterInput | null,
  pob?: SearchableIntFilterInput | null,
  origin?: SearchableStringFilterInput | null,
  destination?: SearchableStringFilterInput | null,
  d_lat?: SearchableStringFilterInput | null,
  d_lng?: SearchableStringFilterInput | null,
  accuracy?: SearchableFloatFilterInput | null,
  altitude?: SearchableFloatFilterInput | null,
  altitudeAccuracy?: SearchableFloatFilterInput | null,
  lat?: SearchableStringFilterInput | null,
  lng?: SearchableStringFilterInput | null,
  eta?: SearchableFloatFilterInput | null,
  ete?: SearchableFloatFilterInput | null,
  gs?: SearchableFloatFilterInput | null,
  hdg?: SearchableFloatFilterInput | null,
  battery?: SearchableFloatFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  modelType?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  originType?: SearchableStringFilterInput | null,
  action?: SearchableStringFilterInput | null,
  and?: Array< SearchableFollowingLogFilterInput | null > | null,
  or?: Array< SearchableFollowingLogFilterInput | null > | null,
  not?: SearchableFollowingLogFilterInput | null,
};

export type SearchableFollowingLogSortInput = {
  field?: SearchableFollowingLogSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFollowingLogSortableFields {
  id = "id",
  user = "user",
  pilotName = "pilotName",
  notes = "notes",
  eventId = "eventId",
  originId = "originId",
  aircraft = "aircraft",
  aircraftType = "aircraftType",
  pob = "pob",
  origin = "origin",
  destination = "destination",
  d_lat = "d_lat",
  d_lng = "d_lng",
  accuracy = "accuracy",
  altitude = "altitude",
  altitudeAccuracy = "altitudeAccuracy",
  lat = "lat",
  lng = "lng",
  eta = "eta",
  ete = "ete",
  gs = "gs",
  hdg = "hdg",
  battery = "battery",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableFollowingLogAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableFollowingLogAggregateField,
};

export enum SearchableFollowingLogAggregateField {
  modelType = "modelType",
  id = "id",
  user = "user",
  pilotName = "pilotName",
  type = "type",
  notes = "notes",
  eventId = "eventId",
  originId = "originId",
  originType = "originType",
  aircraft = "aircraft",
  aircraftType = "aircraftType",
  pob = "pob",
  action = "action",
  origin = "origin",
  destination = "destination",
  d_lat = "d_lat",
  d_lng = "d_lng",
  accuracy = "accuracy",
  altitude = "altitude",
  altitudeAccuracy = "altitudeAccuracy",
  lat = "lat",
  lng = "lng",
  eta = "eta",
  ete = "ete",
  gs = "gs",
  hdg = "hdg",
  battery = "battery",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableFollowingLogConnection = {
  __typename: "SearchableFollowingLogConnection",
  items:  Array<FollowingLog | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelAzureFollowingLogFilterInput = {
  modelType?: ModelFollowingLogStringInput | null,
  id?: ModelIDInput | null,
  user?: ModelStringInput | null,
  pilotName?: ModelStringInput | null,
  type?: ModelEventTypeInput | null,
  notes?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  originId?: ModelIDInput | null,
  originType?: ModelFollowingLogOriginInput | null,
  aircraft?: ModelStringInput | null,
  aircraftType?: ModelStringInput | null,
  pob?: ModelIntInput | null,
  action?: ModelFollowingActionInput | null,
  origin?: ModelStringInput | null,
  destination?: ModelStringInput | null,
  d_lat?: ModelStringInput | null,
  d_lng?: ModelStringInput | null,
  accuracy?: ModelFloatInput | null,
  altitude?: ModelFloatInput | null,
  altitudeAccuracy?: ModelFloatInput | null,
  lat?: ModelStringInput | null,
  lng?: ModelStringInput | null,
  eta?: ModelFloatInput | null,
  ete?: ModelFloatInput | null,
  gs?: ModelFloatInput | null,
  hdg?: ModelFloatInput | null,
  battery?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAzureFollowingLogFilterInput | null > | null,
  or?: Array< ModelAzureFollowingLogFilterInput | null > | null,
  not?: ModelAzureFollowingLogFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAzureFollowingLogConnection = {
  __typename: "ModelAzureFollowingLogConnection",
  items:  Array<AzureFollowingLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableAzureFollowingLogFilterInput = {
  id?: SearchableIDFilterInput | null,
  user?: SearchableStringFilterInput | null,
  pilotName?: SearchableStringFilterInput | null,
  notes?: SearchableStringFilterInput | null,
  eventId?: SearchableIDFilterInput | null,
  originId?: SearchableIDFilterInput | null,
  aircraft?: SearchableStringFilterInput | null,
  aircraftType?: SearchableStringFilterInput | null,
  pob?: SearchableIntFilterInput | null,
  origin?: SearchableStringFilterInput | null,
  destination?: SearchableStringFilterInput | null,
  d_lat?: SearchableStringFilterInput | null,
  d_lng?: SearchableStringFilterInput | null,
  accuracy?: SearchableFloatFilterInput | null,
  altitude?: SearchableFloatFilterInput | null,
  altitudeAccuracy?: SearchableFloatFilterInput | null,
  lat?: SearchableStringFilterInput | null,
  lng?: SearchableStringFilterInput | null,
  eta?: SearchableFloatFilterInput | null,
  ete?: SearchableFloatFilterInput | null,
  gs?: SearchableFloatFilterInput | null,
  hdg?: SearchableFloatFilterInput | null,
  battery?: SearchableFloatFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  modelType?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  originType?: SearchableStringFilterInput | null,
  action?: SearchableStringFilterInput | null,
  and?: Array< SearchableAzureFollowingLogFilterInput | null > | null,
  or?: Array< SearchableAzureFollowingLogFilterInput | null > | null,
  not?: SearchableAzureFollowingLogFilterInput | null,
};

export type SearchableAzureFollowingLogSortInput = {
  field?: SearchableAzureFollowingLogSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableAzureFollowingLogSortableFields {
  id = "id",
  user = "user",
  pilotName = "pilotName",
  notes = "notes",
  eventId = "eventId",
  originId = "originId",
  aircraft = "aircraft",
  aircraftType = "aircraftType",
  pob = "pob",
  origin = "origin",
  destination = "destination",
  d_lat = "d_lat",
  d_lng = "d_lng",
  accuracy = "accuracy",
  altitude = "altitude",
  altitudeAccuracy = "altitudeAccuracy",
  lat = "lat",
  lng = "lng",
  eta = "eta",
  ete = "ete",
  gs = "gs",
  hdg = "hdg",
  battery = "battery",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableAzureFollowingLogAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableAzureFollowingLogAggregateField,
};

export enum SearchableAzureFollowingLogAggregateField {
  modelType = "modelType",
  id = "id",
  user = "user",
  pilotName = "pilotName",
  type = "type",
  notes = "notes",
  eventId = "eventId",
  originId = "originId",
  originType = "originType",
  aircraft = "aircraft",
  aircraftType = "aircraftType",
  pob = "pob",
  action = "action",
  origin = "origin",
  destination = "destination",
  d_lat = "d_lat",
  d_lng = "d_lng",
  accuracy = "accuracy",
  altitude = "altitude",
  altitudeAccuracy = "altitudeAccuracy",
  lat = "lat",
  lng = "lng",
  eta = "eta",
  ete = "ete",
  gs = "gs",
  hdg = "hdg",
  battery = "battery",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableAzureFollowingLogConnection = {
  __typename: "SearchableAzureFollowingLogConnection",
  items:  Array<AzureFollowingLog | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelFlightLegFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  order?: ModelIntInput | null,
  venueId?: ModelIntInput | null,
  venueName?: ModelStringInput | null,
  venuePostcode?: ModelStringInput | null,
  empty?: ModelBooleanInput | null,
  departing?: ModelStringInput | null,
  fromVenueId?: ModelIntInput | null,
  fromVenueName?: ModelStringInput | null,
  fromVenuePostcode?: ModelStringInput | null,
  arriving?: ModelStringInput | null,
  toVenueId?: ModelIntInput | null,
  toVenueName?: ModelStringInput | null,
  toVenuePostcode?: ModelStringInput | null,
  distance?: ModelFloatInput | null,
  ete?: ModelFloatInput | null,
  lat?: ModelFloatInput | null,
  lng?: ModelFloatInput | null,
  w3w?: ModelStringInput | null,
  status?: ModelEventStatusInput | null,
  and?: Array< ModelFlightLegFilterInput | null > | null,
  or?: Array< ModelFlightLegFilterInput | null > | null,
  not?: ModelFlightLegFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type SearchableFlightLegFilterInput = {
  id?: SearchableIDFilterInput | null,
  eventId?: SearchableIDFilterInput | null,
  order?: SearchableIntFilterInput | null,
  venueId?: SearchableIntFilterInput | null,
  venueName?: SearchableStringFilterInput | null,
  venuePostcode?: SearchableStringFilterInput | null,
  empty?: SearchableBooleanFilterInput | null,
  departing?: SearchableStringFilterInput | null,
  fromVenueId?: SearchableIntFilterInput | null,
  fromVenueName?: SearchableStringFilterInput | null,
  fromVenuePostcode?: SearchableStringFilterInput | null,
  arriving?: SearchableStringFilterInput | null,
  toVenueId?: SearchableIntFilterInput | null,
  toVenueName?: SearchableStringFilterInput | null,
  toVenuePostcode?: SearchableStringFilterInput | null,
  distance?: SearchableFloatFilterInput | null,
  ete?: SearchableFloatFilterInput | null,
  lat?: SearchableFloatFilterInput | null,
  lng?: SearchableFloatFilterInput | null,
  w3w?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  status?: SearchableStringFilterInput | null,
  and?: Array< SearchableFlightLegFilterInput | null > | null,
  or?: Array< SearchableFlightLegFilterInput | null > | null,
  not?: SearchableFlightLegFilterInput | null,
};

export type SearchableFlightLegSortInput = {
  field?: SearchableFlightLegSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFlightLegSortableFields {
  id = "id",
  eventId = "eventId",
  order = "order",
  venueId = "venueId",
  venueName = "venueName",
  venuePostcode = "venuePostcode",
  empty = "empty",
  departing = "departing",
  fromVenueId = "fromVenueId",
  fromVenueName = "fromVenueName",
  fromVenuePostcode = "fromVenuePostcode",
  arriving = "arriving",
  toVenueId = "toVenueId",
  toVenueName = "toVenueName",
  toVenuePostcode = "toVenuePostcode",
  distance = "distance",
  ete = "ete",
  lat = "lat",
  lng = "lng",
  w3w = "w3w",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableFlightLegAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableFlightLegAggregateField,
};

export enum SearchableFlightLegAggregateField {
  id = "id",
  eventId = "eventId",
  order = "order",
  venueId = "venueId",
  venueName = "venueName",
  venuePostcode = "venuePostcode",
  empty = "empty",
  departing = "departing",
  fromVenueId = "fromVenueId",
  fromVenueName = "fromVenueName",
  fromVenuePostcode = "fromVenuePostcode",
  arriving = "arriving",
  toVenueId = "toVenueId",
  toVenueName = "toVenueName",
  toVenuePostcode = "toVenuePostcode",
  distance = "distance",
  ete = "ete",
  lat = "lat",
  lng = "lng",
  w3w = "w3w",
  status = "status",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableFlightLegConnection = {
  __typename: "SearchableFlightLegConnection",
  items:  Array<FlightLeg | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPushSubscriptionPrimaryCompositeKeyConditionInput = {
  eq?: ModelPushSubscriptionPrimaryCompositeKeyInput | null,
  le?: ModelPushSubscriptionPrimaryCompositeKeyInput | null,
  lt?: ModelPushSubscriptionPrimaryCompositeKeyInput | null,
  ge?: ModelPushSubscriptionPrimaryCompositeKeyInput | null,
  gt?: ModelPushSubscriptionPrimaryCompositeKeyInput | null,
  between?: Array< ModelPushSubscriptionPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelPushSubscriptionPrimaryCompositeKeyInput | null,
};

export type ModelPushSubscriptionPrimaryCompositeKeyInput = {
  channel?: PushChannel | null,
  deviceToken?: string | null,
  expoPushToken?: string | null,
};

export type ModelPushSubscriptionFilterInput = {
  user?: ModelStringInput | null,
  channel?: ModelPushChannelInput | null,
  deviceToken?: ModelStringInput | null,
  deviceType?: ModelStringInput | null,
  expoPushToken?: ModelStringInput | null,
  and?: Array< ModelPushSubscriptionFilterInput | null > | null,
  or?: Array< ModelPushSubscriptionFilterInput | null > | null,
  not?: ModelPushSubscriptionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPushChannelInput = {
  eq?: PushChannel | null,
  ne?: PushChannel | null,
};

export type ModelPushSubscriptionConnection = {
  __typename: "ModelPushSubscriptionConnection",
  items:  Array<PushSubscription | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserSettingFilterInput = {
  teamLeaderRegionId?: ModelIntInput | null,
  teamLeaderRegionIds?: ModelIntInput | null,
  teamResourceId?: ModelIntInput | null,
  owner?: ModelStringInput | null,
  profileImage?: ModelStringInput | null,
  callsign?: ModelStringInput | null,
  calCode?: ModelStringInput | null,
  appVersion?: ModelStringInput | null,
  weightDisplay?: ModelWeightDisplayInput | null,
  accessibilityIcons?: ModelBooleanInput | null,
  and?: Array< ModelUserSettingFilterInput | null > | null,
  or?: Array< ModelUserSettingFilterInput | null > | null,
  not?: ModelUserSettingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserSettingConnection = {
  __typename: "ModelUserSettingConnection",
  items:  Array<UserSetting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCertificatePrimaryCompositeKeyConditionInput = {
  eq?: ModelCertificatePrimaryCompositeKeyInput | null,
  le?: ModelCertificatePrimaryCompositeKeyInput | null,
  lt?: ModelCertificatePrimaryCompositeKeyInput | null,
  ge?: ModelCertificatePrimaryCompositeKeyInput | null,
  gt?: ModelCertificatePrimaryCompositeKeyInput | null,
  between?: Array< ModelCertificatePrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelCertificatePrimaryCompositeKeyInput | null,
};

export type ModelCertificatePrimaryCompositeKeyInput = {
  model?: CertificateModel | null,
  type?: CertificateType | null,
};

export type ModelCertificateFilterInput = {
  owner?: ModelStringInput | null,
  model?: ModelCertificateModelInput | null,
  type?: ModelCertificateTypeInput | null,
  number?: ModelStringInput | null,
  aircraft?: ModelStringInput | null,
  pdf?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  and?: Array< ModelCertificateFilterInput | null > | null,
  or?: Array< ModelCertificateFilterInput | null > | null,
  not?: ModelCertificateFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCertificateConnection = {
  __typename: "ModelCertificateConnection",
  items:  Array<Certificate | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserCertificateFilterInput = {
  key?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  model?: ModelCertificateModelInput | null,
  type?: ModelCertificateTypeInput | null,
  number?: ModelStringInput | null,
  aircraft?: ModelStringInput | null,
  pdf?: ModelStringInput | null,
  expiresAt?: ModelStringInput | null,
  and?: Array< ModelUserCertificateFilterInput | null > | null,
  or?: Array< ModelUserCertificateFilterInput | null > | null,
  not?: ModelUserCertificateFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserCertificateConnection = {
  __typename: "ModelUserCertificateConnection",
  items:  Array<UserCertificate | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserCertificateByOwnerCompositeKeyConditionInput = {
  eq?: ModelUserCertificateByOwnerCompositeKeyInput | null,
  le?: ModelUserCertificateByOwnerCompositeKeyInput | null,
  lt?: ModelUserCertificateByOwnerCompositeKeyInput | null,
  ge?: ModelUserCertificateByOwnerCompositeKeyInput | null,
  gt?: ModelUserCertificateByOwnerCompositeKeyInput | null,
  between?: Array< ModelUserCertificateByOwnerCompositeKeyInput | null > | null,
  beginsWith?: ModelUserCertificateByOwnerCompositeKeyInput | null,
};

export type ModelUserCertificateByOwnerCompositeKeyInput = {
  model?: CertificateModel | null,
  type?: CertificateType | null,
  aircraft?: string | null,
};

export type ModelBroadcastMessageFilterInput = {
  id?: ModelIDInput | null,
  modelType?: ModelBroadcastMessageStringInput | null,
  message?: ModelStringInput | null,
  title?: ModelStringInput | null,
  originType?: ModelBroadcastMessageOriginInput | null,
  originId?: ModelIDInput | null,
  sentBy?: ModelStringInput | null,
  url?: ModelStringInput | null,
  extra?: ModelStringInput | null,
  targetedGroup?: ModelUserGroupsInput | null,
  targetedRole?: ModelTeamMemberRoleInput | null,
  targetedEventId?: ModelIDInput | null,
  targetedUsers?: ModelStringInput | null,
  users?: ModelStringInput | null,
  broadcastAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBroadcastMessageFilterInput | null > | null,
  or?: Array< ModelBroadcastMessageFilterInput | null > | null,
  not?: ModelBroadcastMessageFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBroadcastMessageConnection = {
  __typename: "ModelBroadcastMessageConnection",
  items:  Array<BroadcastMessage | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelBroadcastMessageByOriginCompositeKeyConditionInput = {
  eq?: ModelBroadcastMessageByOriginCompositeKeyInput | null,
  le?: ModelBroadcastMessageByOriginCompositeKeyInput | null,
  lt?: ModelBroadcastMessageByOriginCompositeKeyInput | null,
  ge?: ModelBroadcastMessageByOriginCompositeKeyInput | null,
  gt?: ModelBroadcastMessageByOriginCompositeKeyInput | null,
  between?: Array< ModelBroadcastMessageByOriginCompositeKeyInput | null > | null,
  beginsWith?: ModelBroadcastMessageByOriginCompositeKeyInput | null,
};

export type ModelBroadcastMessageByOriginCompositeKeyInput = {
  originType?: BroadcastMessageOrigin | null,
  createdAt?: string | null,
};

export type SearchableBroadcastMessageFilterInput = {
  id?: SearchableIDFilterInput | null,
  message?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  originId?: SearchableIDFilterInput | null,
  sentBy?: SearchableStringFilterInput | null,
  url?: SearchableStringFilterInput | null,
  extra?: SearchableStringFilterInput | null,
  targetedEventId?: SearchableIDFilterInput | null,
  targetedUsers?: SearchableStringFilterInput | null,
  users?: SearchableStringFilterInput | null,
  broadcastAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  modelType?: SearchableStringFilterInput | null,
  originType?: SearchableStringFilterInput | null,
  targetedGroup?: SearchableStringFilterInput | null,
  targetedRole?: SearchableStringFilterInput | null,
  and?: Array< SearchableBroadcastMessageFilterInput | null > | null,
  or?: Array< SearchableBroadcastMessageFilterInput | null > | null,
  not?: SearchableBroadcastMessageFilterInput | null,
};

export type SearchableBroadcastMessageSortInput = {
  field?: SearchableBroadcastMessageSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBroadcastMessageSortableFields {
  id = "id",
  message = "message",
  title = "title",
  originId = "originId",
  sentBy = "sentBy",
  url = "url",
  extra = "extra",
  targetedEventId = "targetedEventId",
  targetedUsers = "targetedUsers",
  users = "users",
  broadcastAt = "broadcastAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableBroadcastMessageAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableBroadcastMessageAggregateField,
};

export enum SearchableBroadcastMessageAggregateField {
  id = "id",
  modelType = "modelType",
  message = "message",
  title = "title",
  originType = "originType",
  originId = "originId",
  sentBy = "sentBy",
  url = "url",
  extra = "extra",
  targetedGroup = "targetedGroup",
  targetedRole = "targetedRole",
  targetedEventId = "targetedEventId",
  targetedUsers = "targetedUsers",
  users = "users",
  broadcastAt = "broadcastAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableBroadcastMessageConnection = {
  __typename: "SearchableBroadcastMessageConnection",
  items:  Array<BroadcastMessage | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelBroadcastReceiptFilterInput = {
  id?: ModelIDInput | null,
  broadcastMessageId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  pushToken?: ModelStringInput | null,
  deviceToken?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  status?: ModelBroadcastReceiptStatusInput | null,
  message?: ModelStringInput | null,
  details?: ModelStringInput | null,
  deliveredAt?: ModelStringInput | null,
  errors?: ModelStringInput | null,
  and?: Array< ModelBroadcastReceiptFilterInput | null > | null,
  or?: Array< ModelBroadcastReceiptFilterInput | null > | null,
  not?: ModelBroadcastReceiptFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBroadcastReceiptConnection = {
  __typename: "ModelBroadcastReceiptConnection",
  items:  Array<BroadcastReceipt | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelExperimentFilterInput = {
  owner?: ModelStringInput | null,
  experiment?: ModelExperimentsInput | null,
  enabled?: ModelBooleanInput | null,
  extra?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelExperimentFilterInput | null > | null,
  or?: Array< ModelExperimentFilterInput | null > | null,
  not?: ModelExperimentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelExperimentsInput = {
  eq?: Experiments | null,
  ne?: Experiments | null,
};

export type ModelExperimentConnection = {
  __typename: "ModelExperimentConnection",
  items:  Array<Experiment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPhotoAssetFilterInput = {
  id?: ModelIDInput | null,
  enquiryId?: ModelStringInput | null,
  passengers?: ModelStringInput | null,
  email?: ModelStringInput | null,
  flightId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  soldBy?: ModelStringInput | null,
  emailSent?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPhotoAssetFilterInput | null > | null,
  or?: Array< ModelPhotoAssetFilterInput | null > | null,
  not?: ModelPhotoAssetFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPhotoAssetConnection = {
  __typename: "ModelPhotoAssetConnection",
  items:  Array<PhotoAsset | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchablePhotoAssetFilterInput = {
  id?: SearchableIDFilterInput | null,
  enquiryId?: SearchableStringFilterInput | null,
  passengers?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  flightId?: SearchableIDFilterInput | null,
  eventId?: SearchableIDFilterInput | null,
  soldBy?: SearchableStringFilterInput | null,
  emailSent?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchablePhotoAssetFilterInput | null > | null,
  or?: Array< SearchablePhotoAssetFilterInput | null > | null,
  not?: SearchablePhotoAssetFilterInput | null,
};

export type SearchablePhotoAssetSortInput = {
  field?: SearchablePhotoAssetSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePhotoAssetSortableFields {
  id = "id",
  enquiryId = "enquiryId",
  passengers = "passengers",
  email = "email",
  flightId = "flightId",
  eventId = "eventId",
  soldBy = "soldBy",
  emailSent = "emailSent",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchablePhotoAssetAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePhotoAssetAggregateField,
};

export enum SearchablePhotoAssetAggregateField {
  id = "id",
  enquiryId = "enquiryId",
  passengers = "passengers",
  email = "email",
  flightId = "flightId",
  eventId = "eventId",
  soldBy = "soldBy",
  emailSent = "emailSent",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchablePhotoAssetConnection = {
  __typename: "SearchablePhotoAssetConnection",
  items:  Array<PhotoAsset | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelSafetyCommFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  modelType?: ModelSafetyCommStringInput | null,
  url?: ModelStringInput | null,
  publishedBy?: ModelStringInput | null,
  publishedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSafetyCommFilterInput | null > | null,
  or?: Array< ModelSafetyCommFilterInput | null > | null,
  not?: ModelSafetyCommFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSafetyCommConnection = {
  __typename: "ModelSafetyCommConnection",
  items:  Array<SafetyComm | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionEventFilterInput = {
  modelType?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  oid?: ModelSubscriptionStringInput | null,
  venue?: ModelSubscriptionStringInput | null,
  venueId?: ModelSubscriptionIntInput | null,
  regionId?: ModelSubscriptionIntInput | null,
  date?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  pax?: ModelSubscriptionIntInput | null,
  product?: ModelSubscriptionStringInput | null,
  ref?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  pilotName?: ModelSubscriptionStringInput | null,
  pilotConfirmed?: ModelSubscriptionBooleanInput | null,
  aircraftId?: ModelSubscriptionIntInput | null,
  aircraftType?: ModelSubscriptionStringInput | null,
  aircraftName?: ModelSubscriptionStringInput | null,
  aircraftSeats?: ModelSubscriptionIntInput | null,
  aircraft?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionFloatInput | null,
  client?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionEventFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFlightFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  tupleId?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  flightOrderId?: ModelSubscriptionIntInput | null,
  designator?: ModelSubscriptionStringInput | null,
  sequence?: ModelSubscriptionIntInput | null,
  flightNumber?: ModelSubscriptionStringInput | null,
  theme?: ModelSubscriptionStringInput | null,
  aircraftName?: ModelSubscriptionStringInput | null,
  isFlightFull?: ModelSubscriptionBooleanInput | null,
  scheduledDeparture?: ModelSubscriptionStringInput | null,
  actualDeparture?: ModelSubscriptionStringInput | null,
  estimatedArrival?: ModelSubscriptionStringInput | null,
  scheduledCheckIn?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionFloatInput | null,
  users?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  lastUpdatedBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFlightFilterInput | null > | null,
  or?: Array< ModelSubscriptionFlightFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRefuelRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  requestedBy?: ModelSubscriptionStringInput | null,
  requestFrom?: ModelSubscriptionStringInput | null,
  delay?: ModelSubscriptionIntInput | null,
  users?: ModelSubscriptionStringInput | null,
  flightOrderId?: ModelSubscriptionIntInput | null,
  adventureId?: ModelSubscriptionIntInput | null,
  aircraftId?: ModelSubscriptionIntInput | null,
  aircraftName?: ModelSubscriptionStringInput | null,
  litres?: ModelSubscriptionIntInput | null,
  fuelType?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRefuelRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionRefuelRequestFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRefuelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  refuelRequestId?: ModelSubscriptionIDInput | null,
  aircraftId?: ModelSubscriptionIntInput | null,
  aircraftName?: ModelSubscriptionStringInput | null,
  litres?: ModelSubscriptionIntInput | null,
  fuelType?: ModelSubscriptionStringInput | null,
  users?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  refueledAt?: ModelSubscriptionStringInput | null,
  refueledBy?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRefuelFilterInput | null > | null,
  or?: Array< ModelSubscriptionRefuelFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFlightPassengerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  tupleId?: ModelSubscriptionIDInput | null,
  enquiryId?: ModelSubscriptionIntInput | null,
  voucherId?: ModelSubscriptionStringInput | null,
  designator?: ModelSubscriptionStringInput | null,
  sequence?: ModelSubscriptionIntInput | null,
  flightNumber?: ModelSubscriptionStringInput | null,
  flightId?: ModelSubscriptionIDInput | null,
  number?: ModelSubscriptionIntInput | null,
  passengerNumber?: ModelSubscriptionIntInput | null,
  forename?: ModelSubscriptionStringInput | null,
  surname?: ModelSubscriptionStringInput | null,
  weight?: ModelSubscriptionFloatInput | null,
  seat?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  postcode?: ModelSubscriptionStringInput | null,
  bubbly?: ModelSubscriptionBooleanInput | null,
  creamTea?: ModelSubscriptionBooleanInput | null,
  lunch?: ModelSubscriptionBooleanInput | null,
  magnum?: ModelSubscriptionBooleanInput | null,
  chocolate?: ModelSubscriptionBooleanInput | null,
  photo?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionFlightPassengerFilterInput | null > | null,
  or?: Array< ModelSubscriptionFlightPassengerFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPleasureOverviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  paxBooked?: ModelSubscriptionIntInput | null,
  aircraftReg?: ModelSubscriptionStringInput | null,
  aircraftSeats?: ModelSubscriptionIntInput | null,
  fireName?: ModelSubscriptionStringInput | null,
  fireReg?: ModelSubscriptionStringInput | null,
  bowserName?: ModelSubscriptionStringInput | null,
  truckName?: ModelSubscriptionStringInput | null,
  truckReg?: ModelSubscriptionStringInput | null,
  counts?: ModelSubscriptionStringInput | null,
  teamLeaderId?: ModelSubscriptionIDInput | null,
  disabledRoles?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPleasureOverviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionPleasureOverviewFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTeamMemberFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  overviewId?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  forename?: ModelSubscriptionStringInput | null,
  surname?: ModelSubscriptionStringInput | null,
  teamResourceId?: ModelSubscriptionIntInput | null,
  teamRole?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  confirmed?: ModelSubscriptionBooleanInput | null,
  owner?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamMemberFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamMemberFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionManifestItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  overviewId?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  time?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionFloatInput | null,
  designator?: ModelSubscriptionStringInput | null,
  pax?: ModelSubscriptionIntInput | null,
  weight?: ModelSubscriptionIntInput | null,
  voucherIds?: ModelSubscriptionStringInput | null,
  frontSeatTaken?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionManifestItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionManifestItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFollowingLogFilterInput = {
  modelType?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  user?: ModelSubscriptionStringInput | null,
  pilotName?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  originId?: ModelSubscriptionIDInput | null,
  originType?: ModelSubscriptionStringInput | null,
  aircraft?: ModelSubscriptionStringInput | null,
  aircraftType?: ModelSubscriptionStringInput | null,
  pob?: ModelSubscriptionIntInput | null,
  action?: ModelSubscriptionStringInput | null,
  origin?: ModelSubscriptionStringInput | null,
  destination?: ModelSubscriptionStringInput | null,
  d_lat?: ModelSubscriptionStringInput | null,
  d_lng?: ModelSubscriptionStringInput | null,
  accuracy?: ModelSubscriptionFloatInput | null,
  altitude?: ModelSubscriptionFloatInput | null,
  altitudeAccuracy?: ModelSubscriptionFloatInput | null,
  lat?: ModelSubscriptionStringInput | null,
  lng?: ModelSubscriptionStringInput | null,
  eta?: ModelSubscriptionFloatInput | null,
  ete?: ModelSubscriptionFloatInput | null,
  gs?: ModelSubscriptionFloatInput | null,
  hdg?: ModelSubscriptionFloatInput | null,
  battery?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFollowingLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionFollowingLogFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAzureFollowingLogFilterInput = {
  modelType?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  user?: ModelSubscriptionStringInput | null,
  pilotName?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  originId?: ModelSubscriptionIDInput | null,
  originType?: ModelSubscriptionStringInput | null,
  aircraft?: ModelSubscriptionStringInput | null,
  aircraftType?: ModelSubscriptionStringInput | null,
  pob?: ModelSubscriptionIntInput | null,
  action?: ModelSubscriptionStringInput | null,
  origin?: ModelSubscriptionStringInput | null,
  destination?: ModelSubscriptionStringInput | null,
  d_lat?: ModelSubscriptionStringInput | null,
  d_lng?: ModelSubscriptionStringInput | null,
  accuracy?: ModelSubscriptionFloatInput | null,
  altitude?: ModelSubscriptionFloatInput | null,
  altitudeAccuracy?: ModelSubscriptionFloatInput | null,
  lat?: ModelSubscriptionStringInput | null,
  lng?: ModelSubscriptionStringInput | null,
  eta?: ModelSubscriptionFloatInput | null,
  ete?: ModelSubscriptionFloatInput | null,
  gs?: ModelSubscriptionFloatInput | null,
  hdg?: ModelSubscriptionFloatInput | null,
  battery?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAzureFollowingLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionAzureFollowingLogFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFlightLegFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  order?: ModelSubscriptionIntInput | null,
  venueId?: ModelSubscriptionIntInput | null,
  venueName?: ModelSubscriptionStringInput | null,
  venuePostcode?: ModelSubscriptionStringInput | null,
  empty?: ModelSubscriptionBooleanInput | null,
  departing?: ModelSubscriptionStringInput | null,
  fromVenueId?: ModelSubscriptionIntInput | null,
  fromVenueName?: ModelSubscriptionStringInput | null,
  fromVenuePostcode?: ModelSubscriptionStringInput | null,
  arriving?: ModelSubscriptionStringInput | null,
  toVenueId?: ModelSubscriptionIntInput | null,
  toVenueName?: ModelSubscriptionStringInput | null,
  toVenuePostcode?: ModelSubscriptionStringInput | null,
  distance?: ModelSubscriptionFloatInput | null,
  ete?: ModelSubscriptionFloatInput | null,
  lat?: ModelSubscriptionFloatInput | null,
  lng?: ModelSubscriptionFloatInput | null,
  w3w?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFlightLegFilterInput | null > | null,
  or?: Array< ModelSubscriptionFlightLegFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPushSubscriptionFilterInput = {
  user?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  deviceToken?: ModelSubscriptionStringInput | null,
  deviceType?: ModelSubscriptionStringInput | null,
  expoPushToken?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPushSubscriptionFilterInput | null > | null,
  or?: Array< ModelSubscriptionPushSubscriptionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserSettingFilterInput = {
  teamLeaderRegionId?: ModelSubscriptionIntInput | null,
  teamLeaderRegionIds?: ModelSubscriptionIntInput | null,
  teamResourceId?: ModelSubscriptionIntInput | null,
  profileImage?: ModelSubscriptionStringInput | null,
  callsign?: ModelSubscriptionStringInput | null,
  calCode?: ModelSubscriptionStringInput | null,
  appVersion?: ModelSubscriptionStringInput | null,
  weightDisplay?: ModelSubscriptionStringInput | null,
  accessibilityIcons?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionUserSettingFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserSettingFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCertificateFilterInput = {
  model?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  number?: ModelSubscriptionStringInput | null,
  aircraft?: ModelSubscriptionStringInput | null,
  pdf?: ModelSubscriptionStringInput | null,
  expiresAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCertificateFilterInput | null > | null,
  or?: Array< ModelSubscriptionCertificateFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserCertificateFilterInput = {
  key?: ModelSubscriptionStringInput | null,
  model?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  number?: ModelSubscriptionStringInput | null,
  aircraft?: ModelSubscriptionStringInput | null,
  pdf?: ModelSubscriptionStringInput | null,
  expiresAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserCertificateFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserCertificateFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBroadcastMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  modelType?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  originType?: ModelSubscriptionStringInput | null,
  originId?: ModelSubscriptionIDInput | null,
  sentBy?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  extra?: ModelSubscriptionStringInput | null,
  targetedGroup?: ModelSubscriptionStringInput | null,
  targetedRole?: ModelSubscriptionStringInput | null,
  targetedEventId?: ModelSubscriptionIDInput | null,
  targetedUsers?: ModelSubscriptionStringInput | null,
  users?: ModelSubscriptionStringInput | null,
  broadcastAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBroadcastMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionBroadcastMessageFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBroadcastReceiptFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  broadcastMessageId?: ModelSubscriptionIDInput | null,
  owner?: ModelSubscriptionStringInput | null,
  pushToken?: ModelSubscriptionStringInput | null,
  deviceToken?: ModelSubscriptionStringInput | null,
  sentAt?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  details?: ModelSubscriptionStringInput | null,
  deliveredAt?: ModelSubscriptionStringInput | null,
  errors?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBroadcastReceiptFilterInput | null > | null,
  or?: Array< ModelSubscriptionBroadcastReceiptFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionExperimentFilterInput = {
  experiment?: ModelSubscriptionStringInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  extra?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionExperimentFilterInput | null > | null,
  or?: Array< ModelSubscriptionExperimentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPhotoAssetFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  enquiryId?: ModelSubscriptionStringInput | null,
  passengers?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  flightId?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  soldBy?: ModelSubscriptionStringInput | null,
  emailSent?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPhotoAssetFilterInput | null > | null,
  or?: Array< ModelSubscriptionPhotoAssetFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSafetyCommFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  modelType?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  publishedBy?: ModelSubscriptionStringInput | null,
  publishedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSafetyCommFilterInput | null > | null,
  or?: Array< ModelSubscriptionSafetyCommFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type DeleteBroadcastMessageMutationVariables = {
  input: DeleteBroadcastMessageInput,
  condition?: ModelBroadcastMessageConditionInput | null,
};

export type DeleteBroadcastMessageMutation = {
  deleteBroadcastMessage?:  {
    __typename: "BroadcastMessage",
    id: string,
    modelType?: BroadcastMessageString | null,
    message: string,
    title?: string | null,
    originType?: BroadcastMessageOrigin | null,
    originId?: string | null,
    sentBy?: string | null,
    url?: string | null,
    extra?: string | null,
    targetedGroup?: UserGroups | null,
    targetedRole?: TeamMemberRole | null,
    targetedEventId?: string | null,
    targetedEvent?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    targetedUsers?: Array< string | null > | null,
    users?: Array< string | null > | null,
    broadcastAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type CreatePleasureOverviewMutationVariables = {
  input: CreatePleasureOverviewInput,
  condition?: ModelPleasureOverviewConditionInput | null,
};

export type CreatePleasureOverviewMutation = {
  createPleasureOverview?:  {
    __typename: "PleasureOverview",
    id: string,
    eventId: string,
    team?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    manifestItems?:  {
      __typename: "ModelManifestItemConnection",
      items:  Array< {
        __typename: "ManifestItem",
        id: string,
        overviewId: string,
        eventId?: string | null,
        time?: string | null,
        duration?: number | null,
        designator?: PfFlightDesignator | null,
        pax?: number | null,
        weight?: number | null,
        voucherIds?: Array< string | null > | null,
        frontSeatTaken?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    paxBooked?: number | null,
    aircraftReg?: string | null,
    aircraftSeats?: number | null,
    fireName?: string | null,
    fireReg?: string | null,
    bowserName?: string | null,
    truckName?: string | null,
    truckReg?: string | null,
    counts?: string | null,
    teamLeaderId?: string | null,
    teamLeader?:  {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    disabledRoles?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePleasureOverviewMutationVariables = {
  input: UpdatePleasureOverviewInput,
  condition?: ModelPleasureOverviewConditionInput | null,
};

export type UpdatePleasureOverviewMutation = {
  updatePleasureOverview?:  {
    __typename: "PleasureOverview",
    id: string,
    eventId: string,
    team?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    manifestItems?:  {
      __typename: "ModelManifestItemConnection",
      items:  Array< {
        __typename: "ManifestItem",
        id: string,
        overviewId: string,
        eventId?: string | null,
        time?: string | null,
        duration?: number | null,
        designator?: PfFlightDesignator | null,
        pax?: number | null,
        weight?: number | null,
        voucherIds?: Array< string | null > | null,
        frontSeatTaken?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    paxBooked?: number | null,
    aircraftReg?: string | null,
    aircraftSeats?: number | null,
    fireName?: string | null,
    fireReg?: string | null,
    bowserName?: string | null,
    truckName?: string | null,
    truckReg?: string | null,
    counts?: string | null,
    teamLeaderId?: string | null,
    teamLeader?:  {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    disabledRoles?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePleasureOverviewMutationVariables = {
  input: DeletePleasureOverviewInput,
  condition?: ModelPleasureOverviewConditionInput | null,
};

export type DeletePleasureOverviewMutation = {
  deletePleasureOverview?:  {
    __typename: "PleasureOverview",
    id: string,
    eventId: string,
    team?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    manifestItems?:  {
      __typename: "ModelManifestItemConnection",
      items:  Array< {
        __typename: "ManifestItem",
        id: string,
        overviewId: string,
        eventId?: string | null,
        time?: string | null,
        duration?: number | null,
        designator?: PfFlightDesignator | null,
        pax?: number | null,
        weight?: number | null,
        voucherIds?: Array< string | null > | null,
        frontSeatTaken?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    paxBooked?: number | null,
    aircraftReg?: string | null,
    aircraftSeats?: number | null,
    fireName?: string | null,
    fireReg?: string | null,
    bowserName?: string | null,
    truckName?: string | null,
    truckReg?: string | null,
    counts?: string | null,
    teamLeaderId?: string | null,
    teamLeader?:  {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    disabledRoles?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTeamMemberMutationVariables = {
  input: CreateTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type CreateTeamMemberMutation = {
  createTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    overviewId?: string | null,
    eventId?: string | null,
    name?: string | null,
    forename?: string | null,
    surname?: string | null,
    teamResourceId?: number | null,
    teamRole?: string | null,
    phone?: string | null,
    confirmed?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTeamMemberMutationVariables = {
  input: UpdateTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type UpdateTeamMemberMutation = {
  updateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    overviewId?: string | null,
    eventId?: string | null,
    name?: string | null,
    forename?: string | null,
    surname?: string | null,
    teamResourceId?: number | null,
    teamRole?: string | null,
    phone?: string | null,
    confirmed?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTeamMemberMutationVariables = {
  input: DeleteTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type DeleteTeamMemberMutation = {
  deleteTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    overviewId?: string | null,
    eventId?: string | null,
    name?: string | null,
    forename?: string | null,
    surname?: string | null,
    teamResourceId?: number | null,
    teamRole?: string | null,
    phone?: string | null,
    confirmed?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateManifestItemMutationVariables = {
  input: CreateManifestItemInput,
  condition?: ModelManifestItemConditionInput | null,
};

export type CreateManifestItemMutation = {
  createManifestItem?:  {
    __typename: "ManifestItem",
    id: string,
    overviewId: string,
    eventId?: string | null,
    time?: string | null,
    duration?: number | null,
    designator?: PfFlightDesignator | null,
    pax?: number | null,
    weight?: number | null,
    voucherIds?: Array< string | null > | null,
    frontSeatTaken?: boolean | null,
    passengers?:  Array< {
      __typename: "ManifestPassenger",
      title?: string | null,
      forename?: string | null,
      surname?: string | null,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      weight?: number | null,
      enquiryId?: string | null,
      voucherId?: string | null,
      frontSeat?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateManifestItemMutationVariables = {
  input: UpdateManifestItemInput,
  condition?: ModelManifestItemConditionInput | null,
};

export type UpdateManifestItemMutation = {
  updateManifestItem?:  {
    __typename: "ManifestItem",
    id: string,
    overviewId: string,
    eventId?: string | null,
    time?: string | null,
    duration?: number | null,
    designator?: PfFlightDesignator | null,
    pax?: number | null,
    weight?: number | null,
    voucherIds?: Array< string | null > | null,
    frontSeatTaken?: boolean | null,
    passengers?:  Array< {
      __typename: "ManifestPassenger",
      title?: string | null,
      forename?: string | null,
      surname?: string | null,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      weight?: number | null,
      enquiryId?: string | null,
      voucherId?: string | null,
      frontSeat?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteManifestItemMutationVariables = {
  input: DeleteManifestItemInput,
  condition?: ModelManifestItemConditionInput | null,
};

export type DeleteManifestItemMutation = {
  deleteManifestItem?:  {
    __typename: "ManifestItem",
    id: string,
    overviewId: string,
    eventId?: string | null,
    time?: string | null,
    duration?: number | null,
    designator?: PfFlightDesignator | null,
    pax?: number | null,
    weight?: number | null,
    voucherIds?: Array< string | null > | null,
    frontSeatTaken?: boolean | null,
    passengers?:  Array< {
      __typename: "ManifestPassenger",
      title?: string | null,
      forename?: string | null,
      surname?: string | null,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      weight?: number | null,
      enquiryId?: string | null,
      voucherId?: string | null,
      frontSeat?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFlightMutationVariables = {
  input: CreateFlightInput,
  condition?: ModelFlightConditionInput | null,
};

export type CreateFlightMutation = {
  createFlight?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFlightMutationVariables = {
  input: UpdateFlightInput,
  condition?: ModelFlightConditionInput | null,
};

export type UpdateFlightMutation = {
  updateFlight?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFlightMutationVariables = {
  input: DeleteFlightInput,
  condition?: ModelFlightConditionInput | null,
};

export type DeleteFlightMutation = {
  deleteFlight?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRefuelRequestMutationVariables = {
  input: CreateRefuelRequestInput,
  condition?: ModelRefuelRequestConditionInput | null,
};

export type CreateRefuelRequestMutation = {
  createRefuelRequest?:  {
    __typename: "RefuelRequest",
    id: string,
    eventId: string,
    requestedBy?: string | null,
    requestFrom?: TeamMemberRole | null,
    delay?: number | null,
    users: Array< string >,
    flightOrderId?: number | null,
    adventureId?: number | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRefuelRequestMutationVariables = {
  input: UpdateRefuelRequestInput,
  condition?: ModelRefuelRequestConditionInput | null,
};

export type UpdateRefuelRequestMutation = {
  updateRefuelRequest?:  {
    __typename: "RefuelRequest",
    id: string,
    eventId: string,
    requestedBy?: string | null,
    requestFrom?: TeamMemberRole | null,
    delay?: number | null,
    users: Array< string >,
    flightOrderId?: number | null,
    adventureId?: number | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRefuelRequestMutationVariables = {
  input: DeleteRefuelRequestInput,
  condition?: ModelRefuelRequestConditionInput | null,
};

export type DeleteRefuelRequestMutation = {
  deleteRefuelRequest?:  {
    __typename: "RefuelRequest",
    id: string,
    eventId: string,
    requestedBy?: string | null,
    requestFrom?: TeamMemberRole | null,
    delay?: number | null,
    users: Array< string >,
    flightOrderId?: number | null,
    adventureId?: number | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRefuelMutationVariables = {
  input: CreateRefuelInput,
  condition?: ModelRefuelConditionInput | null,
};

export type CreateRefuelMutation = {
  createRefuel?:  {
    __typename: "Refuel",
    id: string,
    eventId: string,
    refuelRequestId?: string | null,
    refuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    users: Array< string >,
    createdAt: string,
    refueledAt: string,
    refueledBy?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRefuelMutationVariables = {
  input: UpdateRefuelInput,
  condition?: ModelRefuelConditionInput | null,
};

export type UpdateRefuelMutation = {
  updateRefuel?:  {
    __typename: "Refuel",
    id: string,
    eventId: string,
    refuelRequestId?: string | null,
    refuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    users: Array< string >,
    createdAt: string,
    refueledAt: string,
    refueledBy?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRefuelMutationVariables = {
  input: DeleteRefuelInput,
  condition?: ModelRefuelConditionInput | null,
};

export type DeleteRefuelMutation = {
  deleteRefuel?:  {
    __typename: "Refuel",
    id: string,
    eventId: string,
    refuelRequestId?: string | null,
    refuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    users: Array< string >,
    createdAt: string,
    refueledAt: string,
    refueledBy?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFlightPassengerMutationVariables = {
  input: CreateFlightPassengerInput,
  condition?: ModelFlightPassengerConditionInput | null,
};

export type CreateFlightPassengerMutation = {
  createFlightPassenger?:  {
    __typename: "FlightPassenger",
    id: string,
    eventId: string,
    tupleId?: string | null,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    enquiryId?: number | null,
    voucherId?: string | null,
    designator?: PfFlightDesignator | null,
    sequence?: number | null,
    flightNumber?: string | null,
    flightId?: string | null,
    number: number,
    passengerNumber?: number | null,
    forename: string,
    surname: string,
    weight: number,
    seat: PassengerSeat,
    phone?: string | null,
    email?: string | null,
    postcode?: string | null,
    bubbly?: boolean | null,
    creamTea?: boolean | null,
    lunch?: boolean | null,
    magnum?: boolean | null,
    chocolate?: boolean | null,
    photo?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFlightPassengerMutationVariables = {
  input: UpdateFlightPassengerInput,
  condition?: ModelFlightPassengerConditionInput | null,
};

export type UpdateFlightPassengerMutation = {
  updateFlightPassenger?:  {
    __typename: "FlightPassenger",
    id: string,
    eventId: string,
    tupleId?: string | null,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    enquiryId?: number | null,
    voucherId?: string | null,
    designator?: PfFlightDesignator | null,
    sequence?: number | null,
    flightNumber?: string | null,
    flightId?: string | null,
    number: number,
    passengerNumber?: number | null,
    forename: string,
    surname: string,
    weight: number,
    seat: PassengerSeat,
    phone?: string | null,
    email?: string | null,
    postcode?: string | null,
    bubbly?: boolean | null,
    creamTea?: boolean | null,
    lunch?: boolean | null,
    magnum?: boolean | null,
    chocolate?: boolean | null,
    photo?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFlightPassengerMutationVariables = {
  input: DeleteFlightPassengerInput,
  condition?: ModelFlightPassengerConditionInput | null,
};

export type DeleteFlightPassengerMutation = {
  deleteFlightPassenger?:  {
    __typename: "FlightPassenger",
    id: string,
    eventId: string,
    tupleId?: string | null,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    enquiryId?: number | null,
    voucherId?: string | null,
    designator?: PfFlightDesignator | null,
    sequence?: number | null,
    flightNumber?: string | null,
    flightId?: string | null,
    number: number,
    passengerNumber?: number | null,
    forename: string,
    surname: string,
    weight: number,
    seat: PassengerSeat,
    phone?: string | null,
    email?: string | null,
    postcode?: string | null,
    bubbly?: boolean | null,
    creamTea?: boolean | null,
    lunch?: boolean | null,
    magnum?: boolean | null,
    chocolate?: boolean | null,
    photo?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFollowingLogMutationVariables = {
  input: CreateFollowingLogInput,
  condition?: ModelFollowingLogConditionInput | null,
};

export type CreateFollowingLogMutation = {
  createFollowingLog?:  {
    __typename: "FollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFollowingLogMutationVariables = {
  input: UpdateFollowingLogInput,
  condition?: ModelFollowingLogConditionInput | null,
};

export type UpdateFollowingLogMutation = {
  updateFollowingLog?:  {
    __typename: "FollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFollowingLogMutationVariables = {
  input: DeleteFollowingLogInput,
  condition?: ModelFollowingLogConditionInput | null,
};

export type DeleteFollowingLogMutation = {
  deleteFollowingLog?:  {
    __typename: "FollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAzureFollowingLogMutationVariables = {
  input: CreateAzureFollowingLogInput,
  condition?: ModelAzureFollowingLogConditionInput | null,
};

export type CreateAzureFollowingLogMutation = {
  createAzureFollowingLog?:  {
    __typename: "AzureFollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAzureFollowingLogMutationVariables = {
  input: UpdateAzureFollowingLogInput,
  condition?: ModelAzureFollowingLogConditionInput | null,
};

export type UpdateAzureFollowingLogMutation = {
  updateAzureFollowingLog?:  {
    __typename: "AzureFollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAzureFollowingLogMutationVariables = {
  input: DeleteAzureFollowingLogInput,
  condition?: ModelAzureFollowingLogConditionInput | null,
};

export type DeleteAzureFollowingLogMutation = {
  deleteAzureFollowingLog?:  {
    __typename: "AzureFollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFlightLegMutationVariables = {
  input: CreateFlightLegInput,
  condition?: ModelFlightLegConditionInput | null,
};

export type CreateFlightLegMutation = {
  createFlightLeg?:  {
    __typename: "FlightLeg",
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    order: number,
    venueId?: number | null,
    venueName?: string | null,
    venuePostcode?: string | null,
    empty?: boolean | null,
    departing?: string | null,
    fromVenueId?: number | null,
    fromVenueName?: string | null,
    fromVenuePostcode?: string | null,
    fromCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    arriving?: string | null,
    toVenueId?: number | null,
    toVenueName?: string | null,
    toVenuePostcode?: string | null,
    toCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    distance?: number | null,
    ete?: number | null,
    lat?: number | null,
    lng?: number | null,
    w3w?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFlightLegMutationVariables = {
  input: UpdateFlightLegInput,
  condition?: ModelFlightLegConditionInput | null,
};

export type UpdateFlightLegMutation = {
  updateFlightLeg?:  {
    __typename: "FlightLeg",
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    order: number,
    venueId?: number | null,
    venueName?: string | null,
    venuePostcode?: string | null,
    empty?: boolean | null,
    departing?: string | null,
    fromVenueId?: number | null,
    fromVenueName?: string | null,
    fromVenuePostcode?: string | null,
    fromCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    arriving?: string | null,
    toVenueId?: number | null,
    toVenueName?: string | null,
    toVenuePostcode?: string | null,
    toCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    distance?: number | null,
    ete?: number | null,
    lat?: number | null,
    lng?: number | null,
    w3w?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFlightLegMutationVariables = {
  input: DeleteFlightLegInput,
  condition?: ModelFlightLegConditionInput | null,
};

export type DeleteFlightLegMutation = {
  deleteFlightLeg?:  {
    __typename: "FlightLeg",
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    order: number,
    venueId?: number | null,
    venueName?: string | null,
    venuePostcode?: string | null,
    empty?: boolean | null,
    departing?: string | null,
    fromVenueId?: number | null,
    fromVenueName?: string | null,
    fromVenuePostcode?: string | null,
    fromCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    arriving?: string | null,
    toVenueId?: number | null,
    toVenueName?: string | null,
    toVenuePostcode?: string | null,
    toCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    distance?: number | null,
    ete?: number | null,
    lat?: number | null,
    lng?: number | null,
    w3w?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePushSubscriptionMutationVariables = {
  input: CreatePushSubscriptionInput,
  condition?: ModelPushSubscriptionConditionInput | null,
};

export type CreatePushSubscriptionMutation = {
  createPushSubscription?:  {
    __typename: "PushSubscription",
    user: string,
    channel: PushChannel,
    deviceToken: string,
    deviceType?: string | null,
    expoPushToken: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePushSubscriptionMutationVariables = {
  input: UpdatePushSubscriptionInput,
  condition?: ModelPushSubscriptionConditionInput | null,
};

export type UpdatePushSubscriptionMutation = {
  updatePushSubscription?:  {
    __typename: "PushSubscription",
    user: string,
    channel: PushChannel,
    deviceToken: string,
    deviceType?: string | null,
    expoPushToken: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePushSubscriptionMutationVariables = {
  input: DeletePushSubscriptionInput,
  condition?: ModelPushSubscriptionConditionInput | null,
};

export type DeletePushSubscriptionMutation = {
  deletePushSubscription?:  {
    __typename: "PushSubscription",
    user: string,
    channel: PushChannel,
    deviceToken: string,
    deviceType?: string | null,
    expoPushToken: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserSettingMutationVariables = {
  input: CreateUserSettingInput,
  condition?: ModelUserSettingConditionInput | null,
};

export type CreateUserSettingMutation = {
  createUserSetting?:  {
    __typename: "UserSetting",
    teamLeaderRegionId?: number | null,
    teamLeaderRegionIds?: Array< number | null > | null,
    teamResourceId?: number | null,
    owner: string,
    profileImage?: string | null,
    callsign?: string | null,
    calCode?: string | null,
    appVersion?: string | null,
    weightDisplay?: WeightDisplay | null,
    accessibilityIcons?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserSettingMutationVariables = {
  input: UpdateUserSettingInput,
  condition?: ModelUserSettingConditionInput | null,
};

export type UpdateUserSettingMutation = {
  updateUserSetting?:  {
    __typename: "UserSetting",
    teamLeaderRegionId?: number | null,
    teamLeaderRegionIds?: Array< number | null > | null,
    teamResourceId?: number | null,
    owner: string,
    profileImage?: string | null,
    callsign?: string | null,
    calCode?: string | null,
    appVersion?: string | null,
    weightDisplay?: WeightDisplay | null,
    accessibilityIcons?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserSettingMutationVariables = {
  input: DeleteUserSettingInput,
  condition?: ModelUserSettingConditionInput | null,
};

export type DeleteUserSettingMutation = {
  deleteUserSetting?:  {
    __typename: "UserSetting",
    teamLeaderRegionId?: number | null,
    teamLeaderRegionIds?: Array< number | null > | null,
    teamResourceId?: number | null,
    owner: string,
    profileImage?: string | null,
    callsign?: string | null,
    calCode?: string | null,
    appVersion?: string | null,
    weightDisplay?: WeightDisplay | null,
    accessibilityIcons?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCertificateMutationVariables = {
  input: CreateCertificateInput,
  condition?: ModelCertificateConditionInput | null,
};

export type CreateCertificateMutation = {
  createCertificate?:  {
    __typename: "Certificate",
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCertificateMutationVariables = {
  input: UpdateCertificateInput,
  condition?: ModelCertificateConditionInput | null,
};

export type UpdateCertificateMutation = {
  updateCertificate?:  {
    __typename: "Certificate",
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCertificateMutationVariables = {
  input: DeleteCertificateInput,
  condition?: ModelCertificateConditionInput | null,
};

export type DeleteCertificateMutation = {
  deleteCertificate?:  {
    __typename: "Certificate",
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserCertificateMutationVariables = {
  input: CreateUserCertificateInput,
  condition?: ModelUserCertificateConditionInput | null,
};

export type CreateUserCertificateMutation = {
  createUserCertificate?:  {
    __typename: "UserCertificate",
    key: string,
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserCertificateMutationVariables = {
  input: UpdateUserCertificateInput,
  condition?: ModelUserCertificateConditionInput | null,
};

export type UpdateUserCertificateMutation = {
  updateUserCertificate?:  {
    __typename: "UserCertificate",
    key: string,
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserCertificateMutationVariables = {
  input: DeleteUserCertificateInput,
  condition?: ModelUserCertificateConditionInput | null,
};

export type DeleteUserCertificateMutation = {
  deleteUserCertificate?:  {
    __typename: "UserCertificate",
    key: string,
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBroadcastMessageMutationVariables = {
  input: CreateBroadcastMessageInput,
  condition?: ModelBroadcastMessageConditionInput | null,
};

export type CreateBroadcastMessageMutation = {
  createBroadcastMessage?:  {
    __typename: "BroadcastMessage",
    id: string,
    modelType?: BroadcastMessageString | null,
    message: string,
    title?: string | null,
    originType?: BroadcastMessageOrigin | null,
    originId?: string | null,
    sentBy?: string | null,
    url?: string | null,
    extra?: string | null,
    targetedGroup?: UserGroups | null,
    targetedRole?: TeamMemberRole | null,
    targetedEventId?: string | null,
    targetedEvent?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    targetedUsers?: Array< string | null > | null,
    users?: Array< string | null > | null,
    broadcastAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBroadcastMessageMutationVariables = {
  input: UpdateBroadcastMessageInput,
  condition?: ModelBroadcastMessageConditionInput | null,
};

export type UpdateBroadcastMessageMutation = {
  updateBroadcastMessage?:  {
    __typename: "BroadcastMessage",
    id: string,
    modelType?: BroadcastMessageString | null,
    message: string,
    title?: string | null,
    originType?: BroadcastMessageOrigin | null,
    originId?: string | null,
    sentBy?: string | null,
    url?: string | null,
    extra?: string | null,
    targetedGroup?: UserGroups | null,
    targetedRole?: TeamMemberRole | null,
    targetedEventId?: string | null,
    targetedEvent?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    targetedUsers?: Array< string | null > | null,
    users?: Array< string | null > | null,
    broadcastAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBroadcastReceiptMutationVariables = {
  input: CreateBroadcastReceiptInput,
  condition?: ModelBroadcastReceiptConditionInput | null,
};

export type CreateBroadcastReceiptMutation = {
  createBroadcastReceipt?:  {
    __typename: "BroadcastReceipt",
    id: string,
    broadcastMessageId?: string | null,
    broadcastMessage?:  {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner: string,
    pushToken: string,
    deviceToken: string,
    sentAt?: string | null,
    status?: BroadcastReceiptStatus | null,
    message?: string | null,
    details?: string | null,
    deliveredAt?: string | null,
    errors?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBroadcastReceiptMutationVariables = {
  input: UpdateBroadcastReceiptInput,
  condition?: ModelBroadcastReceiptConditionInput | null,
};

export type UpdateBroadcastReceiptMutation = {
  updateBroadcastReceipt?:  {
    __typename: "BroadcastReceipt",
    id: string,
    broadcastMessageId?: string | null,
    broadcastMessage?:  {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner: string,
    pushToken: string,
    deviceToken: string,
    sentAt?: string | null,
    status?: BroadcastReceiptStatus | null,
    message?: string | null,
    details?: string | null,
    deliveredAt?: string | null,
    errors?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBroadcastReceiptMutationVariables = {
  input: DeleteBroadcastReceiptInput,
  condition?: ModelBroadcastReceiptConditionInput | null,
};

export type DeleteBroadcastReceiptMutation = {
  deleteBroadcastReceipt?:  {
    __typename: "BroadcastReceipt",
    id: string,
    broadcastMessageId?: string | null,
    broadcastMessage?:  {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner: string,
    pushToken: string,
    deviceToken: string,
    sentAt?: string | null,
    status?: BroadcastReceiptStatus | null,
    message?: string | null,
    details?: string | null,
    deliveredAt?: string | null,
    errors?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateExperimentMutationVariables = {
  input: CreateExperimentInput,
  condition?: ModelExperimentConditionInput | null,
};

export type CreateExperimentMutation = {
  createExperiment?:  {
    __typename: "Experiment",
    owner: string,
    experiment: Experiments,
    enabled: boolean,
    extra?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateExperimentMutationVariables = {
  input: UpdateExperimentInput,
  condition?: ModelExperimentConditionInput | null,
};

export type UpdateExperimentMutation = {
  updateExperiment?:  {
    __typename: "Experiment",
    owner: string,
    experiment: Experiments,
    enabled: boolean,
    extra?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteExperimentMutationVariables = {
  input: DeleteExperimentInput,
  condition?: ModelExperimentConditionInput | null,
};

export type DeleteExperimentMutation = {
  deleteExperiment?:  {
    __typename: "Experiment",
    owner: string,
    experiment: Experiments,
    enabled: boolean,
    extra?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePhotoAssetMutationVariables = {
  input: CreatePhotoAssetInput,
  condition?: ModelPhotoAssetConditionInput | null,
};

export type CreatePhotoAssetMutation = {
  createPhotoAsset?:  {
    __typename: "PhotoAsset",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    files?:  Array< {
      __typename: "PhotoObject",
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      soldAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
    } | null > | null,
    enquiryId?: string | null,
    passengers?: string | null,
    email?: string | null,
    flightId: string,
    flight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    salesAtEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    salesAfterEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    soldBy: string,
    emailSent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePhotoAssetMutationVariables = {
  input: UpdatePhotoAssetInput,
  condition?: ModelPhotoAssetConditionInput | null,
};

export type UpdatePhotoAssetMutation = {
  updatePhotoAsset?:  {
    __typename: "PhotoAsset",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    files?:  Array< {
      __typename: "PhotoObject",
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      soldAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
    } | null > | null,
    enquiryId?: string | null,
    passengers?: string | null,
    email?: string | null,
    flightId: string,
    flight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    salesAtEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    salesAfterEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    soldBy: string,
    emailSent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePhotoAssetMutationVariables = {
  input: DeletePhotoAssetInput,
  condition?: ModelPhotoAssetConditionInput | null,
};

export type DeletePhotoAssetMutation = {
  deletePhotoAsset?:  {
    __typename: "PhotoAsset",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    files?:  Array< {
      __typename: "PhotoObject",
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      soldAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
    } | null > | null,
    enquiryId?: string | null,
    passengers?: string | null,
    email?: string | null,
    flightId: string,
    flight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    salesAtEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    salesAfterEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    soldBy: string,
    emailSent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSafetyCommMutationVariables = {
  input: CreateSafetyCommInput,
  condition?: ModelSafetyCommConditionInput | null,
};

export type CreateSafetyCommMutation = {
  createSafetyComm?:  {
    __typename: "SafetyComm",
    id: string,
    title: string,
    modelType: SafetyCommString,
    url?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    publishedBy?: string | null,
    publishedAt: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSafetyCommMutationVariables = {
  input: UpdateSafetyCommInput,
  condition?: ModelSafetyCommConditionInput | null,
};

export type UpdateSafetyCommMutation = {
  updateSafetyComm?:  {
    __typename: "SafetyComm",
    id: string,
    title: string,
    modelType: SafetyCommString,
    url?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    publishedBy?: string | null,
    publishedAt: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSafetyCommMutationVariables = {
  input: DeleteSafetyCommInput,
  condition?: ModelSafetyCommConditionInput | null,
};

export type DeleteSafetyCommMutation = {
  deleteSafetyComm?:  {
    __typename: "SafetyComm",
    id: string,
    title: string,
    modelType: SafetyCommString,
    url?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    publishedBy?: string | null,
    publishedAt: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEventsQuery = {
  syncEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListEventsByDateQueryVariables = {
  modelType: TaskTypeString,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByDateQuery = {
  listEventsByDate?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListEventsByTypeQueryVariables = {
  type: EventType,
  oid?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByTypeQuery = {
  listEventsByType?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListEventsByTypeAndDateQueryVariables = {
  type: EventType,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByTypeAndDateQuery = {
  listEventsByTypeAndDate?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListEventsByRegionIdQueryVariables = {
  regionId: number,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsByRegionIdQuery = {
  listEventsByRegionId?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchEventsQueryVariables = {
  filter?: SearchableEventFilterInput | null,
  sort?: Array< SearchableEventSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableEventAggregationInput | null > | null,
};

export type SearchEventsQuery = {
  searchEvents?:  {
    __typename: "SearchableEventConnection",
    items:  Array< {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPleasureOverviewQueryVariables = {
  id: string,
};

export type GetPleasureOverviewQuery = {
  getPleasureOverview?:  {
    __typename: "PleasureOverview",
    id: string,
    eventId: string,
    team?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    manifestItems?:  {
      __typename: "ModelManifestItemConnection",
      items:  Array< {
        __typename: "ManifestItem",
        id: string,
        overviewId: string,
        eventId?: string | null,
        time?: string | null,
        duration?: number | null,
        designator?: PfFlightDesignator | null,
        pax?: number | null,
        weight?: number | null,
        voucherIds?: Array< string | null > | null,
        frontSeatTaken?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    paxBooked?: number | null,
    aircraftReg?: string | null,
    aircraftSeats?: number | null,
    fireName?: string | null,
    fireReg?: string | null,
    bowserName?: string | null,
    truckName?: string | null,
    truckReg?: string | null,
    counts?: string | null,
    teamLeaderId?: string | null,
    teamLeader?:  {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    disabledRoles?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPleasureOverviewsQueryVariables = {
  filter?: ModelPleasureOverviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPleasureOverviewsQuery = {
  listPleasureOverviews?:  {
    __typename: "ModelPleasureOverviewConnection",
    items:  Array< {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPleasureOverviewsQueryVariables = {
  filter?: ModelPleasureOverviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPleasureOverviewsQuery = {
  syncPleasureOverviews?:  {
    __typename: "ModelPleasureOverviewConnection",
    items:  Array< {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeamMemberQueryVariables = {
  id: string,
};

export type GetTeamMemberQuery = {
  getTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    overviewId?: string | null,
    eventId?: string | null,
    name?: string | null,
    forename?: string | null,
    surname?: string | null,
    teamResourceId?: number | null,
    teamRole?: string | null,
    phone?: string | null,
    confirmed?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTeamMembersQueryVariables = {
  filter?: ModelTeamMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMembersQuery = {
  listTeamMembers?:  {
    __typename: "ModelTeamMemberConnection",
    items:  Array< {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTeamMembersQueryVariables = {
  filter?: ModelTeamMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTeamMembersQuery = {
  syncTeamMembers?:  {
    __typename: "ModelTeamMemberConnection",
    items:  Array< {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamMembersByOverviewQueryVariables = {
  overviewId: string,
  teamRole?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMembersByOverviewQuery = {
  listTeamMembersByOverview?:  {
    __typename: "ModelTeamMemberConnection",
    items:  Array< {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamMembersByEventQueryVariables = {
  eventId: string,
  teamRole?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMembersByEventQuery = {
  listTeamMembersByEvent?:  {
    __typename: "ModelTeamMemberConnection",
    items:  Array< {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetManifestItemQueryVariables = {
  id: string,
};

export type GetManifestItemQuery = {
  getManifestItem?:  {
    __typename: "ManifestItem",
    id: string,
    overviewId: string,
    eventId?: string | null,
    time?: string | null,
    duration?: number | null,
    designator?: PfFlightDesignator | null,
    pax?: number | null,
    weight?: number | null,
    voucherIds?: Array< string | null > | null,
    frontSeatTaken?: boolean | null,
    passengers?:  Array< {
      __typename: "ManifestPassenger",
      title?: string | null,
      forename?: string | null,
      surname?: string | null,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      weight?: number | null,
      enquiryId?: string | null,
      voucherId?: string | null,
      frontSeat?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListManifestItemsQueryVariables = {
  filter?: ModelManifestItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManifestItemsQuery = {
  listManifestItems?:  {
    __typename: "ModelManifestItemConnection",
    items:  Array< {
      __typename: "ManifestItem",
      id: string,
      overviewId: string,
      eventId?: string | null,
      time?: string | null,
      duration?: number | null,
      designator?: PfFlightDesignator | null,
      pax?: number | null,
      weight?: number | null,
      voucherIds?: Array< string | null > | null,
      frontSeatTaken?: boolean | null,
      passengers?:  Array< {
        __typename: "ManifestPassenger",
        title?: string | null,
        forename?: string | null,
        surname?: string | null,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        weight?: number | null,
        enquiryId?: string | null,
        voucherId?: string | null,
        frontSeat?: boolean | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncManifestItemsQueryVariables = {
  filter?: ModelManifestItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncManifestItemsQuery = {
  syncManifestItems?:  {
    __typename: "ModelManifestItemConnection",
    items:  Array< {
      __typename: "ManifestItem",
      id: string,
      overviewId: string,
      eventId?: string | null,
      time?: string | null,
      duration?: number | null,
      designator?: PfFlightDesignator | null,
      pax?: number | null,
      weight?: number | null,
      voucherIds?: Array< string | null > | null,
      frontSeatTaken?: boolean | null,
      passengers?:  Array< {
        __typename: "ManifestPassenger",
        title?: string | null,
        forename?: string | null,
        surname?: string | null,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        weight?: number | null,
        enquiryId?: string | null,
        voucherId?: string | null,
        frontSeat?: boolean | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListManifestItemsByOverviewQueryVariables = {
  overviewId: string,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelManifestItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManifestItemsByOverviewQuery = {
  listManifestItemsByOverview?:  {
    __typename: "ModelManifestItemConnection",
    items:  Array< {
      __typename: "ManifestItem",
      id: string,
      overviewId: string,
      eventId?: string | null,
      time?: string | null,
      duration?: number | null,
      designator?: PfFlightDesignator | null,
      pax?: number | null,
      weight?: number | null,
      voucherIds?: Array< string | null > | null,
      frontSeatTaken?: boolean | null,
      passengers?:  Array< {
        __typename: "ManifestPassenger",
        title?: string | null,
        forename?: string | null,
        surname?: string | null,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        weight?: number | null,
        enquiryId?: string | null,
        voucherId?: string | null,
        frontSeat?: boolean | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListManifestItemsByEventQueryVariables = {
  eventId: string,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelManifestItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManifestItemsByEventQuery = {
  listManifestItemsByEvent?:  {
    __typename: "ModelManifestItemConnection",
    items:  Array< {
      __typename: "ManifestItem",
      id: string,
      overviewId: string,
      eventId?: string | null,
      time?: string | null,
      duration?: number | null,
      designator?: PfFlightDesignator | null,
      pax?: number | null,
      weight?: number | null,
      voucherIds?: Array< string | null > | null,
      frontSeatTaken?: boolean | null,
      passengers?:  Array< {
        __typename: "ManifestPassenger",
        title?: string | null,
        forename?: string | null,
        surname?: string | null,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        weight?: number | null,
        enquiryId?: string | null,
        voucherId?: string | null,
        frontSeat?: boolean | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchManifestItemsQueryVariables = {
  filter?: SearchableManifestItemFilterInput | null,
  sort?: Array< SearchableManifestItemSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableManifestItemAggregationInput | null > | null,
};

export type SearchManifestItemsQuery = {
  searchManifestItems?:  {
    __typename: "SearchableManifestItemConnection",
    items:  Array< {
      __typename: "ManifestItem",
      id: string,
      overviewId: string,
      eventId?: string | null,
      time?: string | null,
      duration?: number | null,
      designator?: PfFlightDesignator | null,
      pax?: number | null,
      weight?: number | null,
      voucherIds?: Array< string | null > | null,
      frontSeatTaken?: boolean | null,
      passengers?:  Array< {
        __typename: "ManifestPassenger",
        title?: string | null,
        forename?: string | null,
        surname?: string | null,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        weight?: number | null,
        enquiryId?: string | null,
        voucherId?: string | null,
        frontSeat?: boolean | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetFlightQueryVariables = {
  id: string,
};

export type GetFlightQuery = {
  getFlight?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFlightsQueryVariables = {
  filter?: ModelFlightFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlightsQuery = {
  listFlights?:  {
    __typename: "ModelFlightConnection",
    items:  Array< {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFlightsQueryVariables = {
  filter?: ModelFlightFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFlightsQuery = {
  syncFlights?:  {
    __typename: "ModelFlightConnection",
    items:  Array< {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListFlightsByEventSequenceQueryVariables = {
  eventId: string,
  scheduledDeparture?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFlightFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlightsByEventSequenceQuery = {
  listFlightsByEventSequence?:  {
    __typename: "ModelFlightConnection",
    items:  Array< {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchFlightsQueryVariables = {
  filter?: SearchableFlightFilterInput | null,
  sort?: Array< SearchableFlightSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableFlightAggregationInput | null > | null,
};

export type SearchFlightsQuery = {
  searchFlights?:  {
    __typename: "SearchableFlightConnection",
    items:  Array< {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetRefuelRequestQueryVariables = {
  id: string,
};

export type GetRefuelRequestQuery = {
  getRefuelRequest?:  {
    __typename: "RefuelRequest",
    id: string,
    eventId: string,
    requestedBy?: string | null,
    requestFrom?: TeamMemberRole | null,
    delay?: number | null,
    users: Array< string >,
    flightOrderId?: number | null,
    adventureId?: number | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRefuelRequestsQueryVariables = {
  filter?: ModelRefuelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRefuelRequestsQuery = {
  listRefuelRequests?:  {
    __typename: "ModelRefuelRequestConnection",
    items:  Array< {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRefuelRequestsQueryVariables = {
  filter?: ModelRefuelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRefuelRequestsQuery = {
  syncRefuelRequests?:  {
    __typename: "ModelRefuelRequestConnection",
    items:  Array< {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListRefuelRequestsByEventIdQueryVariables = {
  eventId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRefuelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRefuelRequestsByEventIdQuery = {
  listRefuelRequestsByEventId?:  {
    __typename: "ModelRefuelRequestConnection",
    items:  Array< {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRefuelQueryVariables = {
  id: string,
};

export type GetRefuelQuery = {
  getRefuel?:  {
    __typename: "Refuel",
    id: string,
    eventId: string,
    refuelRequestId?: string | null,
    refuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    users: Array< string >,
    createdAt: string,
    refueledAt: string,
    refueledBy?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRefuelsQueryVariables = {
  filter?: ModelRefuelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRefuelsQuery = {
  listRefuels?:  {
    __typename: "ModelRefuelConnection",
    items:  Array< {
      __typename: "Refuel",
      id: string,
      eventId: string,
      refuelRequestId?: string | null,
      refuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      users: Array< string >,
      createdAt: string,
      refueledAt: string,
      refueledBy?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRefuelsQueryVariables = {
  filter?: ModelRefuelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRefuelsQuery = {
  syncRefuels?:  {
    __typename: "ModelRefuelConnection",
    items:  Array< {
      __typename: "Refuel",
      id: string,
      eventId: string,
      refuelRequestId?: string | null,
      refuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      users: Array< string >,
      createdAt: string,
      refueledAt: string,
      refueledBy?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListRefuelsByEventIdQueryVariables = {
  eventId: string,
  refueledAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRefuelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRefuelsByEventIdQuery = {
  listRefuelsByEventId?:  {
    __typename: "ModelRefuelConnection",
    items:  Array< {
      __typename: "Refuel",
      id: string,
      eventId: string,
      refuelRequestId?: string | null,
      refuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      users: Array< string >,
      createdAt: string,
      refueledAt: string,
      refueledBy?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFlightPassengerQueryVariables = {
  id: string,
};

export type GetFlightPassengerQuery = {
  getFlightPassenger?:  {
    __typename: "FlightPassenger",
    id: string,
    eventId: string,
    tupleId?: string | null,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    enquiryId?: number | null,
    voucherId?: string | null,
    designator?: PfFlightDesignator | null,
    sequence?: number | null,
    flightNumber?: string | null,
    flightId?: string | null,
    number: number,
    passengerNumber?: number | null,
    forename: string,
    surname: string,
    weight: number,
    seat: PassengerSeat,
    phone?: string | null,
    email?: string | null,
    postcode?: string | null,
    bubbly?: boolean | null,
    creamTea?: boolean | null,
    lunch?: boolean | null,
    magnum?: boolean | null,
    chocolate?: boolean | null,
    photo?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFlightPassengersQueryVariables = {
  filter?: ModelFlightPassengerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlightPassengersQuery = {
  listFlightPassengers?:  {
    __typename: "ModelFlightPassengerConnection",
    items:  Array< {
      __typename: "FlightPassenger",
      id: string,
      eventId: string,
      tupleId?: string | null,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      enquiryId?: number | null,
      voucherId?: string | null,
      designator?: PfFlightDesignator | null,
      sequence?: number | null,
      flightNumber?: string | null,
      flightId?: string | null,
      number: number,
      passengerNumber?: number | null,
      forename: string,
      surname: string,
      weight: number,
      seat: PassengerSeat,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      bubbly?: boolean | null,
      creamTea?: boolean | null,
      lunch?: boolean | null,
      magnum?: boolean | null,
      chocolate?: boolean | null,
      photo?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFlightPassengersQueryVariables = {
  filter?: ModelFlightPassengerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFlightPassengersQuery = {
  syncFlightPassengers?:  {
    __typename: "ModelFlightPassengerConnection",
    items:  Array< {
      __typename: "FlightPassenger",
      id: string,
      eventId: string,
      tupleId?: string | null,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      enquiryId?: number | null,
      voucherId?: string | null,
      designator?: PfFlightDesignator | null,
      sequence?: number | null,
      flightNumber?: string | null,
      flightId?: string | null,
      number: number,
      passengerNumber?: number | null,
      forename: string,
      surname: string,
      weight: number,
      seat: PassengerSeat,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      bubbly?: boolean | null,
      creamTea?: boolean | null,
      lunch?: boolean | null,
      magnum?: boolean | null,
      chocolate?: boolean | null,
      photo?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListFlightPassengersByEventQueryVariables = {
  eventId: string,
  flightIdNumber?: ModelFlightPassengerByEventCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFlightPassengerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlightPassengersByEventQuery = {
  listFlightPassengersByEvent?:  {
    __typename: "ModelFlightPassengerConnection",
    items:  Array< {
      __typename: "FlightPassenger",
      id: string,
      eventId: string,
      tupleId?: string | null,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      enquiryId?: number | null,
      voucherId?: string | null,
      designator?: PfFlightDesignator | null,
      sequence?: number | null,
      flightNumber?: string | null,
      flightId?: string | null,
      number: number,
      passengerNumber?: number | null,
      forename: string,
      surname: string,
      weight: number,
      seat: PassengerSeat,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      bubbly?: boolean | null,
      creamTea?: boolean | null,
      lunch?: boolean | null,
      magnum?: boolean | null,
      chocolate?: boolean | null,
      photo?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListFlightPassengersByFlightQueryVariables = {
  flightId: string,
  eventIdNumber?: ModelFlightPassengerByFlightCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFlightPassengerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlightPassengersByFlightQuery = {
  listFlightPassengersByFlight?:  {
    __typename: "ModelFlightPassengerConnection",
    items:  Array< {
      __typename: "FlightPassenger",
      id: string,
      eventId: string,
      tupleId?: string | null,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      enquiryId?: number | null,
      voucherId?: string | null,
      designator?: PfFlightDesignator | null,
      sequence?: number | null,
      flightNumber?: string | null,
      flightId?: string | null,
      number: number,
      passengerNumber?: number | null,
      forename: string,
      surname: string,
      weight: number,
      seat: PassengerSeat,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      bubbly?: boolean | null,
      creamTea?: boolean | null,
      lunch?: boolean | null,
      magnum?: boolean | null,
      chocolate?: boolean | null,
      photo?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchFlightPassengersQueryVariables = {
  filter?: SearchableFlightPassengerFilterInput | null,
  sort?: Array< SearchableFlightPassengerSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableFlightPassengerAggregationInput | null > | null,
};

export type SearchFlightPassengersQuery = {
  searchFlightPassengers?:  {
    __typename: "SearchableFlightPassengerConnection",
    items:  Array< {
      __typename: "FlightPassenger",
      id: string,
      eventId: string,
      tupleId?: string | null,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      enquiryId?: number | null,
      voucherId?: string | null,
      designator?: PfFlightDesignator | null,
      sequence?: number | null,
      flightNumber?: string | null,
      flightId?: string | null,
      number: number,
      passengerNumber?: number | null,
      forename: string,
      surname: string,
      weight: number,
      seat: PassengerSeat,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      bubbly?: boolean | null,
      creamTea?: boolean | null,
      lunch?: boolean | null,
      magnum?: boolean | null,
      chocolate?: boolean | null,
      photo?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetFollowingLogQueryVariables = {
  id: string,
};

export type GetFollowingLogQuery = {
  getFollowingLog?:  {
    __typename: "FollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFollowingLogsQueryVariables = {
  filter?: ModelFollowingLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFollowingLogsQuery = {
  listFollowingLogs?:  {
    __typename: "ModelFollowingLogConnection",
    items:  Array< {
      __typename: "FollowingLog",
      modelType: FollowingLogString,
      id: string,
      user: string,
      pilotName?: string | null,
      type: EventType,
      notes?: string | null,
      eventId?: string | null,
      originId: string,
      originType: FollowingLogOrigin,
      aircraft?: string | null,
      aircraftType?: string | null,
      pob?: number | null,
      action?: FollowingAction | null,
      origin?: string | null,
      destination?: string | null,
      d_lat?: string | null,
      d_lng?: string | null,
      accuracy?: number | null,
      altitude?: number | null,
      altitudeAccuracy?: number | null,
      lat?: string | null,
      lng?: string | null,
      eta?: number | null,
      ete?: number | null,
      gs?: number | null,
      hdg?: number | null,
      battery?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFollowingLogsQueryVariables = {
  filter?: ModelFollowingLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFollowingLogsQuery = {
  syncFollowingLogs?:  {
    __typename: "ModelFollowingLogConnection",
    items:  Array< {
      __typename: "FollowingLog",
      modelType: FollowingLogString,
      id: string,
      user: string,
      pilotName?: string | null,
      type: EventType,
      notes?: string | null,
      eventId?: string | null,
      originId: string,
      originType: FollowingLogOrigin,
      aircraft?: string | null,
      aircraftType?: string | null,
      pob?: number | null,
      action?: FollowingAction | null,
      origin?: string | null,
      destination?: string | null,
      d_lat?: string | null,
      d_lng?: string | null,
      accuracy?: number | null,
      altitude?: number | null,
      altitudeAccuracy?: number | null,
      lat?: string | null,
      lng?: string | null,
      eta?: number | null,
      ete?: number | null,
      gs?: number | null,
      hdg?: number | null,
      battery?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListFollowingLogsByCreatedAtQueryVariables = {
  modelType: FollowingLogString,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFollowingLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFollowingLogsByCreatedAtQuery = {
  listFollowingLogsByCreatedAt?:  {
    __typename: "ModelFollowingLogConnection",
    items:  Array< {
      __typename: "FollowingLog",
      modelType: FollowingLogString,
      id: string,
      user: string,
      pilotName?: string | null,
      type: EventType,
      notes?: string | null,
      eventId?: string | null,
      originId: string,
      originType: FollowingLogOrigin,
      aircraft?: string | null,
      aircraftType?: string | null,
      pob?: number | null,
      action?: FollowingAction | null,
      origin?: string | null,
      destination?: string | null,
      d_lat?: string | null,
      d_lng?: string | null,
      accuracy?: number | null,
      altitude?: number | null,
      altitudeAccuracy?: number | null,
      lat?: string | null,
      lng?: string | null,
      eta?: number | null,
      ete?: number | null,
      gs?: number | null,
      hdg?: number | null,
      battery?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchFollowingLogsQueryVariables = {
  filter?: SearchableFollowingLogFilterInput | null,
  sort?: Array< SearchableFollowingLogSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableFollowingLogAggregationInput | null > | null,
};

export type SearchFollowingLogsQuery = {
  searchFollowingLogs?:  {
    __typename: "SearchableFollowingLogConnection",
    items:  Array< {
      __typename: "FollowingLog",
      modelType: FollowingLogString,
      id: string,
      user: string,
      pilotName?: string | null,
      type: EventType,
      notes?: string | null,
      eventId?: string | null,
      originId: string,
      originType: FollowingLogOrigin,
      aircraft?: string | null,
      aircraftType?: string | null,
      pob?: number | null,
      action?: FollowingAction | null,
      origin?: string | null,
      destination?: string | null,
      d_lat?: string | null,
      d_lng?: string | null,
      accuracy?: number | null,
      altitude?: number | null,
      altitudeAccuracy?: number | null,
      lat?: string | null,
      lng?: string | null,
      eta?: number | null,
      ete?: number | null,
      gs?: number | null,
      hdg?: number | null,
      battery?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetAzureFollowingLogQueryVariables = {
  id: string,
};

export type GetAzureFollowingLogQuery = {
  getAzureFollowingLog?:  {
    __typename: "AzureFollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAzureFollowingLogsQueryVariables = {
  filter?: ModelAzureFollowingLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAzureFollowingLogsQuery = {
  listAzureFollowingLogs?:  {
    __typename: "ModelAzureFollowingLogConnection",
    items:  Array< {
      __typename: "AzureFollowingLog",
      modelType: FollowingLogString,
      id: string,
      user: string,
      pilotName?: string | null,
      type: EventType,
      notes?: string | null,
      eventId?: string | null,
      originId: string,
      originType: FollowingLogOrigin,
      aircraft?: string | null,
      aircraftType?: string | null,
      pob?: number | null,
      action?: FollowingAction | null,
      origin?: string | null,
      destination?: string | null,
      d_lat?: string | null,
      d_lng?: string | null,
      accuracy?: number | null,
      altitude?: number | null,
      altitudeAccuracy?: number | null,
      lat?: string | null,
      lng?: string | null,
      eta?: number | null,
      ete?: number | null,
      gs?: number | null,
      hdg?: number | null,
      battery?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAzureFollowingLogsQueryVariables = {
  filter?: ModelAzureFollowingLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAzureFollowingLogsQuery = {
  syncAzureFollowingLogs?:  {
    __typename: "ModelAzureFollowingLogConnection",
    items:  Array< {
      __typename: "AzureFollowingLog",
      modelType: FollowingLogString,
      id: string,
      user: string,
      pilotName?: string | null,
      type: EventType,
      notes?: string | null,
      eventId?: string | null,
      originId: string,
      originType: FollowingLogOrigin,
      aircraft?: string | null,
      aircraftType?: string | null,
      pob?: number | null,
      action?: FollowingAction | null,
      origin?: string | null,
      destination?: string | null,
      d_lat?: string | null,
      d_lng?: string | null,
      accuracy?: number | null,
      altitude?: number | null,
      altitudeAccuracy?: number | null,
      lat?: string | null,
      lng?: string | null,
      eta?: number | null,
      ete?: number | null,
      gs?: number | null,
      hdg?: number | null,
      battery?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListAzureFollowingLogsByCreatedAtQueryVariables = {
  modelType: FollowingLogString,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAzureFollowingLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAzureFollowingLogsByCreatedAtQuery = {
  listAzureFollowingLogsByCreatedAt?:  {
    __typename: "ModelAzureFollowingLogConnection",
    items:  Array< {
      __typename: "AzureFollowingLog",
      modelType: FollowingLogString,
      id: string,
      user: string,
      pilotName?: string | null,
      type: EventType,
      notes?: string | null,
      eventId?: string | null,
      originId: string,
      originType: FollowingLogOrigin,
      aircraft?: string | null,
      aircraftType?: string | null,
      pob?: number | null,
      action?: FollowingAction | null,
      origin?: string | null,
      destination?: string | null,
      d_lat?: string | null,
      d_lng?: string | null,
      accuracy?: number | null,
      altitude?: number | null,
      altitudeAccuracy?: number | null,
      lat?: string | null,
      lng?: string | null,
      eta?: number | null,
      ete?: number | null,
      gs?: number | null,
      hdg?: number | null,
      battery?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchAzureFollowingLogsQueryVariables = {
  filter?: SearchableAzureFollowingLogFilterInput | null,
  sort?: Array< SearchableAzureFollowingLogSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableAzureFollowingLogAggregationInput | null > | null,
};

export type SearchAzureFollowingLogsQuery = {
  searchAzureFollowingLogs?:  {
    __typename: "SearchableAzureFollowingLogConnection",
    items:  Array< {
      __typename: "AzureFollowingLog",
      modelType: FollowingLogString,
      id: string,
      user: string,
      pilotName?: string | null,
      type: EventType,
      notes?: string | null,
      eventId?: string | null,
      originId: string,
      originType: FollowingLogOrigin,
      aircraft?: string | null,
      aircraftType?: string | null,
      pob?: number | null,
      action?: FollowingAction | null,
      origin?: string | null,
      destination?: string | null,
      d_lat?: string | null,
      d_lng?: string | null,
      accuracy?: number | null,
      altitude?: number | null,
      altitudeAccuracy?: number | null,
      lat?: string | null,
      lng?: string | null,
      eta?: number | null,
      ete?: number | null,
      gs?: number | null,
      hdg?: number | null,
      battery?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetFlightLegQueryVariables = {
  id: string,
};

export type GetFlightLegQuery = {
  getFlightLeg?:  {
    __typename: "FlightLeg",
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    order: number,
    venueId?: number | null,
    venueName?: string | null,
    venuePostcode?: string | null,
    empty?: boolean | null,
    departing?: string | null,
    fromVenueId?: number | null,
    fromVenueName?: string | null,
    fromVenuePostcode?: string | null,
    fromCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    arriving?: string | null,
    toVenueId?: number | null,
    toVenueName?: string | null,
    toVenuePostcode?: string | null,
    toCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    distance?: number | null,
    ete?: number | null,
    lat?: number | null,
    lng?: number | null,
    w3w?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFlightLegsQueryVariables = {
  filter?: ModelFlightLegFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlightLegsQuery = {
  listFlightLegs?:  {
    __typename: "ModelFlightLegConnection",
    items:  Array< {
      __typename: "FlightLeg",
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      order: number,
      venueId?: number | null,
      venueName?: string | null,
      venuePostcode?: string | null,
      empty?: boolean | null,
      departing?: string | null,
      fromVenueId?: number | null,
      fromVenueName?: string | null,
      fromVenuePostcode?: string | null,
      fromCoords?:  {
        __typename: "Coords",
        lat?: number | null,
        lng?: number | null,
      } | null,
      arriving?: string | null,
      toVenueId?: number | null,
      toVenueName?: string | null,
      toVenuePostcode?: string | null,
      toCoords?:  {
        __typename: "Coords",
        lat?: number | null,
        lng?: number | null,
      } | null,
      distance?: number | null,
      ete?: number | null,
      lat?: number | null,
      lng?: number | null,
      w3w?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFlightLegsQueryVariables = {
  filter?: ModelFlightLegFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFlightLegsQuery = {
  syncFlightLegs?:  {
    __typename: "ModelFlightLegConnection",
    items:  Array< {
      __typename: "FlightLeg",
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      order: number,
      venueId?: number | null,
      venueName?: string | null,
      venuePostcode?: string | null,
      empty?: boolean | null,
      departing?: string | null,
      fromVenueId?: number | null,
      fromVenueName?: string | null,
      fromVenuePostcode?: string | null,
      fromCoords?:  {
        __typename: "Coords",
        lat?: number | null,
        lng?: number | null,
      } | null,
      arriving?: string | null,
      toVenueId?: number | null,
      toVenueName?: string | null,
      toVenuePostcode?: string | null,
      toCoords?:  {
        __typename: "Coords",
        lat?: number | null,
        lng?: number | null,
      } | null,
      distance?: number | null,
      ete?: number | null,
      lat?: number | null,
      lng?: number | null,
      w3w?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListFlightLegsByEventQueryVariables = {
  eventId: string,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFlightLegFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlightLegsByEventQuery = {
  listFlightLegsByEvent?:  {
    __typename: "ModelFlightLegConnection",
    items:  Array< {
      __typename: "FlightLeg",
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      order: number,
      venueId?: number | null,
      venueName?: string | null,
      venuePostcode?: string | null,
      empty?: boolean | null,
      departing?: string | null,
      fromVenueId?: number | null,
      fromVenueName?: string | null,
      fromVenuePostcode?: string | null,
      fromCoords?:  {
        __typename: "Coords",
        lat?: number | null,
        lng?: number | null,
      } | null,
      arriving?: string | null,
      toVenueId?: number | null,
      toVenueName?: string | null,
      toVenuePostcode?: string | null,
      toCoords?:  {
        __typename: "Coords",
        lat?: number | null,
        lng?: number | null,
      } | null,
      distance?: number | null,
      ete?: number | null,
      lat?: number | null,
      lng?: number | null,
      w3w?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchFlightLegsQueryVariables = {
  filter?: SearchableFlightLegFilterInput | null,
  sort?: Array< SearchableFlightLegSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableFlightLegAggregationInput | null > | null,
};

export type SearchFlightLegsQuery = {
  searchFlightLegs?:  {
    __typename: "SearchableFlightLegConnection",
    items:  Array< {
      __typename: "FlightLeg",
      id: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      order: number,
      venueId?: number | null,
      venueName?: string | null,
      venuePostcode?: string | null,
      empty?: boolean | null,
      departing?: string | null,
      fromVenueId?: number | null,
      fromVenueName?: string | null,
      fromVenuePostcode?: string | null,
      fromCoords?:  {
        __typename: "Coords",
        lat?: number | null,
        lng?: number | null,
      } | null,
      arriving?: string | null,
      toVenueId?: number | null,
      toVenueName?: string | null,
      toVenuePostcode?: string | null,
      toCoords?:  {
        __typename: "Coords",
        lat?: number | null,
        lng?: number | null,
      } | null,
      distance?: number | null,
      ete?: number | null,
      lat?: number | null,
      lng?: number | null,
      w3w?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPushSubscriptionQueryVariables = {
  user: string,
  channel: PushChannel,
  deviceToken: string,
  expoPushToken: string,
};

export type GetPushSubscriptionQuery = {
  getPushSubscription?:  {
    __typename: "PushSubscription",
    user: string,
    channel: PushChannel,
    deviceToken: string,
    deviceType?: string | null,
    expoPushToken: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPushSubscriptionsQueryVariables = {
  user?: string | null,
  channelDeviceTokenExpoPushToken?: ModelPushSubscriptionPrimaryCompositeKeyConditionInput | null,
  filter?: ModelPushSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPushSubscriptionsQuery = {
  listPushSubscriptions?:  {
    __typename: "ModelPushSubscriptionConnection",
    items:  Array< {
      __typename: "PushSubscription",
      user: string,
      channel: PushChannel,
      deviceToken: string,
      deviceType?: string | null,
      expoPushToken: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPushSubscriptionsQueryVariables = {
  filter?: ModelPushSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPushSubscriptionsQuery = {
  syncPushSubscriptions?:  {
    __typename: "ModelPushSubscriptionConnection",
    items:  Array< {
      __typename: "PushSubscription",
      user: string,
      channel: PushChannel,
      deviceToken: string,
      deviceType?: string | null,
      expoPushToken: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListPushSubscriptionsByChannelQueryVariables = {
  channel: PushChannel,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPushSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPushSubscriptionsByChannelQuery = {
  listPushSubscriptionsByChannel?:  {
    __typename: "ModelPushSubscriptionConnection",
    items:  Array< {
      __typename: "PushSubscription",
      user: string,
      channel: PushChannel,
      deviceToken: string,
      deviceType?: string | null,
      expoPushToken: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserSettingQueryVariables = {
  owner: string,
};

export type GetUserSettingQuery = {
  getUserSetting?:  {
    __typename: "UserSetting",
    teamLeaderRegionId?: number | null,
    teamLeaderRegionIds?: Array< number | null > | null,
    teamResourceId?: number | null,
    owner: string,
    profileImage?: string | null,
    callsign?: string | null,
    calCode?: string | null,
    appVersion?: string | null,
    weightDisplay?: WeightDisplay | null,
    accessibilityIcons?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserSettingsQueryVariables = {
  owner?: string | null,
  filter?: ModelUserSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserSettingsQuery = {
  listUserSettings?:  {
    __typename: "ModelUserSettingConnection",
    items:  Array< {
      __typename: "UserSetting",
      teamLeaderRegionId?: number | null,
      teamLeaderRegionIds?: Array< number | null > | null,
      teamResourceId?: number | null,
      owner: string,
      profileImage?: string | null,
      callsign?: string | null,
      calCode?: string | null,
      appVersion?: string | null,
      weightDisplay?: WeightDisplay | null,
      accessibilityIcons?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserSettingsQueryVariables = {
  filter?: ModelUserSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserSettingsQuery = {
  syncUserSettings?:  {
    __typename: "ModelUserSettingConnection",
    items:  Array< {
      __typename: "UserSetting",
      teamLeaderRegionId?: number | null,
      teamLeaderRegionIds?: Array< number | null > | null,
      teamResourceId?: number | null,
      owner: string,
      profileImage?: string | null,
      callsign?: string | null,
      calCode?: string | null,
      appVersion?: string | null,
      weightDisplay?: WeightDisplay | null,
      accessibilityIcons?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUserSettingsByTeamResourceIdQueryVariables = {
  teamResourceId: number,
  owner?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSettingsByTeamResourceIdQuery = {
  listUserSettingsByTeamResourceId?:  {
    __typename: "ModelUserSettingConnection",
    items:  Array< {
      __typename: "UserSetting",
      teamLeaderRegionId?: number | null,
      teamLeaderRegionIds?: Array< number | null > | null,
      teamResourceId?: number | null,
      owner: string,
      profileImage?: string | null,
      callsign?: string | null,
      calCode?: string | null,
      appVersion?: string | null,
      weightDisplay?: WeightDisplay | null,
      accessibilityIcons?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUserSettingsByCalCodeQueryVariables = {
  calCode: string,
  owner?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSettingsByCalCodeQuery = {
  listUserSettingsByCalCode?:  {
    __typename: "ModelUserSettingConnection",
    items:  Array< {
      __typename: "UserSetting",
      teamLeaderRegionId?: number | null,
      teamLeaderRegionIds?: Array< number | null > | null,
      teamResourceId?: number | null,
      owner: string,
      profileImage?: string | null,
      callsign?: string | null,
      calCode?: string | null,
      appVersion?: string | null,
      weightDisplay?: WeightDisplay | null,
      accessibilityIcons?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCertificateQueryVariables = {
  owner: string,
  model: CertificateModel,
  type: CertificateType,
};

export type GetCertificateQuery = {
  getCertificate?:  {
    __typename: "Certificate",
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCertificatesQueryVariables = {
  owner?: string | null,
  modelType?: ModelCertificatePrimaryCompositeKeyConditionInput | null,
  filter?: ModelCertificateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCertificatesQuery = {
  listCertificates?:  {
    __typename: "ModelCertificateConnection",
    items:  Array< {
      __typename: "Certificate",
      owner: string,
      model: CertificateModel,
      type: CertificateType,
      number?: string | null,
      aircraft?: string | null,
      pdf?: string | null,
      expiresAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCertificatesQueryVariables = {
  filter?: ModelCertificateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCertificatesQuery = {
  syncCertificates?:  {
    __typename: "ModelCertificateConnection",
    items:  Array< {
      __typename: "Certificate",
      owner: string,
      model: CertificateModel,
      type: CertificateType,
      number?: string | null,
      aircraft?: string | null,
      pdf?: string | null,
      expiresAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListCertificatesByPilotExpiryQueryVariables = {
  model: CertificateModel,
  expiresAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCertificateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCertificatesByPilotExpiryQuery = {
  listCertificatesByPilotExpiry?:  {
    __typename: "ModelCertificateConnection",
    items:  Array< {
      __typename: "Certificate",
      owner: string,
      model: CertificateModel,
      type: CertificateType,
      number?: string | null,
      aircraft?: string | null,
      pdf?: string | null,
      expiresAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserCertificateQueryVariables = {
  key: string,
};

export type GetUserCertificateQuery = {
  getUserCertificate?:  {
    __typename: "UserCertificate",
    key: string,
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserCertificatesQueryVariables = {
  key?: string | null,
  filter?: ModelUserCertificateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserCertificatesQuery = {
  listUserCertificates?:  {
    __typename: "ModelUserCertificateConnection",
    items:  Array< {
      __typename: "UserCertificate",
      key: string,
      owner: string,
      model: CertificateModel,
      type: CertificateType,
      number?: string | null,
      aircraft?: string | null,
      pdf?: string | null,
      expiresAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserCertificatesQueryVariables = {
  filter?: ModelUserCertificateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserCertificatesQuery = {
  syncUserCertificates?:  {
    __typename: "ModelUserCertificateConnection",
    items:  Array< {
      __typename: "UserCertificate",
      key: string,
      owner: string,
      model: CertificateModel,
      type: CertificateType,
      number?: string | null,
      aircraft?: string | null,
      pdf?: string | null,
      expiresAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUserCertificatesByOwnerQueryVariables = {
  owner: string,
  modelTypeAircraft?: ModelUserCertificateByOwnerCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserCertificateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserCertificatesByOwnerQuery = {
  listUserCertificatesByOwner?:  {
    __typename: "ModelUserCertificateConnection",
    items:  Array< {
      __typename: "UserCertificate",
      key: string,
      owner: string,
      model: CertificateModel,
      type: CertificateType,
      number?: string | null,
      aircraft?: string | null,
      pdf?: string | null,
      expiresAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListUserCertificatesByExpiryQueryVariables = {
  model: CertificateModel,
  expiresAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserCertificateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserCertificatesByExpiryQuery = {
  listUserCertificatesByExpiry?:  {
    __typename: "ModelUserCertificateConnection",
    items:  Array< {
      __typename: "UserCertificate",
      key: string,
      owner: string,
      model: CertificateModel,
      type: CertificateType,
      number?: string | null,
      aircraft?: string | null,
      pdf?: string | null,
      expiresAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBroadcastMessageQueryVariables = {
  id: string,
};

export type GetBroadcastMessageQuery = {
  getBroadcastMessage?:  {
    __typename: "BroadcastMessage",
    id: string,
    modelType?: BroadcastMessageString | null,
    message: string,
    title?: string | null,
    originType?: BroadcastMessageOrigin | null,
    originId?: string | null,
    sentBy?: string | null,
    url?: string | null,
    extra?: string | null,
    targetedGroup?: UserGroups | null,
    targetedRole?: TeamMemberRole | null,
    targetedEventId?: string | null,
    targetedEvent?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    targetedUsers?: Array< string | null > | null,
    users?: Array< string | null > | null,
    broadcastAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBroadcastMessagesQueryVariables = {
  filter?: ModelBroadcastMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBroadcastMessagesQuery = {
  listBroadcastMessages?:  {
    __typename: "ModelBroadcastMessageConnection",
    items:  Array< {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBroadcastMessagesQueryVariables = {
  filter?: ModelBroadcastMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBroadcastMessagesQuery = {
  syncBroadcastMessages?:  {
    __typename: "ModelBroadcastMessageConnection",
    items:  Array< {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBroadcastMessagesByCreatedAtQueryVariables = {
  modelType: BroadcastMessageString,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBroadcastMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBroadcastMessagesByCreatedAtQuery = {
  listBroadcastMessagesByCreatedAt?:  {
    __typename: "ModelBroadcastMessageConnection",
    items:  Array< {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBroadcastMessagesByOriginQueryVariables = {
  originId: string,
  originTypeCreatedAt?: ModelBroadcastMessageByOriginCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBroadcastMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBroadcastMessagesByOriginQuery = {
  listBroadcastMessagesByOrigin?:  {
    __typename: "ModelBroadcastMessageConnection",
    items:  Array< {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchBroadcastMessagesQueryVariables = {
  filter?: SearchableBroadcastMessageFilterInput | null,
  sort?: Array< SearchableBroadcastMessageSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableBroadcastMessageAggregationInput | null > | null,
};

export type SearchBroadcastMessagesQuery = {
  searchBroadcastMessages?:  {
    __typename: "SearchableBroadcastMessageConnection",
    items:  Array< {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetBroadcastReceiptQueryVariables = {
  id: string,
};

export type GetBroadcastReceiptQuery = {
  getBroadcastReceipt?:  {
    __typename: "BroadcastReceipt",
    id: string,
    broadcastMessageId?: string | null,
    broadcastMessage?:  {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner: string,
    pushToken: string,
    deviceToken: string,
    sentAt?: string | null,
    status?: BroadcastReceiptStatus | null,
    message?: string | null,
    details?: string | null,
    deliveredAt?: string | null,
    errors?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBroadcastReceiptsQueryVariables = {
  filter?: ModelBroadcastReceiptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBroadcastReceiptsQuery = {
  listBroadcastReceipts?:  {
    __typename: "ModelBroadcastReceiptConnection",
    items:  Array< {
      __typename: "BroadcastReceipt",
      id: string,
      broadcastMessageId?: string | null,
      broadcastMessage?:  {
        __typename: "BroadcastMessage",
        id: string,
        modelType?: BroadcastMessageString | null,
        message: string,
        title?: string | null,
        originType?: BroadcastMessageOrigin | null,
        originId?: string | null,
        sentBy?: string | null,
        url?: string | null,
        extra?: string | null,
        targetedGroup?: UserGroups | null,
        targetedRole?: TeamMemberRole | null,
        targetedEventId?: string | null,
        targetedUsers?: Array< string | null > | null,
        users?: Array< string | null > | null,
        broadcastAt?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owner: string,
      pushToken: string,
      deviceToken: string,
      sentAt?: string | null,
      status?: BroadcastReceiptStatus | null,
      message?: string | null,
      details?: string | null,
      deliveredAt?: string | null,
      errors?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBroadcastReceiptsQueryVariables = {
  filter?: ModelBroadcastReceiptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBroadcastReceiptsQuery = {
  syncBroadcastReceipts?:  {
    __typename: "ModelBroadcastReceiptConnection",
    items:  Array< {
      __typename: "BroadcastReceipt",
      id: string,
      broadcastMessageId?: string | null,
      broadcastMessage?:  {
        __typename: "BroadcastMessage",
        id: string,
        modelType?: BroadcastMessageString | null,
        message: string,
        title?: string | null,
        originType?: BroadcastMessageOrigin | null,
        originId?: string | null,
        sentBy?: string | null,
        url?: string | null,
        extra?: string | null,
        targetedGroup?: UserGroups | null,
        targetedRole?: TeamMemberRole | null,
        targetedEventId?: string | null,
        targetedUsers?: Array< string | null > | null,
        users?: Array< string | null > | null,
        broadcastAt?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owner: string,
      pushToken: string,
      deviceToken: string,
      sentAt?: string | null,
      status?: BroadcastReceiptStatus | null,
      message?: string | null,
      details?: string | null,
      deliveredAt?: string | null,
      errors?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetExperimentQueryVariables = {
  owner: string,
  experiment: Experiments,
};

export type GetExperimentQuery = {
  getExperiment?:  {
    __typename: "Experiment",
    owner: string,
    experiment: Experiments,
    enabled: boolean,
    extra?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListExperimentsQueryVariables = {
  owner?: string | null,
  experiment?: ModelStringKeyConditionInput | null,
  filter?: ModelExperimentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListExperimentsQuery = {
  listExperiments?:  {
    __typename: "ModelExperimentConnection",
    items:  Array< {
      __typename: "Experiment",
      owner: string,
      experiment: Experiments,
      enabled: boolean,
      extra?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncExperimentsQueryVariables = {
  filter?: ModelExperimentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncExperimentsQuery = {
  syncExperiments?:  {
    __typename: "ModelExperimentConnection",
    items:  Array< {
      __typename: "Experiment",
      owner: string,
      experiment: Experiments,
      enabled: boolean,
      extra?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPhotoAssetQueryVariables = {
  id: string,
};

export type GetPhotoAssetQuery = {
  getPhotoAsset?:  {
    __typename: "PhotoAsset",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    files?:  Array< {
      __typename: "PhotoObject",
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      soldAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
    } | null > | null,
    enquiryId?: string | null,
    passengers?: string | null,
    email?: string | null,
    flightId: string,
    flight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    salesAtEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    salesAfterEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    soldBy: string,
    emailSent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPhotoAssetsQueryVariables = {
  filter?: ModelPhotoAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPhotoAssetsQuery = {
  listPhotoAssets?:  {
    __typename: "ModelPhotoAssetConnection",
    items:  Array< {
      __typename: "PhotoAsset",
      id: string,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      files?:  Array< {
        __typename: "PhotoObject",
      } | null > | null,
      enquiryId?: string | null,
      passengers?: string | null,
      email?: string | null,
      flightId: string,
      flight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      salesAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      salesAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldBy: string,
      emailSent?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPhotoAssetsQueryVariables = {
  filter?: ModelPhotoAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPhotoAssetsQuery = {
  syncPhotoAssets?:  {
    __typename: "ModelPhotoAssetConnection",
    items:  Array< {
      __typename: "PhotoAsset",
      id: string,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      files?:  Array< {
        __typename: "PhotoObject",
      } | null > | null,
      enquiryId?: string | null,
      passengers?: string | null,
      email?: string | null,
      flightId: string,
      flight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      salesAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      salesAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldBy: string,
      emailSent?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListPhotoAssetsByEnquiryIdQueryVariables = {
  enquiryId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPhotoAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPhotoAssetsByEnquiryIdQuery = {
  listPhotoAssetsByEnquiryId?:  {
    __typename: "ModelPhotoAssetConnection",
    items:  Array< {
      __typename: "PhotoAsset",
      id: string,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      files?:  Array< {
        __typename: "PhotoObject",
      } | null > | null,
      enquiryId?: string | null,
      passengers?: string | null,
      email?: string | null,
      flightId: string,
      flight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      salesAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      salesAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldBy: string,
      emailSent?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListPhotoAssetsByEventQueryVariables = {
  eventId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPhotoAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPhotoAssetsByEventQuery = {
  listPhotoAssetsByEvent?:  {
    __typename: "ModelPhotoAssetConnection",
    items:  Array< {
      __typename: "PhotoAsset",
      id: string,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      files?:  Array< {
        __typename: "PhotoObject",
      } | null > | null,
      enquiryId?: string | null,
      passengers?: string | null,
      email?: string | null,
      flightId: string,
      flight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      salesAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      salesAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldBy: string,
      emailSent?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchPhotoAssetsQueryVariables = {
  filter?: SearchablePhotoAssetFilterInput | null,
  sort?: Array< SearchablePhotoAssetSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePhotoAssetAggregationInput | null > | null,
};

export type SearchPhotoAssetsQuery = {
  searchPhotoAssets?:  {
    __typename: "SearchablePhotoAssetConnection",
    items:  Array< {
      __typename: "PhotoAsset",
      id: string,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      files?:  Array< {
        __typename: "PhotoObject",
      } | null > | null,
      enquiryId?: string | null,
      passengers?: string | null,
      email?: string | null,
      flightId: string,
      flight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      eventId: string,
      event?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      salesAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      salesAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldBy: string,
      emailSent?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetSafetyCommQueryVariables = {
  id: string,
};

export type GetSafetyCommQuery = {
  getSafetyComm?:  {
    __typename: "SafetyComm",
    id: string,
    title: string,
    modelType: SafetyCommString,
    url?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    publishedBy?: string | null,
    publishedAt: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSafetyCommsQueryVariables = {
  filter?: ModelSafetyCommFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSafetyCommsQuery = {
  listSafetyComms?:  {
    __typename: "ModelSafetyCommConnection",
    items:  Array< {
      __typename: "SafetyComm",
      id: string,
      title: string,
      modelType: SafetyCommString,
      url?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      publishedBy?: string | null,
      publishedAt: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSafetyCommsQueryVariables = {
  filter?: ModelSafetyCommFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSafetyCommsQuery = {
  syncSafetyComms?:  {
    __typename: "ModelSafetyCommConnection",
    items:  Array< {
      __typename: "SafetyComm",
      id: string,
      title: string,
      modelType: SafetyCommString,
      url?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      publishedBy?: string | null,
      publishedAt: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListSafetyCommsByPublishedAtQueryVariables = {
  modelType: SafetyCommString,
  publishedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSafetyCommFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSafetyCommsByPublishedAtQuery = {
  listSafetyCommsByPublishedAt?:  {
    __typename: "ModelSafetyCommConnection",
    items:  Array< {
      __typename: "SafetyComm",
      id: string,
      title: string,
      modelType: SafetyCommString,
      url?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      publishedBy?: string | null,
      publishedAt: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnWatchFlightByEventIdSubscriptionVariables = {
  eventId: string,
};

export type OnWatchFlightByEventIdSubscription = {
  onWatchFlightByEventId?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFlightByEventIdSubscriptionVariables = {
  eventId: string,
};

export type OnCreateFlightByEventIdSubscription = {
  onCreateFlightByEventId?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFlightByEventIdSubscriptionVariables = {
  eventId: string,
};

export type OnUpdateFlightByEventIdSubscription = {
  onUpdateFlightByEventId?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEventByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateEventByIdSubscription = {
  onUpdateEventById?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type OnCreateUpdateEventSubscriptionVariables = {
};

export type OnCreateUpdateEventSubscription = {
  onCreateUpdateEvent?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type OnCreateUpdateEventByUserSubscriptionVariables = {
  users: Array< string >,
};

export type OnCreateUpdateEventByUserSubscription = {
  onCreateUpdateEventByUser?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type OnUpdateFlightLegByEventIdSubscriptionVariables = {
  eventId: string,
};

export type OnUpdateFlightLegByEventIdSubscription = {
  onUpdateFlightLegByEventId?:  {
    __typename: "FlightLeg",
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    order: number,
    venueId?: number | null,
    venueName?: string | null,
    venuePostcode?: string | null,
    empty?: boolean | null,
    departing?: string | null,
    fromVenueId?: number | null,
    fromVenueName?: string | null,
    fromVenuePostcode?: string | null,
    fromCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    arriving?: string | null,
    toVenueId?: number | null,
    toVenueName?: string | null,
    toVenuePostcode?: string | null,
    toCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    distance?: number | null,
    ete?: number | null,
    lat?: number | null,
    lng?: number | null,
    w3w?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    modelType?: TaskTypeString | null,
    id: string,
    type: EventType,
    oid: string,
    venue?: string | null,
    venueId?: number | null,
    regionId?: number | null,
    date: string,
    endDate?: string | null,
    pax?: number | null,
    product?: string | null,
    ref?: string | null,
    description?: string | null,
    notes?: string | null,
    overview?:  {
      __typename: "PleasureOverview",
      id: string,
      eventId: string,
      team?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      manifestItems?:  {
        __typename: "ModelManifestItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      paxBooked?: number | null,
      aircraftReg?: string | null,
      aircraftSeats?: number | null,
      fireName?: string | null,
      fireReg?: string | null,
      bowserName?: string | null,
      truckName?: string | null,
      truckReg?: string | null,
      counts?: string | null,
      teamLeaderId?: string | null,
      teamLeader?:  {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      disabledRoles?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentFlight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    currentRefuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    flights?:  {
      __typename: "ModelFlightConnection",
      items:  Array< {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    legs?:  {
      __typename: "ModelFlightLegConnection",
      items:  Array< {
        __typename: "FlightLeg",
        id: string,
        eventId: string,
        order: number,
        venueId?: number | null,
        venueName?: string | null,
        venuePostcode?: string | null,
        empty?: boolean | null,
        departing?: string | null,
        fromVenueId?: number | null,
        fromVenueName?: string | null,
        fromVenuePostcode?: string | null,
        arriving?: string | null,
        toVenueId?: number | null,
        toVenueName?: string | null,
        toVenuePostcode?: string | null,
        distance?: number | null,
        ete?: number | null,
        lat?: number | null,
        lng?: number | null,
        w3w?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    pilotName?: string | null,
    pilotConfirmed?: boolean | null,
    aircraftId?: number | null,
    aircraftType?: string | null,
    aircraftName?: string | null,
    aircraftSeats?: number | null,
    aircraft?: string | null,
    duration?: number | null,
    client?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventOverviewId?: string | null,
    eventCurrentFlightId?: string | null,
    eventCurrentRefuelRequestId?: string | null,
  } | null,
};

export type OnCreateFlightSubscriptionVariables = {
  filter?: ModelSubscriptionFlightFilterInput | null,
};

export type OnCreateFlightSubscription = {
  onCreateFlight?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFlightSubscriptionVariables = {
  filter?: ModelSubscriptionFlightFilterInput | null,
};

export type OnUpdateFlightSubscription = {
  onUpdateFlight?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFlightSubscriptionVariables = {
  filter?: ModelSubscriptionFlightFilterInput | null,
};

export type OnDeleteFlightSubscription = {
  onDeleteFlight?:  {
    __typename: "Flight",
    id: string,
    tupleId?: string | null,
    eventId: string,
    flightOrderId?: number | null,
    designator: PfFlightDesignator,
    sequence: number,
    flightNumber?: string | null,
    theme?: string | null,
    aircraftName?: string | null,
    isFlightFull?: boolean | null,
    scheduledDeparture?: string | null,
    actualDeparture?: string | null,
    estimatedArrival?: string | null,
    scheduledCheckIn?: string | null,
    status?: FlightStatus | null,
    duration?: number | null,
    passengers?:  {
      __typename: "ModelFlightPassengerConnection",
      items:  Array< {
        __typename: "FlightPassenger",
        id: string,
        eventId: string,
        tupleId?: string | null,
        enquiryId?: number | null,
        voucherId?: string | null,
        designator?: PfFlightDesignator | null,
        sequence?: number | null,
        flightNumber?: string | null,
        flightId?: string | null,
        number: number,
        passengerNumber?: number | null,
        forename: string,
        surname: string,
        weight: number,
        seat: PassengerSeat,
        phone?: string | null,
        email?: string | null,
        postcode?: string | null,
        bubbly?: boolean | null,
        creamTea?: boolean | null,
        lunch?: boolean | null,
        magnum?: boolean | null,
        chocolate?: boolean | null,
        photo?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users: Array< string >,
    updatedAt: string,
    lastUpdatedBy?: UpdateSource | null,
    createdAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateRefuelRequestSubscriptionVariables = {
  filter?: ModelSubscriptionRefuelRequestFilterInput | null,
};

export type OnCreateRefuelRequestSubscription = {
  onCreateRefuelRequest?:  {
    __typename: "RefuelRequest",
    id: string,
    eventId: string,
    requestedBy?: string | null,
    requestFrom?: TeamMemberRole | null,
    delay?: number | null,
    users: Array< string >,
    flightOrderId?: number | null,
    adventureId?: number | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRefuelRequestSubscriptionVariables = {
  filter?: ModelSubscriptionRefuelRequestFilterInput | null,
};

export type OnUpdateRefuelRequestSubscription = {
  onUpdateRefuelRequest?:  {
    __typename: "RefuelRequest",
    id: string,
    eventId: string,
    requestedBy?: string | null,
    requestFrom?: TeamMemberRole | null,
    delay?: number | null,
    users: Array< string >,
    flightOrderId?: number | null,
    adventureId?: number | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRefuelRequestSubscriptionVariables = {
  filter?: ModelSubscriptionRefuelRequestFilterInput | null,
};

export type OnDeleteRefuelRequestSubscription = {
  onDeleteRefuelRequest?:  {
    __typename: "RefuelRequest",
    id: string,
    eventId: string,
    requestedBy?: string | null,
    requestFrom?: TeamMemberRole | null,
    delay?: number | null,
    users: Array< string >,
    flightOrderId?: number | null,
    adventureId?: number | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateRefuelSubscriptionVariables = {
  filter?: ModelSubscriptionRefuelFilterInput | null,
};

export type OnCreateRefuelSubscription = {
  onCreateRefuel?:  {
    __typename: "Refuel",
    id: string,
    eventId: string,
    refuelRequestId?: string | null,
    refuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    users: Array< string >,
    createdAt: string,
    refueledAt: string,
    refueledBy?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRefuelSubscriptionVariables = {
  filter?: ModelSubscriptionRefuelFilterInput | null,
};

export type OnUpdateRefuelSubscription = {
  onUpdateRefuel?:  {
    __typename: "Refuel",
    id: string,
    eventId: string,
    refuelRequestId?: string | null,
    refuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    users: Array< string >,
    createdAt: string,
    refueledAt: string,
    refueledBy?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRefuelSubscriptionVariables = {
  filter?: ModelSubscriptionRefuelFilterInput | null,
};

export type OnDeleteRefuelSubscription = {
  onDeleteRefuel?:  {
    __typename: "Refuel",
    id: string,
    eventId: string,
    refuelRequestId?: string | null,
    refuelRequest?:  {
      __typename: "RefuelRequest",
      id: string,
      eventId: string,
      requestedBy?: string | null,
      requestFrom?: TeamMemberRole | null,
      delay?: number | null,
      users: Array< string >,
      flightOrderId?: number | null,
      adventureId?: number | null,
      aircraftId?: number | null,
      aircraftName?: string | null,
      litres?: number | null,
      fuelSource?:  {
        __typename: "FuelSource",
        provider?: FuellingSource | null,
        name?: string | null,
      } | null,
      fuelType?: FuelType | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    aircraftId?: number | null,
    aircraftName?: string | null,
    litres?: number | null,
    fuelSource?:  {
      __typename: "FuelSource",
      provider?: FuellingSource | null,
      name?: string | null,
    } | null,
    fuelType?: FuelType | null,
    users: Array< string >,
    createdAt: string,
    refueledAt: string,
    refueledBy?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFlightPassengerSubscriptionVariables = {
  filter?: ModelSubscriptionFlightPassengerFilterInput | null,
};

export type OnCreateFlightPassengerSubscription = {
  onCreateFlightPassenger?:  {
    __typename: "FlightPassenger",
    id: string,
    eventId: string,
    tupleId?: string | null,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    enquiryId?: number | null,
    voucherId?: string | null,
    designator?: PfFlightDesignator | null,
    sequence?: number | null,
    flightNumber?: string | null,
    flightId?: string | null,
    number: number,
    passengerNumber?: number | null,
    forename: string,
    surname: string,
    weight: number,
    seat: PassengerSeat,
    phone?: string | null,
    email?: string | null,
    postcode?: string | null,
    bubbly?: boolean | null,
    creamTea?: boolean | null,
    lunch?: boolean | null,
    magnum?: boolean | null,
    chocolate?: boolean | null,
    photo?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFlightPassengerSubscriptionVariables = {
  filter?: ModelSubscriptionFlightPassengerFilterInput | null,
};

export type OnUpdateFlightPassengerSubscription = {
  onUpdateFlightPassenger?:  {
    __typename: "FlightPassenger",
    id: string,
    eventId: string,
    tupleId?: string | null,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    enquiryId?: number | null,
    voucherId?: string | null,
    designator?: PfFlightDesignator | null,
    sequence?: number | null,
    flightNumber?: string | null,
    flightId?: string | null,
    number: number,
    passengerNumber?: number | null,
    forename: string,
    surname: string,
    weight: number,
    seat: PassengerSeat,
    phone?: string | null,
    email?: string | null,
    postcode?: string | null,
    bubbly?: boolean | null,
    creamTea?: boolean | null,
    lunch?: boolean | null,
    magnum?: boolean | null,
    chocolate?: boolean | null,
    photo?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFlightPassengerSubscriptionVariables = {
  filter?: ModelSubscriptionFlightPassengerFilterInput | null,
};

export type OnDeleteFlightPassengerSubscription = {
  onDeleteFlightPassenger?:  {
    __typename: "FlightPassenger",
    id: string,
    eventId: string,
    tupleId?: string | null,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    enquiryId?: number | null,
    voucherId?: string | null,
    designator?: PfFlightDesignator | null,
    sequence?: number | null,
    flightNumber?: string | null,
    flightId?: string | null,
    number: number,
    passengerNumber?: number | null,
    forename: string,
    surname: string,
    weight: number,
    seat: PassengerSeat,
    phone?: string | null,
    email?: string | null,
    postcode?: string | null,
    bubbly?: boolean | null,
    creamTea?: boolean | null,
    lunch?: boolean | null,
    magnum?: boolean | null,
    chocolate?: boolean | null,
    photo?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePleasureOverviewSubscriptionVariables = {
  filter?: ModelSubscriptionPleasureOverviewFilterInput | null,
};

export type OnCreatePleasureOverviewSubscription = {
  onCreatePleasureOverview?:  {
    __typename: "PleasureOverview",
    id: string,
    eventId: string,
    team?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    manifestItems?:  {
      __typename: "ModelManifestItemConnection",
      items:  Array< {
        __typename: "ManifestItem",
        id: string,
        overviewId: string,
        eventId?: string | null,
        time?: string | null,
        duration?: number | null,
        designator?: PfFlightDesignator | null,
        pax?: number | null,
        weight?: number | null,
        voucherIds?: Array< string | null > | null,
        frontSeatTaken?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    paxBooked?: number | null,
    aircraftReg?: string | null,
    aircraftSeats?: number | null,
    fireName?: string | null,
    fireReg?: string | null,
    bowserName?: string | null,
    truckName?: string | null,
    truckReg?: string | null,
    counts?: string | null,
    teamLeaderId?: string | null,
    teamLeader?:  {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    disabledRoles?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePleasureOverviewSubscriptionVariables = {
  filter?: ModelSubscriptionPleasureOverviewFilterInput | null,
};

export type OnUpdatePleasureOverviewSubscription = {
  onUpdatePleasureOverview?:  {
    __typename: "PleasureOverview",
    id: string,
    eventId: string,
    team?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    manifestItems?:  {
      __typename: "ModelManifestItemConnection",
      items:  Array< {
        __typename: "ManifestItem",
        id: string,
        overviewId: string,
        eventId?: string | null,
        time?: string | null,
        duration?: number | null,
        designator?: PfFlightDesignator | null,
        pax?: number | null,
        weight?: number | null,
        voucherIds?: Array< string | null > | null,
        frontSeatTaken?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    paxBooked?: number | null,
    aircraftReg?: string | null,
    aircraftSeats?: number | null,
    fireName?: string | null,
    fireReg?: string | null,
    bowserName?: string | null,
    truckName?: string | null,
    truckReg?: string | null,
    counts?: string | null,
    teamLeaderId?: string | null,
    teamLeader?:  {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    disabledRoles?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePleasureOverviewSubscriptionVariables = {
  filter?: ModelSubscriptionPleasureOverviewFilterInput | null,
};

export type OnDeletePleasureOverviewSubscription = {
  onDeletePleasureOverview?:  {
    __typename: "PleasureOverview",
    id: string,
    eventId: string,
    team?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        overviewId?: string | null,
        eventId?: string | null,
        name?: string | null,
        forename?: string | null,
        surname?: string | null,
        teamResourceId?: number | null,
        teamRole?: string | null,
        phone?: string | null,
        confirmed?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    manifestItems?:  {
      __typename: "ModelManifestItemConnection",
      items:  Array< {
        __typename: "ManifestItem",
        id: string,
        overviewId: string,
        eventId?: string | null,
        time?: string | null,
        duration?: number | null,
        designator?: PfFlightDesignator | null,
        pax?: number | null,
        weight?: number | null,
        voucherIds?: Array< string | null > | null,
        frontSeatTaken?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    paxBooked?: number | null,
    aircraftReg?: string | null,
    aircraftSeats?: number | null,
    fireName?: string | null,
    fireReg?: string | null,
    bowserName?: string | null,
    truckName?: string | null,
    truckReg?: string | null,
    counts?: string | null,
    teamLeaderId?: string | null,
    teamLeader?:  {
      __typename: "TeamMember",
      id: string,
      overviewId?: string | null,
      eventId?: string | null,
      name?: string | null,
      forename?: string | null,
      surname?: string | null,
      teamResourceId?: number | null,
      teamRole?: string | null,
      phone?: string | null,
      confirmed?: boolean | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    disabledRoles?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTeamMemberSubscriptionVariables = {
  filter?: ModelSubscriptionTeamMemberFilterInput | null,
};

export type OnCreateTeamMemberSubscription = {
  onCreateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    overviewId?: string | null,
    eventId?: string | null,
    name?: string | null,
    forename?: string | null,
    surname?: string | null,
    teamResourceId?: number | null,
    teamRole?: string | null,
    phone?: string | null,
    confirmed?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTeamMemberSubscriptionVariables = {
  filter?: ModelSubscriptionTeamMemberFilterInput | null,
};

export type OnUpdateTeamMemberSubscription = {
  onUpdateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    overviewId?: string | null,
    eventId?: string | null,
    name?: string | null,
    forename?: string | null,
    surname?: string | null,
    teamResourceId?: number | null,
    teamRole?: string | null,
    phone?: string | null,
    confirmed?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTeamMemberSubscriptionVariables = {
  filter?: ModelSubscriptionTeamMemberFilterInput | null,
};

export type OnDeleteTeamMemberSubscription = {
  onDeleteTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    overviewId?: string | null,
    eventId?: string | null,
    name?: string | null,
    forename?: string | null,
    surname?: string | null,
    teamResourceId?: number | null,
    teamRole?: string | null,
    phone?: string | null,
    confirmed?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateManifestItemSubscriptionVariables = {
  filter?: ModelSubscriptionManifestItemFilterInput | null,
};

export type OnCreateManifestItemSubscription = {
  onCreateManifestItem?:  {
    __typename: "ManifestItem",
    id: string,
    overviewId: string,
    eventId?: string | null,
    time?: string | null,
    duration?: number | null,
    designator?: PfFlightDesignator | null,
    pax?: number | null,
    weight?: number | null,
    voucherIds?: Array< string | null > | null,
    frontSeatTaken?: boolean | null,
    passengers?:  Array< {
      __typename: "ManifestPassenger",
      title?: string | null,
      forename?: string | null,
      surname?: string | null,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      weight?: number | null,
      enquiryId?: string | null,
      voucherId?: string | null,
      frontSeat?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateManifestItemSubscriptionVariables = {
  filter?: ModelSubscriptionManifestItemFilterInput | null,
};

export type OnUpdateManifestItemSubscription = {
  onUpdateManifestItem?:  {
    __typename: "ManifestItem",
    id: string,
    overviewId: string,
    eventId?: string | null,
    time?: string | null,
    duration?: number | null,
    designator?: PfFlightDesignator | null,
    pax?: number | null,
    weight?: number | null,
    voucherIds?: Array< string | null > | null,
    frontSeatTaken?: boolean | null,
    passengers?:  Array< {
      __typename: "ManifestPassenger",
      title?: string | null,
      forename?: string | null,
      surname?: string | null,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      weight?: number | null,
      enquiryId?: string | null,
      voucherId?: string | null,
      frontSeat?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteManifestItemSubscriptionVariables = {
  filter?: ModelSubscriptionManifestItemFilterInput | null,
};

export type OnDeleteManifestItemSubscription = {
  onDeleteManifestItem?:  {
    __typename: "ManifestItem",
    id: string,
    overviewId: string,
    eventId?: string | null,
    time?: string | null,
    duration?: number | null,
    designator?: PfFlightDesignator | null,
    pax?: number | null,
    weight?: number | null,
    voucherIds?: Array< string | null > | null,
    frontSeatTaken?: boolean | null,
    passengers?:  Array< {
      __typename: "ManifestPassenger",
      title?: string | null,
      forename?: string | null,
      surname?: string | null,
      phone?: string | null,
      email?: string | null,
      postcode?: string | null,
      weight?: number | null,
      enquiryId?: string | null,
      voucherId?: string | null,
      frontSeat?: boolean | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFollowingLogSubscriptionVariables = {
  filter?: ModelSubscriptionFollowingLogFilterInput | null,
};

export type OnCreateFollowingLogSubscription = {
  onCreateFollowingLog?:  {
    __typename: "FollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFollowingLogSubscriptionVariables = {
  filter?: ModelSubscriptionFollowingLogFilterInput | null,
};

export type OnUpdateFollowingLogSubscription = {
  onUpdateFollowingLog?:  {
    __typename: "FollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFollowingLogSubscriptionVariables = {
  filter?: ModelSubscriptionFollowingLogFilterInput | null,
};

export type OnDeleteFollowingLogSubscription = {
  onDeleteFollowingLog?:  {
    __typename: "FollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAzureFollowingLogSubscriptionVariables = {
  filter?: ModelSubscriptionAzureFollowingLogFilterInput | null,
};

export type OnCreateAzureFollowingLogSubscription = {
  onCreateAzureFollowingLog?:  {
    __typename: "AzureFollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAzureFollowingLogSubscriptionVariables = {
  filter?: ModelSubscriptionAzureFollowingLogFilterInput | null,
};

export type OnUpdateAzureFollowingLogSubscription = {
  onUpdateAzureFollowingLog?:  {
    __typename: "AzureFollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAzureFollowingLogSubscriptionVariables = {
  filter?: ModelSubscriptionAzureFollowingLogFilterInput | null,
};

export type OnDeleteAzureFollowingLogSubscription = {
  onDeleteAzureFollowingLog?:  {
    __typename: "AzureFollowingLog",
    modelType: FollowingLogString,
    id: string,
    user: string,
    pilotName?: string | null,
    type: EventType,
    notes?: string | null,
    eventId?: string | null,
    originId: string,
    originType: FollowingLogOrigin,
    aircraft?: string | null,
    aircraftType?: string | null,
    pob?: number | null,
    action?: FollowingAction | null,
    origin?: string | null,
    destination?: string | null,
    d_lat?: string | null,
    d_lng?: string | null,
    accuracy?: number | null,
    altitude?: number | null,
    altitudeAccuracy?: number | null,
    lat?: string | null,
    lng?: string | null,
    eta?: number | null,
    ete?: number | null,
    gs?: number | null,
    hdg?: number | null,
    battery?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFlightLegSubscriptionVariables = {
  filter?: ModelSubscriptionFlightLegFilterInput | null,
};

export type OnCreateFlightLegSubscription = {
  onCreateFlightLeg?:  {
    __typename: "FlightLeg",
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    order: number,
    venueId?: number | null,
    venueName?: string | null,
    venuePostcode?: string | null,
    empty?: boolean | null,
    departing?: string | null,
    fromVenueId?: number | null,
    fromVenueName?: string | null,
    fromVenuePostcode?: string | null,
    fromCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    arriving?: string | null,
    toVenueId?: number | null,
    toVenueName?: string | null,
    toVenuePostcode?: string | null,
    toCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    distance?: number | null,
    ete?: number | null,
    lat?: number | null,
    lng?: number | null,
    w3w?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFlightLegSubscriptionVariables = {
  filter?: ModelSubscriptionFlightLegFilterInput | null,
};

export type OnUpdateFlightLegSubscription = {
  onUpdateFlightLeg?:  {
    __typename: "FlightLeg",
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    order: number,
    venueId?: number | null,
    venueName?: string | null,
    venuePostcode?: string | null,
    empty?: boolean | null,
    departing?: string | null,
    fromVenueId?: number | null,
    fromVenueName?: string | null,
    fromVenuePostcode?: string | null,
    fromCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    arriving?: string | null,
    toVenueId?: number | null,
    toVenueName?: string | null,
    toVenuePostcode?: string | null,
    toCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    distance?: number | null,
    ete?: number | null,
    lat?: number | null,
    lng?: number | null,
    w3w?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFlightLegSubscriptionVariables = {
  filter?: ModelSubscriptionFlightLegFilterInput | null,
};

export type OnDeleteFlightLegSubscription = {
  onDeleteFlightLeg?:  {
    __typename: "FlightLeg",
    id: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    order: number,
    venueId?: number | null,
    venueName?: string | null,
    venuePostcode?: string | null,
    empty?: boolean | null,
    departing?: string | null,
    fromVenueId?: number | null,
    fromVenueName?: string | null,
    fromVenuePostcode?: string | null,
    fromCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    arriving?: string | null,
    toVenueId?: number | null,
    toVenueName?: string | null,
    toVenuePostcode?: string | null,
    toCoords?:  {
      __typename: "Coords",
      lat?: number | null,
      lng?: number | null,
    } | null,
    distance?: number | null,
    ete?: number | null,
    lat?: number | null,
    lng?: number | null,
    w3w?: string | null,
    status?: EventStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePushSubscriptionSubscriptionVariables = {
  filter?: ModelSubscriptionPushSubscriptionFilterInput | null,
};

export type OnCreatePushSubscriptionSubscription = {
  onCreatePushSubscription?:  {
    __typename: "PushSubscription",
    user: string,
    channel: PushChannel,
    deviceToken: string,
    deviceType?: string | null,
    expoPushToken: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePushSubscriptionSubscriptionVariables = {
  filter?: ModelSubscriptionPushSubscriptionFilterInput | null,
};

export type OnUpdatePushSubscriptionSubscription = {
  onUpdatePushSubscription?:  {
    __typename: "PushSubscription",
    user: string,
    channel: PushChannel,
    deviceToken: string,
    deviceType?: string | null,
    expoPushToken: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePushSubscriptionSubscriptionVariables = {
  filter?: ModelSubscriptionPushSubscriptionFilterInput | null,
};

export type OnDeletePushSubscriptionSubscription = {
  onDeletePushSubscription?:  {
    __typename: "PushSubscription",
    user: string,
    channel: PushChannel,
    deviceToken: string,
    deviceType?: string | null,
    expoPushToken: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSettingSubscriptionVariables = {
  filter?: ModelSubscriptionUserSettingFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSettingSubscription = {
  onCreateUserSetting?:  {
    __typename: "UserSetting",
    teamLeaderRegionId?: number | null,
    teamLeaderRegionIds?: Array< number | null > | null,
    teamResourceId?: number | null,
    owner: string,
    profileImage?: string | null,
    callsign?: string | null,
    calCode?: string | null,
    appVersion?: string | null,
    weightDisplay?: WeightDisplay | null,
    accessibilityIcons?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSettingSubscriptionVariables = {
  filter?: ModelSubscriptionUserSettingFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSettingSubscription = {
  onUpdateUserSetting?:  {
    __typename: "UserSetting",
    teamLeaderRegionId?: number | null,
    teamLeaderRegionIds?: Array< number | null > | null,
    teamResourceId?: number | null,
    owner: string,
    profileImage?: string | null,
    callsign?: string | null,
    calCode?: string | null,
    appVersion?: string | null,
    weightDisplay?: WeightDisplay | null,
    accessibilityIcons?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSettingSubscriptionVariables = {
  filter?: ModelSubscriptionUserSettingFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSettingSubscription = {
  onDeleteUserSetting?:  {
    __typename: "UserSetting",
    teamLeaderRegionId?: number | null,
    teamLeaderRegionIds?: Array< number | null > | null,
    teamResourceId?: number | null,
    owner: string,
    profileImage?: string | null,
    callsign?: string | null,
    calCode?: string | null,
    appVersion?: string | null,
    weightDisplay?: WeightDisplay | null,
    accessibilityIcons?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCertificateSubscriptionVariables = {
  filter?: ModelSubscriptionCertificateFilterInput | null,
  owner?: string | null,
};

export type OnCreateCertificateSubscription = {
  onCreateCertificate?:  {
    __typename: "Certificate",
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCertificateSubscriptionVariables = {
  filter?: ModelSubscriptionCertificateFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCertificateSubscription = {
  onUpdateCertificate?:  {
    __typename: "Certificate",
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCertificateSubscriptionVariables = {
  filter?: ModelSubscriptionCertificateFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCertificateSubscription = {
  onDeleteCertificate?:  {
    __typename: "Certificate",
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserCertificateSubscriptionVariables = {
  filter?: ModelSubscriptionUserCertificateFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserCertificateSubscription = {
  onCreateUserCertificate?:  {
    __typename: "UserCertificate",
    key: string,
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserCertificateSubscriptionVariables = {
  filter?: ModelSubscriptionUserCertificateFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserCertificateSubscription = {
  onUpdateUserCertificate?:  {
    __typename: "UserCertificate",
    key: string,
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserCertificateSubscriptionVariables = {
  filter?: ModelSubscriptionUserCertificateFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserCertificateSubscription = {
  onDeleteUserCertificate?:  {
    __typename: "UserCertificate",
    key: string,
    owner: string,
    model: CertificateModel,
    type: CertificateType,
    number?: string | null,
    aircraft?: string | null,
    pdf?: string | null,
    expiresAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBroadcastMessageSubscriptionVariables = {
  filter?: ModelSubscriptionBroadcastMessageFilterInput | null,
};

export type OnCreateBroadcastMessageSubscription = {
  onCreateBroadcastMessage?:  {
    __typename: "BroadcastMessage",
    id: string,
    modelType?: BroadcastMessageString | null,
    message: string,
    title?: string | null,
    originType?: BroadcastMessageOrigin | null,
    originId?: string | null,
    sentBy?: string | null,
    url?: string | null,
    extra?: string | null,
    targetedGroup?: UserGroups | null,
    targetedRole?: TeamMemberRole | null,
    targetedEventId?: string | null,
    targetedEvent?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    targetedUsers?: Array< string | null > | null,
    users?: Array< string | null > | null,
    broadcastAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBroadcastMessageSubscriptionVariables = {
  filter?: ModelSubscriptionBroadcastMessageFilterInput | null,
};

export type OnUpdateBroadcastMessageSubscription = {
  onUpdateBroadcastMessage?:  {
    __typename: "BroadcastMessage",
    id: string,
    modelType?: BroadcastMessageString | null,
    message: string,
    title?: string | null,
    originType?: BroadcastMessageOrigin | null,
    originId?: string | null,
    sentBy?: string | null,
    url?: string | null,
    extra?: string | null,
    targetedGroup?: UserGroups | null,
    targetedRole?: TeamMemberRole | null,
    targetedEventId?: string | null,
    targetedEvent?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    targetedUsers?: Array< string | null > | null,
    users?: Array< string | null > | null,
    broadcastAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBroadcastMessageSubscriptionVariables = {
  filter?: ModelSubscriptionBroadcastMessageFilterInput | null,
};

export type OnDeleteBroadcastMessageSubscription = {
  onDeleteBroadcastMessage?:  {
    __typename: "BroadcastMessage",
    id: string,
    modelType?: BroadcastMessageString | null,
    message: string,
    title?: string | null,
    originType?: BroadcastMessageOrigin | null,
    originId?: string | null,
    sentBy?: string | null,
    url?: string | null,
    extra?: string | null,
    targetedGroup?: UserGroups | null,
    targetedRole?: TeamMemberRole | null,
    targetedEventId?: string | null,
    targetedEvent?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    targetedUsers?: Array< string | null > | null,
    users?: Array< string | null > | null,
    broadcastAt?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBroadcastReceiptSubscriptionVariables = {
  filter?: ModelSubscriptionBroadcastReceiptFilterInput | null,
};

export type OnCreateBroadcastReceiptSubscription = {
  onCreateBroadcastReceipt?:  {
    __typename: "BroadcastReceipt",
    id: string,
    broadcastMessageId?: string | null,
    broadcastMessage?:  {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner: string,
    pushToken: string,
    deviceToken: string,
    sentAt?: string | null,
    status?: BroadcastReceiptStatus | null,
    message?: string | null,
    details?: string | null,
    deliveredAt?: string | null,
    errors?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBroadcastReceiptSubscriptionVariables = {
  filter?: ModelSubscriptionBroadcastReceiptFilterInput | null,
};

export type OnUpdateBroadcastReceiptSubscription = {
  onUpdateBroadcastReceipt?:  {
    __typename: "BroadcastReceipt",
    id: string,
    broadcastMessageId?: string | null,
    broadcastMessage?:  {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner: string,
    pushToken: string,
    deviceToken: string,
    sentAt?: string | null,
    status?: BroadcastReceiptStatus | null,
    message?: string | null,
    details?: string | null,
    deliveredAt?: string | null,
    errors?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBroadcastReceiptSubscriptionVariables = {
  filter?: ModelSubscriptionBroadcastReceiptFilterInput | null,
};

export type OnDeleteBroadcastReceiptSubscription = {
  onDeleteBroadcastReceipt?:  {
    __typename: "BroadcastReceipt",
    id: string,
    broadcastMessageId?: string | null,
    broadcastMessage?:  {
      __typename: "BroadcastMessage",
      id: string,
      modelType?: BroadcastMessageString | null,
      message: string,
      title?: string | null,
      originType?: BroadcastMessageOrigin | null,
      originId?: string | null,
      sentBy?: string | null,
      url?: string | null,
      extra?: string | null,
      targetedGroup?: UserGroups | null,
      targetedRole?: TeamMemberRole | null,
      targetedEventId?: string | null,
      targetedEvent?:  {
        __typename: "Event",
        modelType?: TaskTypeString | null,
        id: string,
        type: EventType,
        oid: string,
        venue?: string | null,
        venueId?: number | null,
        regionId?: number | null,
        date: string,
        endDate?: string | null,
        pax?: number | null,
        product?: string | null,
        ref?: string | null,
        description?: string | null,
        notes?: string | null,
        users: Array< string >,
        pilotName?: string | null,
        pilotConfirmed?: boolean | null,
        aircraftId?: number | null,
        aircraftType?: string | null,
        aircraftName?: string | null,
        aircraftSeats?: number | null,
        aircraft?: string | null,
        duration?: number | null,
        client?: string | null,
        status?: EventStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventOverviewId?: string | null,
        eventCurrentFlightId?: string | null,
        eventCurrentRefuelRequestId?: string | null,
      } | null,
      targetedUsers?: Array< string | null > | null,
      users?: Array< string | null > | null,
      broadcastAt?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner: string,
    pushToken: string,
    deviceToken: string,
    sentAt?: string | null,
    status?: BroadcastReceiptStatus | null,
    message?: string | null,
    details?: string | null,
    deliveredAt?: string | null,
    errors?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateExperimentSubscriptionVariables = {
  filter?: ModelSubscriptionExperimentFilterInput | null,
  owner?: string | null,
};

export type OnCreateExperimentSubscription = {
  onCreateExperiment?:  {
    __typename: "Experiment",
    owner: string,
    experiment: Experiments,
    enabled: boolean,
    extra?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateExperimentSubscriptionVariables = {
  filter?: ModelSubscriptionExperimentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateExperimentSubscription = {
  onUpdateExperiment?:  {
    __typename: "Experiment",
    owner: string,
    experiment: Experiments,
    enabled: boolean,
    extra?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteExperimentSubscriptionVariables = {
  filter?: ModelSubscriptionExperimentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteExperimentSubscription = {
  onDeleteExperiment?:  {
    __typename: "Experiment",
    owner: string,
    experiment: Experiments,
    enabled: boolean,
    extra?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePhotoAssetSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoAssetFilterInput | null,
};

export type OnCreatePhotoAssetSubscription = {
  onCreatePhotoAsset?:  {
    __typename: "PhotoAsset",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    files?:  Array< {
      __typename: "PhotoObject",
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      soldAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
    } | null > | null,
    enquiryId?: string | null,
    passengers?: string | null,
    email?: string | null,
    flightId: string,
    flight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    salesAtEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    salesAfterEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    soldBy: string,
    emailSent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePhotoAssetSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoAssetFilterInput | null,
};

export type OnUpdatePhotoAssetSubscription = {
  onUpdatePhotoAsset?:  {
    __typename: "PhotoAsset",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    files?:  Array< {
      __typename: "PhotoObject",
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      soldAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
    } | null > | null,
    enquiryId?: string | null,
    passengers?: string | null,
    email?: string | null,
    flightId: string,
    flight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    salesAtEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    salesAfterEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    soldBy: string,
    emailSent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePhotoAssetSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoAssetFilterInput | null,
};

export type OnDeletePhotoAssetSubscription = {
  onDeletePhotoAsset?:  {
    __typename: "PhotoAsset",
    id: string,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    files?:  Array< {
      __typename: "PhotoObject",
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      soldAtEvent?:  {
        __typename: "PhotoSales",
      } | null,
      soldAfterEvent?:  {
        __typename: "PhotoSales",
      } | null,
    } | null > | null,
    enquiryId?: string | null,
    passengers?: string | null,
    email?: string | null,
    flightId: string,
    flight?:  {
      __typename: "Flight",
      id: string,
      tupleId?: string | null,
      eventId: string,
      flightOrderId?: number | null,
      designator: PfFlightDesignator,
      sequence: number,
      flightNumber?: string | null,
      theme?: string | null,
      aircraftName?: string | null,
      isFlightFull?: boolean | null,
      scheduledDeparture?: string | null,
      actualDeparture?: string | null,
      estimatedArrival?: string | null,
      scheduledCheckIn?: string | null,
      status?: FlightStatus | null,
      duration?: number | null,
      passengers?:  {
        __typename: "ModelFlightPassengerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      updatedAt: string,
      lastUpdatedBy?: UpdateSource | null,
      createdAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    eventId: string,
    event?:  {
      __typename: "Event",
      modelType?: TaskTypeString | null,
      id: string,
      type: EventType,
      oid: string,
      venue?: string | null,
      venueId?: number | null,
      regionId?: number | null,
      date: string,
      endDate?: string | null,
      pax?: number | null,
      product?: string | null,
      ref?: string | null,
      description?: string | null,
      notes?: string | null,
      overview?:  {
        __typename: "PleasureOverview",
        id: string,
        eventId: string,
        paxBooked?: number | null,
        aircraftReg?: string | null,
        aircraftSeats?: number | null,
        fireName?: string | null,
        fireReg?: string | null,
        bowserName?: string | null,
        truckName?: string | null,
        truckReg?: string | null,
        counts?: string | null,
        teamLeaderId?: string | null,
        disabledRoles?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentFlight?:  {
        __typename: "Flight",
        id: string,
        tupleId?: string | null,
        eventId: string,
        flightOrderId?: number | null,
        designator: PfFlightDesignator,
        sequence: number,
        flightNumber?: string | null,
        theme?: string | null,
        aircraftName?: string | null,
        isFlightFull?: boolean | null,
        scheduledDeparture?: string | null,
        actualDeparture?: string | null,
        estimatedArrival?: string | null,
        scheduledCheckIn?: string | null,
        status?: FlightStatus | null,
        duration?: number | null,
        users: Array< string >,
        updatedAt: string,
        lastUpdatedBy?: UpdateSource | null,
        createdAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      currentRefuelRequest?:  {
        __typename: "RefuelRequest",
        id: string,
        eventId: string,
        requestedBy?: string | null,
        requestFrom?: TeamMemberRole | null,
        delay?: number | null,
        users: Array< string >,
        flightOrderId?: number | null,
        adventureId?: number | null,
        aircraftId?: number | null,
        aircraftName?: string | null,
        litres?: number | null,
        fuelType?: FuelType | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      flights?:  {
        __typename: "ModelFlightConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      legs?:  {
        __typename: "ModelFlightLegConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users: Array< string >,
      pilotName?: string | null,
      pilotConfirmed?: boolean | null,
      aircraftId?: number | null,
      aircraftType?: string | null,
      aircraftName?: string | null,
      aircraftSeats?: number | null,
      aircraft?: string | null,
      duration?: number | null,
      client?: string | null,
      status?: EventStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventOverviewId?: string | null,
      eventCurrentFlightId?: string | null,
      eventCurrentRefuelRequestId?: string | null,
    } | null,
    salesAtEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    salesAfterEvent?:  {
      __typename: "PhotoSales",
      physical?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
      digital?:  {
        __typename: "PhotoSale",
        copies?: number | null,
        delivered?: boolean | null,
      } | null,
    } | null,
    soldBy: string,
    emailSent?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSafetyCommSubscriptionVariables = {
  filter?: ModelSubscriptionSafetyCommFilterInput | null,
};

export type OnCreateSafetyCommSubscription = {
  onCreateSafetyComm?:  {
    __typename: "SafetyComm",
    id: string,
    title: string,
    modelType: SafetyCommString,
    url?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    publishedBy?: string | null,
    publishedAt: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSafetyCommSubscriptionVariables = {
  filter?: ModelSubscriptionSafetyCommFilterInput | null,
};

export type OnUpdateSafetyCommSubscription = {
  onUpdateSafetyComm?:  {
    __typename: "SafetyComm",
    id: string,
    title: string,
    modelType: SafetyCommString,
    url?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    publishedBy?: string | null,
    publishedAt: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSafetyCommSubscriptionVariables = {
  filter?: ModelSubscriptionSafetyCommFilterInput | null,
};

export type OnDeleteSafetyCommSubscription = {
  onDeleteSafetyComm?:  {
    __typename: "SafetyComm",
    id: string,
    title: string,
    modelType: SafetyCommString,
    url?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    publishedBy?: string | null,
    publishedAt: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
