/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const deleteBroadcastMessage = /* GraphQL */ `mutation DeleteBroadcastMessage(
  $input: DeleteBroadcastMessageInput!
  $condition: ModelBroadcastMessageConditionInput
) {
  deleteBroadcastMessage(input: $input, condition: $condition) {
    id
    modelType
    message
    title
    originType
    originId
    sentBy
    url
    extra
    targetedGroup
    targetedRole
    targetedEventId
    targetedEvent {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    targetedUsers
    users
    broadcastAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBroadcastMessageMutationVariables,
  APITypes.DeleteBroadcastMessageMutation
>;
export const createEvent = /* GraphQL */ `mutation CreateEvent(
  $input: CreateEventInput!
  $condition: ModelEventConditionInput
) {
  createEvent(input: $input, condition: $condition) {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventMutationVariables,
  APITypes.CreateEventMutation
>;
export const updateEvent = /* GraphQL */ `mutation UpdateEvent(
  $input: UpdateEventInput!
  $condition: ModelEventConditionInput
) {
  updateEvent(input: $input, condition: $condition) {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventMutationVariables,
  APITypes.UpdateEventMutation
>;
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
) {
  deleteEvent(input: $input, condition: $condition) {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventMutationVariables,
  APITypes.DeleteEventMutation
>;
export const createPleasureOverview = /* GraphQL */ `mutation CreatePleasureOverview(
  $input: CreatePleasureOverviewInput!
  $condition: ModelPleasureOverviewConditionInput
) {
  createPleasureOverview(input: $input, condition: $condition) {
    id
    eventId
    team {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    manifestItems {
      items {
        id
        overviewId
        eventId
        time
        duration
        designator
        pax
        weight
        voucherIds
        frontSeatTaken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    paxBooked
    aircraftReg
    aircraftSeats
    fireName
    fireReg
    bowserName
    truckName
    truckReg
    counts
    teamLeaderId
    teamLeader {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    disabledRoles
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePleasureOverviewMutationVariables,
  APITypes.CreatePleasureOverviewMutation
>;
export const updatePleasureOverview = /* GraphQL */ `mutation UpdatePleasureOverview(
  $input: UpdatePleasureOverviewInput!
  $condition: ModelPleasureOverviewConditionInput
) {
  updatePleasureOverview(input: $input, condition: $condition) {
    id
    eventId
    team {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    manifestItems {
      items {
        id
        overviewId
        eventId
        time
        duration
        designator
        pax
        weight
        voucherIds
        frontSeatTaken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    paxBooked
    aircraftReg
    aircraftSeats
    fireName
    fireReg
    bowserName
    truckName
    truckReg
    counts
    teamLeaderId
    teamLeader {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    disabledRoles
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePleasureOverviewMutationVariables,
  APITypes.UpdatePleasureOverviewMutation
>;
export const deletePleasureOverview = /* GraphQL */ `mutation DeletePleasureOverview(
  $input: DeletePleasureOverviewInput!
  $condition: ModelPleasureOverviewConditionInput
) {
  deletePleasureOverview(input: $input, condition: $condition) {
    id
    eventId
    team {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    manifestItems {
      items {
        id
        overviewId
        eventId
        time
        duration
        designator
        pax
        weight
        voucherIds
        frontSeatTaken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    paxBooked
    aircraftReg
    aircraftSeats
    fireName
    fireReg
    bowserName
    truckName
    truckReg
    counts
    teamLeaderId
    teamLeader {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    disabledRoles
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePleasureOverviewMutationVariables,
  APITypes.DeletePleasureOverviewMutation
>;
export const createTeamMember = /* GraphQL */ `mutation CreateTeamMember(
  $input: CreateTeamMemberInput!
  $condition: ModelTeamMemberConditionInput
) {
  createTeamMember(input: $input, condition: $condition) {
    id
    overviewId
    eventId
    name
    forename
    surname
    teamResourceId
    teamRole
    phone
    confirmed
    owner
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamMemberMutationVariables,
  APITypes.CreateTeamMemberMutation
>;
export const updateTeamMember = /* GraphQL */ `mutation UpdateTeamMember(
  $input: UpdateTeamMemberInput!
  $condition: ModelTeamMemberConditionInput
) {
  updateTeamMember(input: $input, condition: $condition) {
    id
    overviewId
    eventId
    name
    forename
    surname
    teamResourceId
    teamRole
    phone
    confirmed
    owner
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamMemberMutationVariables,
  APITypes.UpdateTeamMemberMutation
>;
export const deleteTeamMember = /* GraphQL */ `mutation DeleteTeamMember(
  $input: DeleteTeamMemberInput!
  $condition: ModelTeamMemberConditionInput
) {
  deleteTeamMember(input: $input, condition: $condition) {
    id
    overviewId
    eventId
    name
    forename
    surname
    teamResourceId
    teamRole
    phone
    confirmed
    owner
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeamMemberMutationVariables,
  APITypes.DeleteTeamMemberMutation
>;
export const createManifestItem = /* GraphQL */ `mutation CreateManifestItem(
  $input: CreateManifestItemInput!
  $condition: ModelManifestItemConditionInput
) {
  createManifestItem(input: $input, condition: $condition) {
    id
    overviewId
    eventId
    time
    duration
    designator
    pax
    weight
    voucherIds
    frontSeatTaken
    passengers {
      title
      forename
      surname
      phone
      email
      postcode
      weight
      enquiryId
      voucherId
      frontSeat
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateManifestItemMutationVariables,
  APITypes.CreateManifestItemMutation
>;
export const updateManifestItem = /* GraphQL */ `mutation UpdateManifestItem(
  $input: UpdateManifestItemInput!
  $condition: ModelManifestItemConditionInput
) {
  updateManifestItem(input: $input, condition: $condition) {
    id
    overviewId
    eventId
    time
    duration
    designator
    pax
    weight
    voucherIds
    frontSeatTaken
    passengers {
      title
      forename
      surname
      phone
      email
      postcode
      weight
      enquiryId
      voucherId
      frontSeat
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateManifestItemMutationVariables,
  APITypes.UpdateManifestItemMutation
>;
export const deleteManifestItem = /* GraphQL */ `mutation DeleteManifestItem(
  $input: DeleteManifestItemInput!
  $condition: ModelManifestItemConditionInput
) {
  deleteManifestItem(input: $input, condition: $condition) {
    id
    overviewId
    eventId
    time
    duration
    designator
    pax
    weight
    voucherIds
    frontSeatTaken
    passengers {
      title
      forename
      surname
      phone
      email
      postcode
      weight
      enquiryId
      voucherId
      frontSeat
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteManifestItemMutationVariables,
  APITypes.DeleteManifestItemMutation
>;
export const createFlight = /* GraphQL */ `mutation CreateFlight(
  $input: CreateFlightInput!
  $condition: ModelFlightConditionInput
) {
  createFlight(input: $input, condition: $condition) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlightMutationVariables,
  APITypes.CreateFlightMutation
>;
export const updateFlight = /* GraphQL */ `mutation UpdateFlight(
  $input: UpdateFlightInput!
  $condition: ModelFlightConditionInput
) {
  updateFlight(input: $input, condition: $condition) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlightMutationVariables,
  APITypes.UpdateFlightMutation
>;
export const deleteFlight = /* GraphQL */ `mutation DeleteFlight(
  $input: DeleteFlightInput!
  $condition: ModelFlightConditionInput
) {
  deleteFlight(input: $input, condition: $condition) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlightMutationVariables,
  APITypes.DeleteFlightMutation
>;
export const createRefuelRequest = /* GraphQL */ `mutation CreateRefuelRequest(
  $input: CreateRefuelRequestInput!
  $condition: ModelRefuelRequestConditionInput
) {
  createRefuelRequest(input: $input, condition: $condition) {
    id
    eventId
    requestedBy
    requestFrom
    delay
    users
    flightOrderId
    adventureId
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRefuelRequestMutationVariables,
  APITypes.CreateRefuelRequestMutation
>;
export const updateRefuelRequest = /* GraphQL */ `mutation UpdateRefuelRequest(
  $input: UpdateRefuelRequestInput!
  $condition: ModelRefuelRequestConditionInput
) {
  updateRefuelRequest(input: $input, condition: $condition) {
    id
    eventId
    requestedBy
    requestFrom
    delay
    users
    flightOrderId
    adventureId
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRefuelRequestMutationVariables,
  APITypes.UpdateRefuelRequestMutation
>;
export const deleteRefuelRequest = /* GraphQL */ `mutation DeleteRefuelRequest(
  $input: DeleteRefuelRequestInput!
  $condition: ModelRefuelRequestConditionInput
) {
  deleteRefuelRequest(input: $input, condition: $condition) {
    id
    eventId
    requestedBy
    requestFrom
    delay
    users
    flightOrderId
    adventureId
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRefuelRequestMutationVariables,
  APITypes.DeleteRefuelRequestMutation
>;
export const createRefuel = /* GraphQL */ `mutation CreateRefuel(
  $input: CreateRefuelInput!
  $condition: ModelRefuelConditionInput
) {
  createRefuel(input: $input, condition: $condition) {
    id
    eventId
    refuelRequestId
    refuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    users
    createdAt
    refueledAt
    refueledBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRefuelMutationVariables,
  APITypes.CreateRefuelMutation
>;
export const updateRefuel = /* GraphQL */ `mutation UpdateRefuel(
  $input: UpdateRefuelInput!
  $condition: ModelRefuelConditionInput
) {
  updateRefuel(input: $input, condition: $condition) {
    id
    eventId
    refuelRequestId
    refuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    users
    createdAt
    refueledAt
    refueledBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRefuelMutationVariables,
  APITypes.UpdateRefuelMutation
>;
export const deleteRefuel = /* GraphQL */ `mutation DeleteRefuel(
  $input: DeleteRefuelInput!
  $condition: ModelRefuelConditionInput
) {
  deleteRefuel(input: $input, condition: $condition) {
    id
    eventId
    refuelRequestId
    refuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    users
    createdAt
    refueledAt
    refueledBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRefuelMutationVariables,
  APITypes.DeleteRefuelMutation
>;
export const createFlightPassenger = /* GraphQL */ `mutation CreateFlightPassenger(
  $input: CreateFlightPassengerInput!
  $condition: ModelFlightPassengerConditionInput
) {
  createFlightPassenger(input: $input, condition: $condition) {
    id
    eventId
    tupleId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    enquiryId
    voucherId
    designator
    sequence
    flightNumber
    flightId
    number
    passengerNumber
    forename
    surname
    weight
    seat
    phone
    email
    postcode
    bubbly
    creamTea
    lunch
    magnum
    chocolate
    photo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlightPassengerMutationVariables,
  APITypes.CreateFlightPassengerMutation
>;
export const updateFlightPassenger = /* GraphQL */ `mutation UpdateFlightPassenger(
  $input: UpdateFlightPassengerInput!
  $condition: ModelFlightPassengerConditionInput
) {
  updateFlightPassenger(input: $input, condition: $condition) {
    id
    eventId
    tupleId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    enquiryId
    voucherId
    designator
    sequence
    flightNumber
    flightId
    number
    passengerNumber
    forename
    surname
    weight
    seat
    phone
    email
    postcode
    bubbly
    creamTea
    lunch
    magnum
    chocolate
    photo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlightPassengerMutationVariables,
  APITypes.UpdateFlightPassengerMutation
>;
export const deleteFlightPassenger = /* GraphQL */ `mutation DeleteFlightPassenger(
  $input: DeleteFlightPassengerInput!
  $condition: ModelFlightPassengerConditionInput
) {
  deleteFlightPassenger(input: $input, condition: $condition) {
    id
    eventId
    tupleId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    enquiryId
    voucherId
    designator
    sequence
    flightNumber
    flightId
    number
    passengerNumber
    forename
    surname
    weight
    seat
    phone
    email
    postcode
    bubbly
    creamTea
    lunch
    magnum
    chocolate
    photo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlightPassengerMutationVariables,
  APITypes.DeleteFlightPassengerMutation
>;
export const createFollowingLog = /* GraphQL */ `mutation CreateFollowingLog(
  $input: CreateFollowingLogInput!
  $condition: ModelFollowingLogConditionInput
) {
  createFollowingLog(input: $input, condition: $condition) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFollowingLogMutationVariables,
  APITypes.CreateFollowingLogMutation
>;
export const updateFollowingLog = /* GraphQL */ `mutation UpdateFollowingLog(
  $input: UpdateFollowingLogInput!
  $condition: ModelFollowingLogConditionInput
) {
  updateFollowingLog(input: $input, condition: $condition) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFollowingLogMutationVariables,
  APITypes.UpdateFollowingLogMutation
>;
export const deleteFollowingLog = /* GraphQL */ `mutation DeleteFollowingLog(
  $input: DeleteFollowingLogInput!
  $condition: ModelFollowingLogConditionInput
) {
  deleteFollowingLog(input: $input, condition: $condition) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFollowingLogMutationVariables,
  APITypes.DeleteFollowingLogMutation
>;
export const createAzureFollowingLog = /* GraphQL */ `mutation CreateAzureFollowingLog(
  $input: CreateAzureFollowingLogInput!
  $condition: ModelAzureFollowingLogConditionInput
) {
  createAzureFollowingLog(input: $input, condition: $condition) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAzureFollowingLogMutationVariables,
  APITypes.CreateAzureFollowingLogMutation
>;
export const updateAzureFollowingLog = /* GraphQL */ `mutation UpdateAzureFollowingLog(
  $input: UpdateAzureFollowingLogInput!
  $condition: ModelAzureFollowingLogConditionInput
) {
  updateAzureFollowingLog(input: $input, condition: $condition) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAzureFollowingLogMutationVariables,
  APITypes.UpdateAzureFollowingLogMutation
>;
export const deleteAzureFollowingLog = /* GraphQL */ `mutation DeleteAzureFollowingLog(
  $input: DeleteAzureFollowingLogInput!
  $condition: ModelAzureFollowingLogConditionInput
) {
  deleteAzureFollowingLog(input: $input, condition: $condition) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAzureFollowingLogMutationVariables,
  APITypes.DeleteAzureFollowingLogMutation
>;
export const createFlightLeg = /* GraphQL */ `mutation CreateFlightLeg(
  $input: CreateFlightLegInput!
  $condition: ModelFlightLegConditionInput
) {
  createFlightLeg(input: $input, condition: $condition) {
    id
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    order
    venueId
    venueName
    venuePostcode
    empty
    departing
    fromVenueId
    fromVenueName
    fromVenuePostcode
    fromCoords {
      lat
      lng
      __typename
    }
    arriving
    toVenueId
    toVenueName
    toVenuePostcode
    toCoords {
      lat
      lng
      __typename
    }
    distance
    ete
    lat
    lng
    w3w
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlightLegMutationVariables,
  APITypes.CreateFlightLegMutation
>;
export const updateFlightLeg = /* GraphQL */ `mutation UpdateFlightLeg(
  $input: UpdateFlightLegInput!
  $condition: ModelFlightLegConditionInput
) {
  updateFlightLeg(input: $input, condition: $condition) {
    id
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    order
    venueId
    venueName
    venuePostcode
    empty
    departing
    fromVenueId
    fromVenueName
    fromVenuePostcode
    fromCoords {
      lat
      lng
      __typename
    }
    arriving
    toVenueId
    toVenueName
    toVenuePostcode
    toCoords {
      lat
      lng
      __typename
    }
    distance
    ete
    lat
    lng
    w3w
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlightLegMutationVariables,
  APITypes.UpdateFlightLegMutation
>;
export const deleteFlightLeg = /* GraphQL */ `mutation DeleteFlightLeg(
  $input: DeleteFlightLegInput!
  $condition: ModelFlightLegConditionInput
) {
  deleteFlightLeg(input: $input, condition: $condition) {
    id
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    order
    venueId
    venueName
    venuePostcode
    empty
    departing
    fromVenueId
    fromVenueName
    fromVenuePostcode
    fromCoords {
      lat
      lng
      __typename
    }
    arriving
    toVenueId
    toVenueName
    toVenuePostcode
    toCoords {
      lat
      lng
      __typename
    }
    distance
    ete
    lat
    lng
    w3w
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlightLegMutationVariables,
  APITypes.DeleteFlightLegMutation
>;
export const createPushSubscription = /* GraphQL */ `mutation CreatePushSubscription(
  $input: CreatePushSubscriptionInput!
  $condition: ModelPushSubscriptionConditionInput
) {
  createPushSubscription(input: $input, condition: $condition) {
    user
    channel
    deviceToken
    deviceType
    expoPushToken
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePushSubscriptionMutationVariables,
  APITypes.CreatePushSubscriptionMutation
>;
export const updatePushSubscription = /* GraphQL */ `mutation UpdatePushSubscription(
  $input: UpdatePushSubscriptionInput!
  $condition: ModelPushSubscriptionConditionInput
) {
  updatePushSubscription(input: $input, condition: $condition) {
    user
    channel
    deviceToken
    deviceType
    expoPushToken
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePushSubscriptionMutationVariables,
  APITypes.UpdatePushSubscriptionMutation
>;
export const deletePushSubscription = /* GraphQL */ `mutation DeletePushSubscription(
  $input: DeletePushSubscriptionInput!
  $condition: ModelPushSubscriptionConditionInput
) {
  deletePushSubscription(input: $input, condition: $condition) {
    user
    channel
    deviceToken
    deviceType
    expoPushToken
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePushSubscriptionMutationVariables,
  APITypes.DeletePushSubscriptionMutation
>;
export const createUserSetting = /* GraphQL */ `mutation CreateUserSetting(
  $input: CreateUserSettingInput!
  $condition: ModelUserSettingConditionInput
) {
  createUserSetting(input: $input, condition: $condition) {
    teamLeaderRegionId
    teamLeaderRegionIds
    teamResourceId
    owner
    profileImage
    callsign
    calCode
    appVersion
    weightDisplay
    accessibilityIcons
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserSettingMutationVariables,
  APITypes.CreateUserSettingMutation
>;
export const updateUserSetting = /* GraphQL */ `mutation UpdateUserSetting(
  $input: UpdateUserSettingInput!
  $condition: ModelUserSettingConditionInput
) {
  updateUserSetting(input: $input, condition: $condition) {
    teamLeaderRegionId
    teamLeaderRegionIds
    teamResourceId
    owner
    profileImage
    callsign
    calCode
    appVersion
    weightDisplay
    accessibilityIcons
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserSettingMutationVariables,
  APITypes.UpdateUserSettingMutation
>;
export const deleteUserSetting = /* GraphQL */ `mutation DeleteUserSetting(
  $input: DeleteUserSettingInput!
  $condition: ModelUserSettingConditionInput
) {
  deleteUserSetting(input: $input, condition: $condition) {
    teamLeaderRegionId
    teamLeaderRegionIds
    teamResourceId
    owner
    profileImage
    callsign
    calCode
    appVersion
    weightDisplay
    accessibilityIcons
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserSettingMutationVariables,
  APITypes.DeleteUserSettingMutation
>;
export const createCertificate = /* GraphQL */ `mutation CreateCertificate(
  $input: CreateCertificateInput!
  $condition: ModelCertificateConditionInput
) {
  createCertificate(input: $input, condition: $condition) {
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCertificateMutationVariables,
  APITypes.CreateCertificateMutation
>;
export const updateCertificate = /* GraphQL */ `mutation UpdateCertificate(
  $input: UpdateCertificateInput!
  $condition: ModelCertificateConditionInput
) {
  updateCertificate(input: $input, condition: $condition) {
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCertificateMutationVariables,
  APITypes.UpdateCertificateMutation
>;
export const deleteCertificate = /* GraphQL */ `mutation DeleteCertificate(
  $input: DeleteCertificateInput!
  $condition: ModelCertificateConditionInput
) {
  deleteCertificate(input: $input, condition: $condition) {
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCertificateMutationVariables,
  APITypes.DeleteCertificateMutation
>;
export const createUserCertificate = /* GraphQL */ `mutation CreateUserCertificate(
  $input: CreateUserCertificateInput!
  $condition: ModelUserCertificateConditionInput
) {
  createUserCertificate(input: $input, condition: $condition) {
    key
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserCertificateMutationVariables,
  APITypes.CreateUserCertificateMutation
>;
export const updateUserCertificate = /* GraphQL */ `mutation UpdateUserCertificate(
  $input: UpdateUserCertificateInput!
  $condition: ModelUserCertificateConditionInput
) {
  updateUserCertificate(input: $input, condition: $condition) {
    key
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserCertificateMutationVariables,
  APITypes.UpdateUserCertificateMutation
>;
export const deleteUserCertificate = /* GraphQL */ `mutation DeleteUserCertificate(
  $input: DeleteUserCertificateInput!
  $condition: ModelUserCertificateConditionInput
) {
  deleteUserCertificate(input: $input, condition: $condition) {
    key
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserCertificateMutationVariables,
  APITypes.DeleteUserCertificateMutation
>;
export const createBroadcastMessage = /* GraphQL */ `mutation CreateBroadcastMessage(
  $input: CreateBroadcastMessageInput!
  $condition: ModelBroadcastMessageConditionInput
) {
  createBroadcastMessage(input: $input, condition: $condition) {
    id
    modelType
    message
    title
    originType
    originId
    sentBy
    url
    extra
    targetedGroup
    targetedRole
    targetedEventId
    targetedEvent {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    targetedUsers
    users
    broadcastAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBroadcastMessageMutationVariables,
  APITypes.CreateBroadcastMessageMutation
>;
export const updateBroadcastMessage = /* GraphQL */ `mutation UpdateBroadcastMessage(
  $input: UpdateBroadcastMessageInput!
  $condition: ModelBroadcastMessageConditionInput
) {
  updateBroadcastMessage(input: $input, condition: $condition) {
    id
    modelType
    message
    title
    originType
    originId
    sentBy
    url
    extra
    targetedGroup
    targetedRole
    targetedEventId
    targetedEvent {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    targetedUsers
    users
    broadcastAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBroadcastMessageMutationVariables,
  APITypes.UpdateBroadcastMessageMutation
>;
export const createBroadcastReceipt = /* GraphQL */ `mutation CreateBroadcastReceipt(
  $input: CreateBroadcastReceiptInput!
  $condition: ModelBroadcastReceiptConditionInput
) {
  createBroadcastReceipt(input: $input, condition: $condition) {
    id
    broadcastMessageId
    broadcastMessage {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    owner
    pushToken
    deviceToken
    sentAt
    status
    message
    details
    deliveredAt
    errors
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBroadcastReceiptMutationVariables,
  APITypes.CreateBroadcastReceiptMutation
>;
export const updateBroadcastReceipt = /* GraphQL */ `mutation UpdateBroadcastReceipt(
  $input: UpdateBroadcastReceiptInput!
  $condition: ModelBroadcastReceiptConditionInput
) {
  updateBroadcastReceipt(input: $input, condition: $condition) {
    id
    broadcastMessageId
    broadcastMessage {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    owner
    pushToken
    deviceToken
    sentAt
    status
    message
    details
    deliveredAt
    errors
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBroadcastReceiptMutationVariables,
  APITypes.UpdateBroadcastReceiptMutation
>;
export const deleteBroadcastReceipt = /* GraphQL */ `mutation DeleteBroadcastReceipt(
  $input: DeleteBroadcastReceiptInput!
  $condition: ModelBroadcastReceiptConditionInput
) {
  deleteBroadcastReceipt(input: $input, condition: $condition) {
    id
    broadcastMessageId
    broadcastMessage {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    owner
    pushToken
    deviceToken
    sentAt
    status
    message
    details
    deliveredAt
    errors
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBroadcastReceiptMutationVariables,
  APITypes.DeleteBroadcastReceiptMutation
>;
export const createExperiment = /* GraphQL */ `mutation CreateExperiment(
  $input: CreateExperimentInput!
  $condition: ModelExperimentConditionInput
) {
  createExperiment(input: $input, condition: $condition) {
    owner
    experiment
    enabled
    extra
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExperimentMutationVariables,
  APITypes.CreateExperimentMutation
>;
export const updateExperiment = /* GraphQL */ `mutation UpdateExperiment(
  $input: UpdateExperimentInput!
  $condition: ModelExperimentConditionInput
) {
  updateExperiment(input: $input, condition: $condition) {
    owner
    experiment
    enabled
    extra
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExperimentMutationVariables,
  APITypes.UpdateExperimentMutation
>;
export const deleteExperiment = /* GraphQL */ `mutation DeleteExperiment(
  $input: DeleteExperimentInput!
  $condition: ModelExperimentConditionInput
) {
  deleteExperiment(input: $input, condition: $condition) {
    owner
    experiment
    enabled
    extra
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExperimentMutationVariables,
  APITypes.DeleteExperimentMutation
>;
export const createPhotoAsset = /* GraphQL */ `mutation CreatePhotoAsset(
  $input: CreatePhotoAssetInput!
  $condition: ModelPhotoAssetConditionInput
) {
  createPhotoAsset(input: $input, condition: $condition) {
    id
    file {
      bucket
      region
      key
      __typename
    }
    files {
      file {
        bucket
        region
        key
        __typename
      }
      soldAtEvent {
        __typename
      }
      soldAfterEvent {
        __typename
      }
      __typename
    }
    enquiryId
    passengers
    email
    flightId
    flight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    salesAtEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    salesAfterEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    soldBy
    emailSent
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePhotoAssetMutationVariables,
  APITypes.CreatePhotoAssetMutation
>;
export const updatePhotoAsset = /* GraphQL */ `mutation UpdatePhotoAsset(
  $input: UpdatePhotoAssetInput!
  $condition: ModelPhotoAssetConditionInput
) {
  updatePhotoAsset(input: $input, condition: $condition) {
    id
    file {
      bucket
      region
      key
      __typename
    }
    files {
      file {
        bucket
        region
        key
        __typename
      }
      soldAtEvent {
        __typename
      }
      soldAfterEvent {
        __typename
      }
      __typename
    }
    enquiryId
    passengers
    email
    flightId
    flight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    salesAtEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    salesAfterEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    soldBy
    emailSent
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePhotoAssetMutationVariables,
  APITypes.UpdatePhotoAssetMutation
>;
export const deletePhotoAsset = /* GraphQL */ `mutation DeletePhotoAsset(
  $input: DeletePhotoAssetInput!
  $condition: ModelPhotoAssetConditionInput
) {
  deletePhotoAsset(input: $input, condition: $condition) {
    id
    file {
      bucket
      region
      key
      __typename
    }
    files {
      file {
        bucket
        region
        key
        __typename
      }
      soldAtEvent {
        __typename
      }
      soldAfterEvent {
        __typename
      }
      __typename
    }
    enquiryId
    passengers
    email
    flightId
    flight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    salesAtEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    salesAfterEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    soldBy
    emailSent
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePhotoAssetMutationVariables,
  APITypes.DeletePhotoAssetMutation
>;
export const createSafetyComm = /* GraphQL */ `mutation CreateSafetyComm(
  $input: CreateSafetyCommInput!
  $condition: ModelSafetyCommConditionInput
) {
  createSafetyComm(input: $input, condition: $condition) {
    id
    title
    modelType
    url
    file {
      bucket
      region
      key
      __typename
    }
    publishedBy
    publishedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSafetyCommMutationVariables,
  APITypes.CreateSafetyCommMutation
>;
export const updateSafetyComm = /* GraphQL */ `mutation UpdateSafetyComm(
  $input: UpdateSafetyCommInput!
  $condition: ModelSafetyCommConditionInput
) {
  updateSafetyComm(input: $input, condition: $condition) {
    id
    title
    modelType
    url
    file {
      bucket
      region
      key
      __typename
    }
    publishedBy
    publishedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSafetyCommMutationVariables,
  APITypes.UpdateSafetyCommMutation
>;
export const deleteSafetyComm = /* GraphQL */ `mutation DeleteSafetyComm(
  $input: DeleteSafetyCommInput!
  $condition: ModelSafetyCommConditionInput
) {
  deleteSafetyComm(input: $input, condition: $condition) {
    id
    title
    modelType
    url
    file {
      bucket
      region
      key
      __typename
    }
    publishedBy
    publishedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSafetyCommMutationVariables,
  APITypes.DeleteSafetyCommMutation
>;
