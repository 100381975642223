/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onWatchFlightByEventId = /* GraphQL */ `subscription OnWatchFlightByEventId($eventId: ID!) {
  onWatchFlightByEventId(eventId: $eventId) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnWatchFlightByEventIdSubscriptionVariables,
  APITypes.OnWatchFlightByEventIdSubscription
>;
export const onCreateFlightByEventId = /* GraphQL */ `subscription OnCreateFlightByEventId($eventId: ID!) {
  onCreateFlightByEventId(eventId: $eventId) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlightByEventIdSubscriptionVariables,
  APITypes.OnCreateFlightByEventIdSubscription
>;
export const onUpdateFlightByEventId = /* GraphQL */ `subscription OnUpdateFlightByEventId($eventId: ID!) {
  onUpdateFlightByEventId(eventId: $eventId) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlightByEventIdSubscriptionVariables,
  APITypes.OnUpdateFlightByEventIdSubscription
>;
export const onUpdateEventById = /* GraphQL */ `subscription OnUpdateEventById($id: ID!) {
  onUpdateEventById(id: $id) {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEventByIdSubscriptionVariables,
  APITypes.OnUpdateEventByIdSubscription
>;
export const onCreateUpdateEvent = /* GraphQL */ `subscription OnCreateUpdateEvent {
  onCreateUpdateEvent {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUpdateEventSubscriptionVariables,
  APITypes.OnCreateUpdateEventSubscription
>;
export const onCreateUpdateEventByUser = /* GraphQL */ `subscription OnCreateUpdateEventByUser($users: [String!]!) {
  onCreateUpdateEventByUser(users: $users) {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUpdateEventByUserSubscriptionVariables,
  APITypes.OnCreateUpdateEventByUserSubscription
>;
export const onUpdateFlightLegByEventId = /* GraphQL */ `subscription OnUpdateFlightLegByEventId($eventId: ID!) {
  onUpdateFlightLegByEventId(eventId: $eventId) {
    id
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    order
    venueId
    venueName
    venuePostcode
    empty
    departing
    fromVenueId
    fromVenueName
    fromVenuePostcode
    fromCoords {
      lat
      lng
      __typename
    }
    arriving
    toVenueId
    toVenueName
    toVenuePostcode
    toCoords {
      lat
      lng
      __typename
    }
    distance
    ete
    lat
    lng
    w3w
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlightLegByEventIdSubscriptionVariables,
  APITypes.OnUpdateFlightLegByEventIdSubscription
>;
export const onCreateEvent = /* GraphQL */ `subscription OnCreateEvent($filter: ModelSubscriptionEventFilterInput) {
  onCreateEvent(filter: $filter) {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEventSubscriptionVariables,
  APITypes.OnCreateEventSubscription
>;
export const onUpdateEvent = /* GraphQL */ `subscription OnUpdateEvent($filter: ModelSubscriptionEventFilterInput) {
  onUpdateEvent(filter: $filter) {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEventSubscriptionVariables,
  APITypes.OnUpdateEventSubscription
>;
export const onDeleteEvent = /* GraphQL */ `subscription OnDeleteEvent($filter: ModelSubscriptionEventFilterInput) {
  onDeleteEvent(filter: $filter) {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEventSubscriptionVariables,
  APITypes.OnDeleteEventSubscription
>;
export const onCreateFlight = /* GraphQL */ `subscription OnCreateFlight($filter: ModelSubscriptionFlightFilterInput) {
  onCreateFlight(filter: $filter) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlightSubscriptionVariables,
  APITypes.OnCreateFlightSubscription
>;
export const onUpdateFlight = /* GraphQL */ `subscription OnUpdateFlight($filter: ModelSubscriptionFlightFilterInput) {
  onUpdateFlight(filter: $filter) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlightSubscriptionVariables,
  APITypes.OnUpdateFlightSubscription
>;
export const onDeleteFlight = /* GraphQL */ `subscription OnDeleteFlight($filter: ModelSubscriptionFlightFilterInput) {
  onDeleteFlight(filter: $filter) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlightSubscriptionVariables,
  APITypes.OnDeleteFlightSubscription
>;
export const onCreateRefuelRequest = /* GraphQL */ `subscription OnCreateRefuelRequest(
  $filter: ModelSubscriptionRefuelRequestFilterInput
) {
  onCreateRefuelRequest(filter: $filter) {
    id
    eventId
    requestedBy
    requestFrom
    delay
    users
    flightOrderId
    adventureId
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRefuelRequestSubscriptionVariables,
  APITypes.OnCreateRefuelRequestSubscription
>;
export const onUpdateRefuelRequest = /* GraphQL */ `subscription OnUpdateRefuelRequest(
  $filter: ModelSubscriptionRefuelRequestFilterInput
) {
  onUpdateRefuelRequest(filter: $filter) {
    id
    eventId
    requestedBy
    requestFrom
    delay
    users
    flightOrderId
    adventureId
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRefuelRequestSubscriptionVariables,
  APITypes.OnUpdateRefuelRequestSubscription
>;
export const onDeleteRefuelRequest = /* GraphQL */ `subscription OnDeleteRefuelRequest(
  $filter: ModelSubscriptionRefuelRequestFilterInput
) {
  onDeleteRefuelRequest(filter: $filter) {
    id
    eventId
    requestedBy
    requestFrom
    delay
    users
    flightOrderId
    adventureId
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRefuelRequestSubscriptionVariables,
  APITypes.OnDeleteRefuelRequestSubscription
>;
export const onCreateRefuel = /* GraphQL */ `subscription OnCreateRefuel($filter: ModelSubscriptionRefuelFilterInput) {
  onCreateRefuel(filter: $filter) {
    id
    eventId
    refuelRequestId
    refuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    users
    createdAt
    refueledAt
    refueledBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRefuelSubscriptionVariables,
  APITypes.OnCreateRefuelSubscription
>;
export const onUpdateRefuel = /* GraphQL */ `subscription OnUpdateRefuel($filter: ModelSubscriptionRefuelFilterInput) {
  onUpdateRefuel(filter: $filter) {
    id
    eventId
    refuelRequestId
    refuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    users
    createdAt
    refueledAt
    refueledBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRefuelSubscriptionVariables,
  APITypes.OnUpdateRefuelSubscription
>;
export const onDeleteRefuel = /* GraphQL */ `subscription OnDeleteRefuel($filter: ModelSubscriptionRefuelFilterInput) {
  onDeleteRefuel(filter: $filter) {
    id
    eventId
    refuelRequestId
    refuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    users
    createdAt
    refueledAt
    refueledBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRefuelSubscriptionVariables,
  APITypes.OnDeleteRefuelSubscription
>;
export const onCreateFlightPassenger = /* GraphQL */ `subscription OnCreateFlightPassenger(
  $filter: ModelSubscriptionFlightPassengerFilterInput
) {
  onCreateFlightPassenger(filter: $filter) {
    id
    eventId
    tupleId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    enquiryId
    voucherId
    designator
    sequence
    flightNumber
    flightId
    number
    passengerNumber
    forename
    surname
    weight
    seat
    phone
    email
    postcode
    bubbly
    creamTea
    lunch
    magnum
    chocolate
    photo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlightPassengerSubscriptionVariables,
  APITypes.OnCreateFlightPassengerSubscription
>;
export const onUpdateFlightPassenger = /* GraphQL */ `subscription OnUpdateFlightPassenger(
  $filter: ModelSubscriptionFlightPassengerFilterInput
) {
  onUpdateFlightPassenger(filter: $filter) {
    id
    eventId
    tupleId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    enquiryId
    voucherId
    designator
    sequence
    flightNumber
    flightId
    number
    passengerNumber
    forename
    surname
    weight
    seat
    phone
    email
    postcode
    bubbly
    creamTea
    lunch
    magnum
    chocolate
    photo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlightPassengerSubscriptionVariables,
  APITypes.OnUpdateFlightPassengerSubscription
>;
export const onDeleteFlightPassenger = /* GraphQL */ `subscription OnDeleteFlightPassenger(
  $filter: ModelSubscriptionFlightPassengerFilterInput
) {
  onDeleteFlightPassenger(filter: $filter) {
    id
    eventId
    tupleId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    enquiryId
    voucherId
    designator
    sequence
    flightNumber
    flightId
    number
    passengerNumber
    forename
    surname
    weight
    seat
    phone
    email
    postcode
    bubbly
    creamTea
    lunch
    magnum
    chocolate
    photo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlightPassengerSubscriptionVariables,
  APITypes.OnDeleteFlightPassengerSubscription
>;
export const onCreatePleasureOverview = /* GraphQL */ `subscription OnCreatePleasureOverview(
  $filter: ModelSubscriptionPleasureOverviewFilterInput
) {
  onCreatePleasureOverview(filter: $filter) {
    id
    eventId
    team {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    manifestItems {
      items {
        id
        overviewId
        eventId
        time
        duration
        designator
        pax
        weight
        voucherIds
        frontSeatTaken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    paxBooked
    aircraftReg
    aircraftSeats
    fireName
    fireReg
    bowserName
    truckName
    truckReg
    counts
    teamLeaderId
    teamLeader {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    disabledRoles
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePleasureOverviewSubscriptionVariables,
  APITypes.OnCreatePleasureOverviewSubscription
>;
export const onUpdatePleasureOverview = /* GraphQL */ `subscription OnUpdatePleasureOverview(
  $filter: ModelSubscriptionPleasureOverviewFilterInput
) {
  onUpdatePleasureOverview(filter: $filter) {
    id
    eventId
    team {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    manifestItems {
      items {
        id
        overviewId
        eventId
        time
        duration
        designator
        pax
        weight
        voucherIds
        frontSeatTaken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    paxBooked
    aircraftReg
    aircraftSeats
    fireName
    fireReg
    bowserName
    truckName
    truckReg
    counts
    teamLeaderId
    teamLeader {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    disabledRoles
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePleasureOverviewSubscriptionVariables,
  APITypes.OnUpdatePleasureOverviewSubscription
>;
export const onDeletePleasureOverview = /* GraphQL */ `subscription OnDeletePleasureOverview(
  $filter: ModelSubscriptionPleasureOverviewFilterInput
) {
  onDeletePleasureOverview(filter: $filter) {
    id
    eventId
    team {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    manifestItems {
      items {
        id
        overviewId
        eventId
        time
        duration
        designator
        pax
        weight
        voucherIds
        frontSeatTaken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    paxBooked
    aircraftReg
    aircraftSeats
    fireName
    fireReg
    bowserName
    truckName
    truckReg
    counts
    teamLeaderId
    teamLeader {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    disabledRoles
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePleasureOverviewSubscriptionVariables,
  APITypes.OnDeletePleasureOverviewSubscription
>;
export const onCreateTeamMember = /* GraphQL */ `subscription OnCreateTeamMember(
  $filter: ModelSubscriptionTeamMemberFilterInput
) {
  onCreateTeamMember(filter: $filter) {
    id
    overviewId
    eventId
    name
    forename
    surname
    teamResourceId
    teamRole
    phone
    confirmed
    owner
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTeamMemberSubscriptionVariables,
  APITypes.OnCreateTeamMemberSubscription
>;
export const onUpdateTeamMember = /* GraphQL */ `subscription OnUpdateTeamMember(
  $filter: ModelSubscriptionTeamMemberFilterInput
) {
  onUpdateTeamMember(filter: $filter) {
    id
    overviewId
    eventId
    name
    forename
    surname
    teamResourceId
    teamRole
    phone
    confirmed
    owner
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTeamMemberSubscriptionVariables,
  APITypes.OnUpdateTeamMemberSubscription
>;
export const onDeleteTeamMember = /* GraphQL */ `subscription OnDeleteTeamMember(
  $filter: ModelSubscriptionTeamMemberFilterInput
) {
  onDeleteTeamMember(filter: $filter) {
    id
    overviewId
    eventId
    name
    forename
    surname
    teamResourceId
    teamRole
    phone
    confirmed
    owner
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTeamMemberSubscriptionVariables,
  APITypes.OnDeleteTeamMemberSubscription
>;
export const onCreateManifestItem = /* GraphQL */ `subscription OnCreateManifestItem(
  $filter: ModelSubscriptionManifestItemFilterInput
) {
  onCreateManifestItem(filter: $filter) {
    id
    overviewId
    eventId
    time
    duration
    designator
    pax
    weight
    voucherIds
    frontSeatTaken
    passengers {
      title
      forename
      surname
      phone
      email
      postcode
      weight
      enquiryId
      voucherId
      frontSeat
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateManifestItemSubscriptionVariables,
  APITypes.OnCreateManifestItemSubscription
>;
export const onUpdateManifestItem = /* GraphQL */ `subscription OnUpdateManifestItem(
  $filter: ModelSubscriptionManifestItemFilterInput
) {
  onUpdateManifestItem(filter: $filter) {
    id
    overviewId
    eventId
    time
    duration
    designator
    pax
    weight
    voucherIds
    frontSeatTaken
    passengers {
      title
      forename
      surname
      phone
      email
      postcode
      weight
      enquiryId
      voucherId
      frontSeat
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateManifestItemSubscriptionVariables,
  APITypes.OnUpdateManifestItemSubscription
>;
export const onDeleteManifestItem = /* GraphQL */ `subscription OnDeleteManifestItem(
  $filter: ModelSubscriptionManifestItemFilterInput
) {
  onDeleteManifestItem(filter: $filter) {
    id
    overviewId
    eventId
    time
    duration
    designator
    pax
    weight
    voucherIds
    frontSeatTaken
    passengers {
      title
      forename
      surname
      phone
      email
      postcode
      weight
      enquiryId
      voucherId
      frontSeat
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteManifestItemSubscriptionVariables,
  APITypes.OnDeleteManifestItemSubscription
>;
export const onCreateFollowingLog = /* GraphQL */ `subscription OnCreateFollowingLog(
  $filter: ModelSubscriptionFollowingLogFilterInput
) {
  onCreateFollowingLog(filter: $filter) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFollowingLogSubscriptionVariables,
  APITypes.OnCreateFollowingLogSubscription
>;
export const onUpdateFollowingLog = /* GraphQL */ `subscription OnUpdateFollowingLog(
  $filter: ModelSubscriptionFollowingLogFilterInput
) {
  onUpdateFollowingLog(filter: $filter) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFollowingLogSubscriptionVariables,
  APITypes.OnUpdateFollowingLogSubscription
>;
export const onDeleteFollowingLog = /* GraphQL */ `subscription OnDeleteFollowingLog(
  $filter: ModelSubscriptionFollowingLogFilterInput
) {
  onDeleteFollowingLog(filter: $filter) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFollowingLogSubscriptionVariables,
  APITypes.OnDeleteFollowingLogSubscription
>;
export const onCreateAzureFollowingLog = /* GraphQL */ `subscription OnCreateAzureFollowingLog(
  $filter: ModelSubscriptionAzureFollowingLogFilterInput
) {
  onCreateAzureFollowingLog(filter: $filter) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAzureFollowingLogSubscriptionVariables,
  APITypes.OnCreateAzureFollowingLogSubscription
>;
export const onUpdateAzureFollowingLog = /* GraphQL */ `subscription OnUpdateAzureFollowingLog(
  $filter: ModelSubscriptionAzureFollowingLogFilterInput
) {
  onUpdateAzureFollowingLog(filter: $filter) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAzureFollowingLogSubscriptionVariables,
  APITypes.OnUpdateAzureFollowingLogSubscription
>;
export const onDeleteAzureFollowingLog = /* GraphQL */ `subscription OnDeleteAzureFollowingLog(
  $filter: ModelSubscriptionAzureFollowingLogFilterInput
) {
  onDeleteAzureFollowingLog(filter: $filter) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAzureFollowingLogSubscriptionVariables,
  APITypes.OnDeleteAzureFollowingLogSubscription
>;
export const onCreateFlightLeg = /* GraphQL */ `subscription OnCreateFlightLeg($filter: ModelSubscriptionFlightLegFilterInput) {
  onCreateFlightLeg(filter: $filter) {
    id
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    order
    venueId
    venueName
    venuePostcode
    empty
    departing
    fromVenueId
    fromVenueName
    fromVenuePostcode
    fromCoords {
      lat
      lng
      __typename
    }
    arriving
    toVenueId
    toVenueName
    toVenuePostcode
    toCoords {
      lat
      lng
      __typename
    }
    distance
    ete
    lat
    lng
    w3w
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlightLegSubscriptionVariables,
  APITypes.OnCreateFlightLegSubscription
>;
export const onUpdateFlightLeg = /* GraphQL */ `subscription OnUpdateFlightLeg($filter: ModelSubscriptionFlightLegFilterInput) {
  onUpdateFlightLeg(filter: $filter) {
    id
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    order
    venueId
    venueName
    venuePostcode
    empty
    departing
    fromVenueId
    fromVenueName
    fromVenuePostcode
    fromCoords {
      lat
      lng
      __typename
    }
    arriving
    toVenueId
    toVenueName
    toVenuePostcode
    toCoords {
      lat
      lng
      __typename
    }
    distance
    ete
    lat
    lng
    w3w
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlightLegSubscriptionVariables,
  APITypes.OnUpdateFlightLegSubscription
>;
export const onDeleteFlightLeg = /* GraphQL */ `subscription OnDeleteFlightLeg($filter: ModelSubscriptionFlightLegFilterInput) {
  onDeleteFlightLeg(filter: $filter) {
    id
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    order
    venueId
    venueName
    venuePostcode
    empty
    departing
    fromVenueId
    fromVenueName
    fromVenuePostcode
    fromCoords {
      lat
      lng
      __typename
    }
    arriving
    toVenueId
    toVenueName
    toVenuePostcode
    toCoords {
      lat
      lng
      __typename
    }
    distance
    ete
    lat
    lng
    w3w
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlightLegSubscriptionVariables,
  APITypes.OnDeleteFlightLegSubscription
>;
export const onCreatePushSubscription = /* GraphQL */ `subscription OnCreatePushSubscription(
  $filter: ModelSubscriptionPushSubscriptionFilterInput
) {
  onCreatePushSubscription(filter: $filter) {
    user
    channel
    deviceToken
    deviceType
    expoPushToken
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePushSubscriptionSubscriptionVariables,
  APITypes.OnCreatePushSubscriptionSubscription
>;
export const onUpdatePushSubscription = /* GraphQL */ `subscription OnUpdatePushSubscription(
  $filter: ModelSubscriptionPushSubscriptionFilterInput
) {
  onUpdatePushSubscription(filter: $filter) {
    user
    channel
    deviceToken
    deviceType
    expoPushToken
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePushSubscriptionSubscriptionVariables,
  APITypes.OnUpdatePushSubscriptionSubscription
>;
export const onDeletePushSubscription = /* GraphQL */ `subscription OnDeletePushSubscription(
  $filter: ModelSubscriptionPushSubscriptionFilterInput
) {
  onDeletePushSubscription(filter: $filter) {
    user
    channel
    deviceToken
    deviceType
    expoPushToken
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePushSubscriptionSubscriptionVariables,
  APITypes.OnDeletePushSubscriptionSubscription
>;
export const onCreateUserSetting = /* GraphQL */ `subscription OnCreateUserSetting(
  $filter: ModelSubscriptionUserSettingFilterInput
  $owner: String
) {
  onCreateUserSetting(filter: $filter, owner: $owner) {
    teamLeaderRegionId
    teamLeaderRegionIds
    teamResourceId
    owner
    profileImage
    callsign
    calCode
    appVersion
    weightDisplay
    accessibilityIcons
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSettingSubscriptionVariables,
  APITypes.OnCreateUserSettingSubscription
>;
export const onUpdateUserSetting = /* GraphQL */ `subscription OnUpdateUserSetting(
  $filter: ModelSubscriptionUserSettingFilterInput
  $owner: String
) {
  onUpdateUserSetting(filter: $filter, owner: $owner) {
    teamLeaderRegionId
    teamLeaderRegionIds
    teamResourceId
    owner
    profileImage
    callsign
    calCode
    appVersion
    weightDisplay
    accessibilityIcons
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSettingSubscriptionVariables,
  APITypes.OnUpdateUserSettingSubscription
>;
export const onDeleteUserSetting = /* GraphQL */ `subscription OnDeleteUserSetting(
  $filter: ModelSubscriptionUserSettingFilterInput
  $owner: String
) {
  onDeleteUserSetting(filter: $filter, owner: $owner) {
    teamLeaderRegionId
    teamLeaderRegionIds
    teamResourceId
    owner
    profileImage
    callsign
    calCode
    appVersion
    weightDisplay
    accessibilityIcons
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSettingSubscriptionVariables,
  APITypes.OnDeleteUserSettingSubscription
>;
export const onCreateCertificate = /* GraphQL */ `subscription OnCreateCertificate(
  $filter: ModelSubscriptionCertificateFilterInput
  $owner: String
) {
  onCreateCertificate(filter: $filter, owner: $owner) {
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCertificateSubscriptionVariables,
  APITypes.OnCreateCertificateSubscription
>;
export const onUpdateCertificate = /* GraphQL */ `subscription OnUpdateCertificate(
  $filter: ModelSubscriptionCertificateFilterInput
  $owner: String
) {
  onUpdateCertificate(filter: $filter, owner: $owner) {
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCertificateSubscriptionVariables,
  APITypes.OnUpdateCertificateSubscription
>;
export const onDeleteCertificate = /* GraphQL */ `subscription OnDeleteCertificate(
  $filter: ModelSubscriptionCertificateFilterInput
  $owner: String
) {
  onDeleteCertificate(filter: $filter, owner: $owner) {
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCertificateSubscriptionVariables,
  APITypes.OnDeleteCertificateSubscription
>;
export const onCreateUserCertificate = /* GraphQL */ `subscription OnCreateUserCertificate(
  $filter: ModelSubscriptionUserCertificateFilterInput
  $owner: String
) {
  onCreateUserCertificate(filter: $filter, owner: $owner) {
    key
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserCertificateSubscriptionVariables,
  APITypes.OnCreateUserCertificateSubscription
>;
export const onUpdateUserCertificate = /* GraphQL */ `subscription OnUpdateUserCertificate(
  $filter: ModelSubscriptionUserCertificateFilterInput
  $owner: String
) {
  onUpdateUserCertificate(filter: $filter, owner: $owner) {
    key
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserCertificateSubscriptionVariables,
  APITypes.OnUpdateUserCertificateSubscription
>;
export const onDeleteUserCertificate = /* GraphQL */ `subscription OnDeleteUserCertificate(
  $filter: ModelSubscriptionUserCertificateFilterInput
  $owner: String
) {
  onDeleteUserCertificate(filter: $filter, owner: $owner) {
    key
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserCertificateSubscriptionVariables,
  APITypes.OnDeleteUserCertificateSubscription
>;
export const onCreateBroadcastMessage = /* GraphQL */ `subscription OnCreateBroadcastMessage(
  $filter: ModelSubscriptionBroadcastMessageFilterInput
) {
  onCreateBroadcastMessage(filter: $filter) {
    id
    modelType
    message
    title
    originType
    originId
    sentBy
    url
    extra
    targetedGroup
    targetedRole
    targetedEventId
    targetedEvent {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    targetedUsers
    users
    broadcastAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBroadcastMessageSubscriptionVariables,
  APITypes.OnCreateBroadcastMessageSubscription
>;
export const onUpdateBroadcastMessage = /* GraphQL */ `subscription OnUpdateBroadcastMessage(
  $filter: ModelSubscriptionBroadcastMessageFilterInput
) {
  onUpdateBroadcastMessage(filter: $filter) {
    id
    modelType
    message
    title
    originType
    originId
    sentBy
    url
    extra
    targetedGroup
    targetedRole
    targetedEventId
    targetedEvent {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    targetedUsers
    users
    broadcastAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBroadcastMessageSubscriptionVariables,
  APITypes.OnUpdateBroadcastMessageSubscription
>;
export const onDeleteBroadcastMessage = /* GraphQL */ `subscription OnDeleteBroadcastMessage(
  $filter: ModelSubscriptionBroadcastMessageFilterInput
) {
  onDeleteBroadcastMessage(filter: $filter) {
    id
    modelType
    message
    title
    originType
    originId
    sentBy
    url
    extra
    targetedGroup
    targetedRole
    targetedEventId
    targetedEvent {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    targetedUsers
    users
    broadcastAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBroadcastMessageSubscriptionVariables,
  APITypes.OnDeleteBroadcastMessageSubscription
>;
export const onCreateBroadcastReceipt = /* GraphQL */ `subscription OnCreateBroadcastReceipt(
  $filter: ModelSubscriptionBroadcastReceiptFilterInput
) {
  onCreateBroadcastReceipt(filter: $filter) {
    id
    broadcastMessageId
    broadcastMessage {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    owner
    pushToken
    deviceToken
    sentAt
    status
    message
    details
    deliveredAt
    errors
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBroadcastReceiptSubscriptionVariables,
  APITypes.OnCreateBroadcastReceiptSubscription
>;
export const onUpdateBroadcastReceipt = /* GraphQL */ `subscription OnUpdateBroadcastReceipt(
  $filter: ModelSubscriptionBroadcastReceiptFilterInput
) {
  onUpdateBroadcastReceipt(filter: $filter) {
    id
    broadcastMessageId
    broadcastMessage {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    owner
    pushToken
    deviceToken
    sentAt
    status
    message
    details
    deliveredAt
    errors
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBroadcastReceiptSubscriptionVariables,
  APITypes.OnUpdateBroadcastReceiptSubscription
>;
export const onDeleteBroadcastReceipt = /* GraphQL */ `subscription OnDeleteBroadcastReceipt(
  $filter: ModelSubscriptionBroadcastReceiptFilterInput
) {
  onDeleteBroadcastReceipt(filter: $filter) {
    id
    broadcastMessageId
    broadcastMessage {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    owner
    pushToken
    deviceToken
    sentAt
    status
    message
    details
    deliveredAt
    errors
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBroadcastReceiptSubscriptionVariables,
  APITypes.OnDeleteBroadcastReceiptSubscription
>;
export const onCreateExperiment = /* GraphQL */ `subscription OnCreateExperiment(
  $filter: ModelSubscriptionExperimentFilterInput
  $owner: String
) {
  onCreateExperiment(filter: $filter, owner: $owner) {
    owner
    experiment
    enabled
    extra
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateExperimentSubscriptionVariables,
  APITypes.OnCreateExperimentSubscription
>;
export const onUpdateExperiment = /* GraphQL */ `subscription OnUpdateExperiment(
  $filter: ModelSubscriptionExperimentFilterInput
  $owner: String
) {
  onUpdateExperiment(filter: $filter, owner: $owner) {
    owner
    experiment
    enabled
    extra
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateExperimentSubscriptionVariables,
  APITypes.OnUpdateExperimentSubscription
>;
export const onDeleteExperiment = /* GraphQL */ `subscription OnDeleteExperiment(
  $filter: ModelSubscriptionExperimentFilterInput
  $owner: String
) {
  onDeleteExperiment(filter: $filter, owner: $owner) {
    owner
    experiment
    enabled
    extra
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteExperimentSubscriptionVariables,
  APITypes.OnDeleteExperimentSubscription
>;
export const onCreatePhotoAsset = /* GraphQL */ `subscription OnCreatePhotoAsset(
  $filter: ModelSubscriptionPhotoAssetFilterInput
) {
  onCreatePhotoAsset(filter: $filter) {
    id
    file {
      bucket
      region
      key
      __typename
    }
    files {
      file {
        bucket
        region
        key
        __typename
      }
      soldAtEvent {
        __typename
      }
      soldAfterEvent {
        __typename
      }
      __typename
    }
    enquiryId
    passengers
    email
    flightId
    flight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    salesAtEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    salesAfterEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    soldBy
    emailSent
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePhotoAssetSubscriptionVariables,
  APITypes.OnCreatePhotoAssetSubscription
>;
export const onUpdatePhotoAsset = /* GraphQL */ `subscription OnUpdatePhotoAsset(
  $filter: ModelSubscriptionPhotoAssetFilterInput
) {
  onUpdatePhotoAsset(filter: $filter) {
    id
    file {
      bucket
      region
      key
      __typename
    }
    files {
      file {
        bucket
        region
        key
        __typename
      }
      soldAtEvent {
        __typename
      }
      soldAfterEvent {
        __typename
      }
      __typename
    }
    enquiryId
    passengers
    email
    flightId
    flight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    salesAtEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    salesAfterEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    soldBy
    emailSent
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePhotoAssetSubscriptionVariables,
  APITypes.OnUpdatePhotoAssetSubscription
>;
export const onDeletePhotoAsset = /* GraphQL */ `subscription OnDeletePhotoAsset(
  $filter: ModelSubscriptionPhotoAssetFilterInput
) {
  onDeletePhotoAsset(filter: $filter) {
    id
    file {
      bucket
      region
      key
      __typename
    }
    files {
      file {
        bucket
        region
        key
        __typename
      }
      soldAtEvent {
        __typename
      }
      soldAfterEvent {
        __typename
      }
      __typename
    }
    enquiryId
    passengers
    email
    flightId
    flight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    salesAtEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    salesAfterEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    soldBy
    emailSent
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePhotoAssetSubscriptionVariables,
  APITypes.OnDeletePhotoAssetSubscription
>;
export const onCreateSafetyComm = /* GraphQL */ `subscription OnCreateSafetyComm(
  $filter: ModelSubscriptionSafetyCommFilterInput
) {
  onCreateSafetyComm(filter: $filter) {
    id
    title
    modelType
    url
    file {
      bucket
      region
      key
      __typename
    }
    publishedBy
    publishedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSafetyCommSubscriptionVariables,
  APITypes.OnCreateSafetyCommSubscription
>;
export const onUpdateSafetyComm = /* GraphQL */ `subscription OnUpdateSafetyComm(
  $filter: ModelSubscriptionSafetyCommFilterInput
) {
  onUpdateSafetyComm(filter: $filter) {
    id
    title
    modelType
    url
    file {
      bucket
      region
      key
      __typename
    }
    publishedBy
    publishedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSafetyCommSubscriptionVariables,
  APITypes.OnUpdateSafetyCommSubscription
>;
export const onDeleteSafetyComm = /* GraphQL */ `subscription OnDeleteSafetyComm(
  $filter: ModelSubscriptionSafetyCommFilterInput
) {
  onDeleteSafetyComm(filter: $filter) {
    id
    title
    modelType
    url
    file {
      bucket
      region
      key
      __typename
    }
    publishedBy
    publishedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSafetyCommSubscriptionVariables,
  APITypes.OnDeleteSafetyCommSubscription
>;
