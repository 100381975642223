/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    modelType
    id
    type
    oid
    venue
    venueId
    regionId
    date
    endDate
    pax
    product
    ref
    description
    notes
    overview {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentFlight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    currentRefuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teamMembers {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    flights {
      items {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    legs {
      items {
        id
        eventId
        order
        venueId
        venueName
        venuePostcode
        empty
        departing
        fromVenueId
        fromVenueName
        fromVenuePostcode
        arriving
        toVenueId
        toVenueName
        toVenuePostcode
        distance
        ete
        lat
        lng
        w3w
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    pilotName
    pilotConfirmed
    aircraftId
    aircraftType
    aircraftName
    aircraftSeats
    aircraft
    duration
    client
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventOverviewId
    eventCurrentFlightId
    eventCurrentRefuelRequestId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const syncEvents = /* GraphQL */ `query SyncEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEventsQueryVariables,
  APITypes.SyncEventsQuery
>;
export const listEventsByDate = /* GraphQL */ `query ListEventsByDate(
  $modelType: TaskTypeString!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventsByDate(
    modelType: $modelType
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsByDateQueryVariables,
  APITypes.ListEventsByDateQuery
>;
export const listEventsByType = /* GraphQL */ `query ListEventsByType(
  $type: EventType!
  $oid: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventsByType(
    type: $type
    oid: $oid
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsByTypeQueryVariables,
  APITypes.ListEventsByTypeQuery
>;
export const listEventsByTypeAndDate = /* GraphQL */ `query ListEventsByTypeAndDate(
  $type: EventType!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventsByTypeAndDate(
    type: $type
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsByTypeAndDateQueryVariables,
  APITypes.ListEventsByTypeAndDateQuery
>;
export const listEventsByRegionId = /* GraphQL */ `query ListEventsByRegionId(
  $regionId: Int!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventsByRegionId(
    regionId: $regionId
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsByRegionIdQueryVariables,
  APITypes.ListEventsByRegionIdQuery
>;
export const searchEvents = /* GraphQL */ `query SearchEvents(
  $filter: SearchableEventFilterInput
  $sort: [SearchableEventSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableEventAggregationInput]
) {
  searchEvents(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchEventsQueryVariables,
  APITypes.SearchEventsQuery
>;
export const getPleasureOverview = /* GraphQL */ `query GetPleasureOverview($id: ID!) {
  getPleasureOverview(id: $id) {
    id
    eventId
    team {
      items {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    manifestItems {
      items {
        id
        overviewId
        eventId
        time
        duration
        designator
        pax
        weight
        voucherIds
        frontSeatTaken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    paxBooked
    aircraftReg
    aircraftSeats
    fireName
    fireReg
    bowserName
    truckName
    truckReg
    counts
    teamLeaderId
    teamLeader {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    disabledRoles
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPleasureOverviewQueryVariables,
  APITypes.GetPleasureOverviewQuery
>;
export const listPleasureOverviews = /* GraphQL */ `query ListPleasureOverviews(
  $filter: ModelPleasureOverviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listPleasureOverviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPleasureOverviewsQueryVariables,
  APITypes.ListPleasureOverviewsQuery
>;
export const syncPleasureOverviews = /* GraphQL */ `query SyncPleasureOverviews(
  $filter: ModelPleasureOverviewFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPleasureOverviews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      eventId
      team {
        nextToken
        startedAt
        __typename
      }
      manifestItems {
        nextToken
        startedAt
        __typename
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPleasureOverviewsQueryVariables,
  APITypes.SyncPleasureOverviewsQuery
>;
export const getTeamMember = /* GraphQL */ `query GetTeamMember($id: ID!) {
  getTeamMember(id: $id) {
    id
    overviewId
    eventId
    name
    forename
    surname
    teamResourceId
    teamRole
    phone
    confirmed
    owner
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeamMemberQueryVariables,
  APITypes.GetTeamMemberQuery
>;
export const listTeamMembers = /* GraphQL */ `query ListTeamMembers(
  $filter: ModelTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamMembersQueryVariables,
  APITypes.ListTeamMembersQuery
>;
export const syncTeamMembers = /* GraphQL */ `query SyncTeamMembers(
  $filter: ModelTeamMemberFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTeamMembers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTeamMembersQueryVariables,
  APITypes.SyncTeamMembersQuery
>;
export const listTeamMembersByOverview = /* GraphQL */ `query ListTeamMembersByOverview(
  $overviewId: ID!
  $teamRole: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamMembersByOverview(
    overviewId: $overviewId
    teamRole: $teamRole
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamMembersByOverviewQueryVariables,
  APITypes.ListTeamMembersByOverviewQuery
>;
export const listTeamMembersByEvent = /* GraphQL */ `query ListTeamMembersByEvent(
  $eventId: ID!
  $teamRole: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeamMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamMembersByEvent(
    eventId: $eventId
    teamRole: $teamRole
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamMembersByEventQueryVariables,
  APITypes.ListTeamMembersByEventQuery
>;
export const getManifestItem = /* GraphQL */ `query GetManifestItem($id: ID!) {
  getManifestItem(id: $id) {
    id
    overviewId
    eventId
    time
    duration
    designator
    pax
    weight
    voucherIds
    frontSeatTaken
    passengers {
      title
      forename
      surname
      phone
      email
      postcode
      weight
      enquiryId
      voucherId
      frontSeat
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetManifestItemQueryVariables,
  APITypes.GetManifestItemQuery
>;
export const listManifestItems = /* GraphQL */ `query ListManifestItems(
  $filter: ModelManifestItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listManifestItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      overviewId
      eventId
      time
      duration
      designator
      pax
      weight
      voucherIds
      frontSeatTaken
      passengers {
        title
        forename
        surname
        phone
        email
        postcode
        weight
        enquiryId
        voucherId
        frontSeat
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListManifestItemsQueryVariables,
  APITypes.ListManifestItemsQuery
>;
export const syncManifestItems = /* GraphQL */ `query SyncManifestItems(
  $filter: ModelManifestItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncManifestItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      overviewId
      eventId
      time
      duration
      designator
      pax
      weight
      voucherIds
      frontSeatTaken
      passengers {
        title
        forename
        surname
        phone
        email
        postcode
        weight
        enquiryId
        voucherId
        frontSeat
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncManifestItemsQueryVariables,
  APITypes.SyncManifestItemsQuery
>;
export const listManifestItemsByOverview = /* GraphQL */ `query ListManifestItemsByOverview(
  $overviewId: ID!
  $time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelManifestItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listManifestItemsByOverview(
    overviewId: $overviewId
    time: $time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      overviewId
      eventId
      time
      duration
      designator
      pax
      weight
      voucherIds
      frontSeatTaken
      passengers {
        title
        forename
        surname
        phone
        email
        postcode
        weight
        enquiryId
        voucherId
        frontSeat
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListManifestItemsByOverviewQueryVariables,
  APITypes.ListManifestItemsByOverviewQuery
>;
export const listManifestItemsByEvent = /* GraphQL */ `query ListManifestItemsByEvent(
  $eventId: ID!
  $time: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelManifestItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listManifestItemsByEvent(
    eventId: $eventId
    time: $time
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      overviewId
      eventId
      time
      duration
      designator
      pax
      weight
      voucherIds
      frontSeatTaken
      passengers {
        title
        forename
        surname
        phone
        email
        postcode
        weight
        enquiryId
        voucherId
        frontSeat
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListManifestItemsByEventQueryVariables,
  APITypes.ListManifestItemsByEventQuery
>;
export const searchManifestItems = /* GraphQL */ `query SearchManifestItems(
  $filter: SearchableManifestItemFilterInput
  $sort: [SearchableManifestItemSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableManifestItemAggregationInput]
) {
  searchManifestItems(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      overviewId
      eventId
      time
      duration
      designator
      pax
      weight
      voucherIds
      frontSeatTaken
      passengers {
        title
        forename
        surname
        phone
        email
        postcode
        weight
        enquiryId
        voucherId
        frontSeat
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchManifestItemsQueryVariables,
  APITypes.SearchManifestItemsQuery
>;
export const getFlight = /* GraphQL */ `query GetFlight($id: ID!) {
  getFlight(id: $id) {
    id
    tupleId
    eventId
    flightOrderId
    designator
    sequence
    flightNumber
    theme
    aircraftName
    isFlightFull
    scheduledDeparture
    actualDeparture
    estimatedArrival
    scheduledCheckIn
    status
    duration
    passengers {
      items {
        id
        eventId
        tupleId
        enquiryId
        voucherId
        designator
        sequence
        flightNumber
        flightId
        number
        passengerNumber
        forename
        surname
        weight
        seat
        phone
        email
        postcode
        bubbly
        creamTea
        lunch
        magnum
        chocolate
        photo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    users
    updatedAt
    lastUpdatedBy
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFlightQueryVariables, APITypes.GetFlightQuery>;
export const listFlights = /* GraphQL */ `query ListFlights(
  $filter: ModelFlightFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlights(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlightsQueryVariables,
  APITypes.ListFlightsQuery
>;
export const syncFlights = /* GraphQL */ `query SyncFlights(
  $filter: ModelFlightFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFlights(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFlightsQueryVariables,
  APITypes.SyncFlightsQuery
>;
export const listFlightsByEventSequence = /* GraphQL */ `query ListFlightsByEventSequence(
  $eventId: ID!
  $scheduledDeparture: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFlightFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlightsByEventSequence(
    eventId: $eventId
    scheduledDeparture: $scheduledDeparture
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlightsByEventSequenceQueryVariables,
  APITypes.ListFlightsByEventSequenceQuery
>;
export const searchFlights = /* GraphQL */ `query SearchFlights(
  $filter: SearchableFlightFilterInput
  $sort: [SearchableFlightSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFlightAggregationInput]
) {
  searchFlights(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFlightsQueryVariables,
  APITypes.SearchFlightsQuery
>;
export const getRefuelRequest = /* GraphQL */ `query GetRefuelRequest($id: ID!) {
  getRefuelRequest(id: $id) {
    id
    eventId
    requestedBy
    requestFrom
    delay
    users
    flightOrderId
    adventureId
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRefuelRequestQueryVariables,
  APITypes.GetRefuelRequestQuery
>;
export const listRefuelRequests = /* GraphQL */ `query ListRefuelRequests(
  $filter: ModelRefuelRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listRefuelRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRefuelRequestsQueryVariables,
  APITypes.ListRefuelRequestsQuery
>;
export const syncRefuelRequests = /* GraphQL */ `query SyncRefuelRequests(
  $filter: ModelRefuelRequestFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRefuelRequests(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRefuelRequestsQueryVariables,
  APITypes.SyncRefuelRequestsQuery
>;
export const listRefuelRequestsByEventId = /* GraphQL */ `query ListRefuelRequestsByEventId(
  $eventId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRefuelRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listRefuelRequestsByEventId(
    eventId: $eventId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRefuelRequestsByEventIdQueryVariables,
  APITypes.ListRefuelRequestsByEventIdQuery
>;
export const getRefuel = /* GraphQL */ `query GetRefuel($id: ID!) {
  getRefuel(id: $id) {
    id
    eventId
    refuelRequestId
    refuelRequest {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    aircraftId
    aircraftName
    litres
    fuelSource {
      provider
      name
      __typename
    }
    fuelType
    users
    createdAt
    refueledAt
    refueledBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRefuelQueryVariables, APITypes.GetRefuelQuery>;
export const listRefuels = /* GraphQL */ `query ListRefuels(
  $filter: ModelRefuelFilterInput
  $limit: Int
  $nextToken: String
) {
  listRefuels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventId
      refuelRequestId
      refuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      users
      createdAt
      refueledAt
      refueledBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRefuelsQueryVariables,
  APITypes.ListRefuelsQuery
>;
export const syncRefuels = /* GraphQL */ `query SyncRefuels(
  $filter: ModelRefuelFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRefuels(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      eventId
      refuelRequestId
      refuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      users
      createdAt
      refueledAt
      refueledBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRefuelsQueryVariables,
  APITypes.SyncRefuelsQuery
>;
export const listRefuelsByEventId = /* GraphQL */ `query ListRefuelsByEventId(
  $eventId: ID!
  $refueledAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRefuelFilterInput
  $limit: Int
  $nextToken: String
) {
  listRefuelsByEventId(
    eventId: $eventId
    refueledAt: $refueledAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventId
      refuelRequestId
      refuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
        __typename
      }
      fuelType
      users
      createdAt
      refueledAt
      refueledBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRefuelsByEventIdQueryVariables,
  APITypes.ListRefuelsByEventIdQuery
>;
export const getFlightPassenger = /* GraphQL */ `query GetFlightPassenger($id: ID!) {
  getFlightPassenger(id: $id) {
    id
    eventId
    tupleId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    enquiryId
    voucherId
    designator
    sequence
    flightNumber
    flightId
    number
    passengerNumber
    forename
    surname
    weight
    seat
    phone
    email
    postcode
    bubbly
    creamTea
    lunch
    magnum
    chocolate
    photo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlightPassengerQueryVariables,
  APITypes.GetFlightPassengerQuery
>;
export const listFlightPassengers = /* GraphQL */ `query ListFlightPassengers(
  $filter: ModelFlightPassengerFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlightPassengers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventId
      tupleId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      enquiryId
      voucherId
      designator
      sequence
      flightNumber
      flightId
      number
      passengerNumber
      forename
      surname
      weight
      seat
      phone
      email
      postcode
      bubbly
      creamTea
      lunch
      magnum
      chocolate
      photo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlightPassengersQueryVariables,
  APITypes.ListFlightPassengersQuery
>;
export const syncFlightPassengers = /* GraphQL */ `query SyncFlightPassengers(
  $filter: ModelFlightPassengerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFlightPassengers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      eventId
      tupleId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      enquiryId
      voucherId
      designator
      sequence
      flightNumber
      flightId
      number
      passengerNumber
      forename
      surname
      weight
      seat
      phone
      email
      postcode
      bubbly
      creamTea
      lunch
      magnum
      chocolate
      photo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFlightPassengersQueryVariables,
  APITypes.SyncFlightPassengersQuery
>;
export const listFlightPassengersByEvent = /* GraphQL */ `query ListFlightPassengersByEvent(
  $eventId: ID!
  $flightIdNumber: ModelFlightPassengerByEventCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFlightPassengerFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlightPassengersByEvent(
    eventId: $eventId
    flightIdNumber: $flightIdNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventId
      tupleId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      enquiryId
      voucherId
      designator
      sequence
      flightNumber
      flightId
      number
      passengerNumber
      forename
      surname
      weight
      seat
      phone
      email
      postcode
      bubbly
      creamTea
      lunch
      magnum
      chocolate
      photo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlightPassengersByEventQueryVariables,
  APITypes.ListFlightPassengersByEventQuery
>;
export const listFlightPassengersByFlight = /* GraphQL */ `query ListFlightPassengersByFlight(
  $flightId: ID!
  $eventIdNumber: ModelFlightPassengerByFlightCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFlightPassengerFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlightPassengersByFlight(
    flightId: $flightId
    eventIdNumber: $eventIdNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventId
      tupleId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      enquiryId
      voucherId
      designator
      sequence
      flightNumber
      flightId
      number
      passengerNumber
      forename
      surname
      weight
      seat
      phone
      email
      postcode
      bubbly
      creamTea
      lunch
      magnum
      chocolate
      photo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlightPassengersByFlightQueryVariables,
  APITypes.ListFlightPassengersByFlightQuery
>;
export const searchFlightPassengers = /* GraphQL */ `query SearchFlightPassengers(
  $filter: SearchableFlightPassengerFilterInput
  $sort: [SearchableFlightPassengerSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFlightPassengerAggregationInput]
) {
  searchFlightPassengers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      eventId
      tupleId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      enquiryId
      voucherId
      designator
      sequence
      flightNumber
      flightId
      number
      passengerNumber
      forename
      surname
      weight
      seat
      phone
      email
      postcode
      bubbly
      creamTea
      lunch
      magnum
      chocolate
      photo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFlightPassengersQueryVariables,
  APITypes.SearchFlightPassengersQuery
>;
export const getFollowingLog = /* GraphQL */ `query GetFollowingLog($id: ID!) {
  getFollowingLog(id: $id) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFollowingLogQueryVariables,
  APITypes.GetFollowingLogQuery
>;
export const listFollowingLogs = /* GraphQL */ `query ListFollowingLogs(
  $filter: ModelFollowingLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listFollowingLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFollowingLogsQueryVariables,
  APITypes.ListFollowingLogsQuery
>;
export const syncFollowingLogs = /* GraphQL */ `query SyncFollowingLogs(
  $filter: ModelFollowingLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFollowingLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFollowingLogsQueryVariables,
  APITypes.SyncFollowingLogsQuery
>;
export const listFollowingLogsByCreatedAt = /* GraphQL */ `query ListFollowingLogsByCreatedAt(
  $modelType: FollowingLogString!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowingLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listFollowingLogsByCreatedAt(
    modelType: $modelType
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFollowingLogsByCreatedAtQueryVariables,
  APITypes.ListFollowingLogsByCreatedAtQuery
>;
export const searchFollowingLogs = /* GraphQL */ `query SearchFollowingLogs(
  $filter: SearchableFollowingLogFilterInput
  $sort: [SearchableFollowingLogSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFollowingLogAggregationInput]
) {
  searchFollowingLogs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFollowingLogsQueryVariables,
  APITypes.SearchFollowingLogsQuery
>;
export const getAzureFollowingLog = /* GraphQL */ `query GetAzureFollowingLog($id: ID!) {
  getAzureFollowingLog(id: $id) {
    modelType
    id
    user
    pilotName
    type
    notes
    eventId
    originId
    originType
    aircraft
    aircraftType
    pob
    action
    origin
    destination
    d_lat
    d_lng
    accuracy
    altitude
    altitudeAccuracy
    lat
    lng
    eta
    ete
    gs
    hdg
    battery
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAzureFollowingLogQueryVariables,
  APITypes.GetAzureFollowingLogQuery
>;
export const listAzureFollowingLogs = /* GraphQL */ `query ListAzureFollowingLogs(
  $filter: ModelAzureFollowingLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listAzureFollowingLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAzureFollowingLogsQueryVariables,
  APITypes.ListAzureFollowingLogsQuery
>;
export const syncAzureFollowingLogs = /* GraphQL */ `query SyncAzureFollowingLogs(
  $filter: ModelAzureFollowingLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAzureFollowingLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAzureFollowingLogsQueryVariables,
  APITypes.SyncAzureFollowingLogsQuery
>;
export const listAzureFollowingLogsByCreatedAt = /* GraphQL */ `query ListAzureFollowingLogsByCreatedAt(
  $modelType: FollowingLogString!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAzureFollowingLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listAzureFollowingLogsByCreatedAt(
    modelType: $modelType
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAzureFollowingLogsByCreatedAtQueryVariables,
  APITypes.ListAzureFollowingLogsByCreatedAtQuery
>;
export const searchAzureFollowingLogs = /* GraphQL */ `query SearchAzureFollowingLogs(
  $filter: SearchableAzureFollowingLogFilterInput
  $sort: [SearchableAzureFollowingLogSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableAzureFollowingLogAggregationInput]
) {
  searchAzureFollowingLogs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchAzureFollowingLogsQueryVariables,
  APITypes.SearchAzureFollowingLogsQuery
>;
export const getFlightLeg = /* GraphQL */ `query GetFlightLeg($id: ID!) {
  getFlightLeg(id: $id) {
    id
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    order
    venueId
    venueName
    venuePostcode
    empty
    departing
    fromVenueId
    fromVenueName
    fromVenuePostcode
    fromCoords {
      lat
      lng
      __typename
    }
    arriving
    toVenueId
    toVenueName
    toVenuePostcode
    toCoords {
      lat
      lng
      __typename
    }
    distance
    ete
    lat
    lng
    w3w
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFlightLegQueryVariables,
  APITypes.GetFlightLegQuery
>;
export const listFlightLegs = /* GraphQL */ `query ListFlightLegs(
  $filter: ModelFlightLegFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlightLegs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      order
      venueId
      venueName
      venuePostcode
      empty
      departing
      fromVenueId
      fromVenueName
      fromVenuePostcode
      fromCoords {
        lat
        lng
        __typename
      }
      arriving
      toVenueId
      toVenueName
      toVenuePostcode
      toCoords {
        lat
        lng
        __typename
      }
      distance
      ete
      lat
      lng
      w3w
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlightLegsQueryVariables,
  APITypes.ListFlightLegsQuery
>;
export const syncFlightLegs = /* GraphQL */ `query SyncFlightLegs(
  $filter: ModelFlightLegFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFlightLegs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      order
      venueId
      venueName
      venuePostcode
      empty
      departing
      fromVenueId
      fromVenueName
      fromVenuePostcode
      fromCoords {
        lat
        lng
        __typename
      }
      arriving
      toVenueId
      toVenueName
      toVenuePostcode
      toCoords {
        lat
        lng
        __typename
      }
      distance
      ete
      lat
      lng
      w3w
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFlightLegsQueryVariables,
  APITypes.SyncFlightLegsQuery
>;
export const listFlightLegsByEvent = /* GraphQL */ `query ListFlightLegsByEvent(
  $eventId: ID!
  $order: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFlightLegFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlightLegsByEvent(
    eventId: $eventId
    order: $order
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      order
      venueId
      venueName
      venuePostcode
      empty
      departing
      fromVenueId
      fromVenueName
      fromVenuePostcode
      fromCoords {
        lat
        lng
        __typename
      }
      arriving
      toVenueId
      toVenueName
      toVenuePostcode
      toCoords {
        lat
        lng
        __typename
      }
      distance
      ete
      lat
      lng
      w3w
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlightLegsByEventQueryVariables,
  APITypes.ListFlightLegsByEventQuery
>;
export const searchFlightLegs = /* GraphQL */ `query SearchFlightLegs(
  $filter: SearchableFlightLegFilterInput
  $sort: [SearchableFlightLegSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFlightLegAggregationInput]
) {
  searchFlightLegs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      order
      venueId
      venueName
      venuePostcode
      empty
      departing
      fromVenueId
      fromVenueName
      fromVenuePostcode
      fromCoords {
        lat
        lng
        __typename
      }
      arriving
      toVenueId
      toVenueName
      toVenuePostcode
      toCoords {
        lat
        lng
        __typename
      }
      distance
      ete
      lat
      lng
      w3w
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFlightLegsQueryVariables,
  APITypes.SearchFlightLegsQuery
>;
export const getPushSubscription = /* GraphQL */ `query GetPushSubscription(
  $user: String!
  $channel: PushChannel!
  $deviceToken: String!
  $expoPushToken: String!
) {
  getPushSubscription(
    user: $user
    channel: $channel
    deviceToken: $deviceToken
    expoPushToken: $expoPushToken
  ) {
    user
    channel
    deviceToken
    deviceType
    expoPushToken
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPushSubscriptionQueryVariables,
  APITypes.GetPushSubscriptionQuery
>;
export const listPushSubscriptions = /* GraphQL */ `query ListPushSubscriptions(
  $user: String
  $channelDeviceTokenExpoPushToken: ModelPushSubscriptionPrimaryCompositeKeyConditionInput
  $filter: ModelPushSubscriptionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPushSubscriptions(
    user: $user
    channelDeviceTokenExpoPushToken: $channelDeviceTokenExpoPushToken
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      user
      channel
      deviceToken
      deviceType
      expoPushToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPushSubscriptionsQueryVariables,
  APITypes.ListPushSubscriptionsQuery
>;
export const syncPushSubscriptions = /* GraphQL */ `query SyncPushSubscriptions(
  $filter: ModelPushSubscriptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPushSubscriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      user
      channel
      deviceToken
      deviceType
      expoPushToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPushSubscriptionsQueryVariables,
  APITypes.SyncPushSubscriptionsQuery
>;
export const listPushSubscriptionsByChannel = /* GraphQL */ `query ListPushSubscriptionsByChannel(
  $channel: PushChannel!
  $sortDirection: ModelSortDirection
  $filter: ModelPushSubscriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listPushSubscriptionsByChannel(
    channel: $channel
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      user
      channel
      deviceToken
      deviceType
      expoPushToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPushSubscriptionsByChannelQueryVariables,
  APITypes.ListPushSubscriptionsByChannelQuery
>;
export const getUserSetting = /* GraphQL */ `query GetUserSetting($owner: String!) {
  getUserSetting(owner: $owner) {
    teamLeaderRegionId
    teamLeaderRegionIds
    teamResourceId
    owner
    profileImage
    callsign
    calCode
    appVersion
    weightDisplay
    accessibilityIcons
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserSettingQueryVariables,
  APITypes.GetUserSettingQuery
>;
export const listUserSettings = /* GraphQL */ `query ListUserSettings(
  $owner: String
  $filter: ModelUserSettingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserSettings(
    owner: $owner
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      teamLeaderRegionId
      teamLeaderRegionIds
      teamResourceId
      owner
      profileImage
      callsign
      calCode
      appVersion
      weightDisplay
      accessibilityIcons
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSettingsQueryVariables,
  APITypes.ListUserSettingsQuery
>;
export const syncUserSettings = /* GraphQL */ `query SyncUserSettings(
  $filter: ModelUserSettingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserSettings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      teamLeaderRegionId
      teamLeaderRegionIds
      teamResourceId
      owner
      profileImage
      callsign
      calCode
      appVersion
      weightDisplay
      accessibilityIcons
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserSettingsQueryVariables,
  APITypes.SyncUserSettingsQuery
>;
export const listUserSettingsByTeamResourceId = /* GraphQL */ `query ListUserSettingsByTeamResourceId(
  $teamResourceId: Int!
  $owner: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserSettingsByTeamResourceId(
    teamResourceId: $teamResourceId
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      teamLeaderRegionId
      teamLeaderRegionIds
      teamResourceId
      owner
      profileImage
      callsign
      calCode
      appVersion
      weightDisplay
      accessibilityIcons
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSettingsByTeamResourceIdQueryVariables,
  APITypes.ListUserSettingsByTeamResourceIdQuery
>;
export const listUserSettingsByCalCode = /* GraphQL */ `query ListUserSettingsByCalCode(
  $calCode: String!
  $owner: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserSettingsByCalCode(
    calCode: $calCode
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      teamLeaderRegionId
      teamLeaderRegionIds
      teamResourceId
      owner
      profileImage
      callsign
      calCode
      appVersion
      weightDisplay
      accessibilityIcons
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSettingsByCalCodeQueryVariables,
  APITypes.ListUserSettingsByCalCodeQuery
>;
export const getCertificate = /* GraphQL */ `query GetCertificate(
  $owner: String!
  $model: CertificateModel!
  $type: CertificateType!
) {
  getCertificate(owner: $owner, model: $model, type: $type) {
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCertificateQueryVariables,
  APITypes.GetCertificateQuery
>;
export const listCertificates = /* GraphQL */ `query ListCertificates(
  $owner: String
  $modelType: ModelCertificatePrimaryCompositeKeyConditionInput
  $filter: ModelCertificateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCertificates(
    owner: $owner
    modelType: $modelType
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCertificatesQueryVariables,
  APITypes.ListCertificatesQuery
>;
export const syncCertificates = /* GraphQL */ `query SyncCertificates(
  $filter: ModelCertificateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCertificates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCertificatesQueryVariables,
  APITypes.SyncCertificatesQuery
>;
export const listCertificatesByPilotExpiry = /* GraphQL */ `query ListCertificatesByPilotExpiry(
  $model: CertificateModel!
  $expiresAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCertificateFilterInput
  $limit: Int
  $nextToken: String
) {
  listCertificatesByPilotExpiry(
    model: $model
    expiresAt: $expiresAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCertificatesByPilotExpiryQueryVariables,
  APITypes.ListCertificatesByPilotExpiryQuery
>;
export const getUserCertificate = /* GraphQL */ `query GetUserCertificate($key: String!) {
  getUserCertificate(key: $key) {
    key
    owner
    model
    type
    number
    aircraft
    pdf
    expiresAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserCertificateQueryVariables,
  APITypes.GetUserCertificateQuery
>;
export const listUserCertificates = /* GraphQL */ `query ListUserCertificates(
  $key: String
  $filter: ModelUserCertificateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserCertificates(
    key: $key
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      key
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCertificatesQueryVariables,
  APITypes.ListUserCertificatesQuery
>;
export const syncUserCertificates = /* GraphQL */ `query SyncUserCertificates(
  $filter: ModelUserCertificateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserCertificates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      key
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserCertificatesQueryVariables,
  APITypes.SyncUserCertificatesQuery
>;
export const listUserCertificatesByOwner = /* GraphQL */ `query ListUserCertificatesByOwner(
  $owner: String!
  $modelTypeAircraft: ModelUserCertificateByOwnerCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserCertificateFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserCertificatesByOwner(
    owner: $owner
    modelTypeAircraft: $modelTypeAircraft
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      key
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCertificatesByOwnerQueryVariables,
  APITypes.ListUserCertificatesByOwnerQuery
>;
export const listUserCertificatesByExpiry = /* GraphQL */ `query ListUserCertificatesByExpiry(
  $model: CertificateModel!
  $expiresAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserCertificateFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserCertificatesByExpiry(
    model: $model
    expiresAt: $expiresAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      key
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCertificatesByExpiryQueryVariables,
  APITypes.ListUserCertificatesByExpiryQuery
>;
export const getBroadcastMessage = /* GraphQL */ `query GetBroadcastMessage($id: ID!) {
  getBroadcastMessage(id: $id) {
    id
    modelType
    message
    title
    originType
    originId
    sentBy
    url
    extra
    targetedGroup
    targetedRole
    targetedEventId
    targetedEvent {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    targetedUsers
    users
    broadcastAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBroadcastMessageQueryVariables,
  APITypes.GetBroadcastMessageQuery
>;
export const listBroadcastMessages = /* GraphQL */ `query ListBroadcastMessages(
  $filter: ModelBroadcastMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listBroadcastMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBroadcastMessagesQueryVariables,
  APITypes.ListBroadcastMessagesQuery
>;
export const syncBroadcastMessages = /* GraphQL */ `query SyncBroadcastMessages(
  $filter: ModelBroadcastMessageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBroadcastMessages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBroadcastMessagesQueryVariables,
  APITypes.SyncBroadcastMessagesQuery
>;
export const listBroadcastMessagesByCreatedAt = /* GraphQL */ `query ListBroadcastMessagesByCreatedAt(
  $modelType: BroadcastMessageString!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBroadcastMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listBroadcastMessagesByCreatedAt(
    modelType: $modelType
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBroadcastMessagesByCreatedAtQueryVariables,
  APITypes.ListBroadcastMessagesByCreatedAtQuery
>;
export const listBroadcastMessagesByOrigin = /* GraphQL */ `query ListBroadcastMessagesByOrigin(
  $originId: ID!
  $originTypeCreatedAt: ModelBroadcastMessageByOriginCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBroadcastMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listBroadcastMessagesByOrigin(
    originId: $originId
    originTypeCreatedAt: $originTypeCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBroadcastMessagesByOriginQueryVariables,
  APITypes.ListBroadcastMessagesByOriginQuery
>;
export const searchBroadcastMessages = /* GraphQL */ `query SearchBroadcastMessages(
  $filter: SearchableBroadcastMessageFilterInput
  $sort: [SearchableBroadcastMessageSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBroadcastMessageAggregationInput]
) {
  searchBroadcastMessages(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBroadcastMessagesQueryVariables,
  APITypes.SearchBroadcastMessagesQuery
>;
export const getBroadcastReceipt = /* GraphQL */ `query GetBroadcastReceipt($id: ID!) {
  getBroadcastReceipt(id: $id) {
    id
    broadcastMessageId
    broadcastMessage {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    owner
    pushToken
    deviceToken
    sentAt
    status
    message
    details
    deliveredAt
    errors
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBroadcastReceiptQueryVariables,
  APITypes.GetBroadcastReceiptQuery
>;
export const listBroadcastReceipts = /* GraphQL */ `query ListBroadcastReceipts(
  $filter: ModelBroadcastReceiptFilterInput
  $limit: Int
  $nextToken: String
) {
  listBroadcastReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      broadcastMessageId
      broadcastMessage {
        id
        modelType
        message
        title
        originType
        originId
        sentBy
        url
        extra
        targetedGroup
        targetedRole
        targetedEventId
        targetedUsers
        users
        broadcastAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      owner
      pushToken
      deviceToken
      sentAt
      status
      message
      details
      deliveredAt
      errors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBroadcastReceiptsQueryVariables,
  APITypes.ListBroadcastReceiptsQuery
>;
export const syncBroadcastReceipts = /* GraphQL */ `query SyncBroadcastReceipts(
  $filter: ModelBroadcastReceiptFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBroadcastReceipts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      broadcastMessageId
      broadcastMessage {
        id
        modelType
        message
        title
        originType
        originId
        sentBy
        url
        extra
        targetedGroup
        targetedRole
        targetedEventId
        targetedUsers
        users
        broadcastAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      owner
      pushToken
      deviceToken
      sentAt
      status
      message
      details
      deliveredAt
      errors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBroadcastReceiptsQueryVariables,
  APITypes.SyncBroadcastReceiptsQuery
>;
export const getExperiment = /* GraphQL */ `query GetExperiment($owner: String!, $experiment: Experiments!) {
  getExperiment(owner: $owner, experiment: $experiment) {
    owner
    experiment
    enabled
    extra
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExperimentQueryVariables,
  APITypes.GetExperimentQuery
>;
export const listExperiments = /* GraphQL */ `query ListExperiments(
  $owner: String
  $experiment: ModelStringKeyConditionInput
  $filter: ModelExperimentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listExperiments(
    owner: $owner
    experiment: $experiment
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      owner
      experiment
      enabled
      extra
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExperimentsQueryVariables,
  APITypes.ListExperimentsQuery
>;
export const syncExperiments = /* GraphQL */ `query SyncExperiments(
  $filter: ModelExperimentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncExperiments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      owner
      experiment
      enabled
      extra
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncExperimentsQueryVariables,
  APITypes.SyncExperimentsQuery
>;
export const getPhotoAsset = /* GraphQL */ `query GetPhotoAsset($id: ID!) {
  getPhotoAsset(id: $id) {
    id
    file {
      bucket
      region
      key
      __typename
    }
    files {
      file {
        bucket
        region
        key
        __typename
      }
      soldAtEvent {
        __typename
      }
      soldAfterEvent {
        __typename
      }
      __typename
    }
    enquiryId
    passengers
    email
    flightId
    flight {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        nextToken
        startedAt
        __typename
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventId
    event {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamMembers {
        nextToken
        startedAt
        __typename
      }
      flights {
        nextToken
        startedAt
        __typename
      }
      legs {
        nextToken
        startedAt
        __typename
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
      __typename
    }
    salesAtEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    salesAfterEvent {
      physical {
        copies
        delivered
        __typename
      }
      digital {
        copies
        delivered
        __typename
      }
      __typename
    }
    soldBy
    emailSent
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPhotoAssetQueryVariables,
  APITypes.GetPhotoAssetQuery
>;
export const listPhotoAssets = /* GraphQL */ `query ListPhotoAssets(
  $filter: ModelPhotoAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  listPhotoAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      file {
        bucket
        region
        key
        __typename
      }
      files {
        __typename
      }
      enquiryId
      passengers
      email
      flightId
      flight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      salesAtEvent {
        __typename
      }
      salesAfterEvent {
        __typename
      }
      soldBy
      emailSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPhotoAssetsQueryVariables,
  APITypes.ListPhotoAssetsQuery
>;
export const syncPhotoAssets = /* GraphQL */ `query SyncPhotoAssets(
  $filter: ModelPhotoAssetFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPhotoAssets(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      file {
        bucket
        region
        key
        __typename
      }
      files {
        __typename
      }
      enquiryId
      passengers
      email
      flightId
      flight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      salesAtEvent {
        __typename
      }
      salesAfterEvent {
        __typename
      }
      soldBy
      emailSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPhotoAssetsQueryVariables,
  APITypes.SyncPhotoAssetsQuery
>;
export const listPhotoAssetsByEnquiryId = /* GraphQL */ `query ListPhotoAssetsByEnquiryId(
  $enquiryId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPhotoAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  listPhotoAssetsByEnquiryId(
    enquiryId: $enquiryId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      file {
        bucket
        region
        key
        __typename
      }
      files {
        __typename
      }
      enquiryId
      passengers
      email
      flightId
      flight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      salesAtEvent {
        __typename
      }
      salesAfterEvent {
        __typename
      }
      soldBy
      emailSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPhotoAssetsByEnquiryIdQueryVariables,
  APITypes.ListPhotoAssetsByEnquiryIdQuery
>;
export const listPhotoAssetsByEvent = /* GraphQL */ `query ListPhotoAssetsByEvent(
  $eventId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPhotoAssetFilterInput
  $limit: Int
  $nextToken: String
) {
  listPhotoAssetsByEvent(
    eventId: $eventId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      file {
        bucket
        region
        key
        __typename
      }
      files {
        __typename
      }
      enquiryId
      passengers
      email
      flightId
      flight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      salesAtEvent {
        __typename
      }
      salesAfterEvent {
        __typename
      }
      soldBy
      emailSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPhotoAssetsByEventQueryVariables,
  APITypes.ListPhotoAssetsByEventQuery
>;
export const searchPhotoAssets = /* GraphQL */ `query SearchPhotoAssets(
  $filter: SearchablePhotoAssetFilterInput
  $sort: [SearchablePhotoAssetSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePhotoAssetAggregationInput]
) {
  searchPhotoAssets(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      file {
        bucket
        region
        key
        __typename
      }
      files {
        __typename
      }
      enquiryId
      passengers
      email
      flightId
      flight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
        __typename
      }
      salesAtEvent {
        __typename
      }
      salesAfterEvent {
        __typename
      }
      soldBy
      emailSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPhotoAssetsQueryVariables,
  APITypes.SearchPhotoAssetsQuery
>;
export const getSafetyComm = /* GraphQL */ `query GetSafetyComm($id: ID!) {
  getSafetyComm(id: $id) {
    id
    title
    modelType
    url
    file {
      bucket
      region
      key
      __typename
    }
    publishedBy
    publishedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSafetyCommQueryVariables,
  APITypes.GetSafetyCommQuery
>;
export const listSafetyComms = /* GraphQL */ `query ListSafetyComms(
  $filter: ModelSafetyCommFilterInput
  $limit: Int
  $nextToken: String
) {
  listSafetyComms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      modelType
      url
      file {
        bucket
        region
        key
        __typename
      }
      publishedBy
      publishedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSafetyCommsQueryVariables,
  APITypes.ListSafetyCommsQuery
>;
export const syncSafetyComms = /* GraphQL */ `query SyncSafetyComms(
  $filter: ModelSafetyCommFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSafetyComms(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      modelType
      url
      file {
        bucket
        region
        key
        __typename
      }
      publishedBy
      publishedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSafetyCommsQueryVariables,
  APITypes.SyncSafetyCommsQuery
>;
export const listSafetyCommsByPublishedAt = /* GraphQL */ `query ListSafetyCommsByPublishedAt(
  $modelType: SafetyCommString!
  $publishedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSafetyCommFilterInput
  $limit: Int
  $nextToken: String
) {
  listSafetyCommsByPublishedAt(
    modelType: $modelType
    publishedAt: $publishedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      modelType
      url
      file {
        bucket
        region
        key
        __typename
      }
      publishedBy
      publishedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSafetyCommsByPublishedAtQueryVariables,
  APITypes.ListSafetyCommsByPublishedAtQuery
>;
